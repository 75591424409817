import { Link } from "react-router-dom"

function SignUpCard() {
    return (
        <div className='pl-3'>
            <div className='card card-bordered text-center bg-base-200'>
                <div className="card-body p-4">
                        Don't have an account?
                        <div className='pt-2'>
                            <Link to='/sign-up' className='btn btn-primary'>Sign Up</Link>
                        </div>
                </div>
            </div>
        </div>

    )
}

export default SignUpCard
