import SignUpCard from "../components/SignUpCard"
import { useState } from "react"
import {getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { db } from '../firebase.config'
import { Link, useNavigate, useNavigationType } from "react-router-dom"
import {RiEyeCloseFill, RiEyeFill} from 'react-icons/ri'
import { MdOutlineEmail } from 'react-icons/md'
import { BiLockAlt } from "react-icons/bi"

function ForgotPassword() {

    const [email, setEmail] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        document.title = 'Layolay | Forgot Password'
    }, [])

    const onChange = (e) => {
        setEmail(e.target.value)
    }
    
    const onSubmit = async (e) => {
        e.preventDefault()
        try{
            const auth = getAuth()
            sendPasswordResetEmail(auth, email)
            navigate('/signin')
        } catch (error) {
            console.log(error)

        }
    }

    const emailInput = [
        <input 
            placeholder="Email"
            key='email'
            id="email" 
            value={email} 
            onChange={(onChange)} 
            className="input w-full input-primary input-bordered" 
            type="email" 
        />
    ]

    return (
        <div className='grid grid-cols-8 py-3 my-auto'>
            <div className="col-span-4 col-start-3 p-10 card bg-base-200">
                <h6 className='pb-6 font-bold text-2xl'>Enter Account Email</h6>

                <form onSubmit={onSubmit}>
                    <div className="form-control pb-4">
                        <label className="label">
                            <span className="label-text">Email</span>
                        </label>
                        <div className="input-group">
                            <span><MdOutlineEmail size={18}/></span>
                            {emailInput}
                        </div>
                    </div>
                    
                    <div className="card-actions justify-end">
                        <button type="submit" className='btn btn-primary'>Submit</button>
                    </div>
                </form>
            </div>
            <SignUpCard />
        </div>
    )
}



export default ForgotPassword;
