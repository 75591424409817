import { useState, useEffect, useContext } from 'react'
import { updateDoc, doc, getDoc, deleteDoc, collection, getDocs, query, orderBy, where, limit, startAfter } from 'firebase/firestore'
import { getAuth, updateProfile } from 'firebase/auth'
import { db } from '../firebase.config'
import Spinner from '../components/Spinner'
import ItemCard from '../components/ItemCard'
import { FiTrash, FiEdit, FiDelete } from 'react-icons/fi'

function EditCreations() {

  const auth = getAuth()
  //deleteDoc(doc(db, "cities", "DC"));
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [refreshList, setRefreshList] = useState(true)
  const userUID = (auth.currentUser != null) ? auth.currentUser.uid : ''

  const deleteItem = async (itemId) => {
    console.log(itemId)
    await deleteDoc(doc(db, "items", itemId))
    setRefreshList(!refreshList)
    const userRef = doc(db, 'users', userUID)
    const docSnap = await getDoc(userRef)
    const numItems = docSnap.data().numItems
    console.log(userRef)
    console.log(userUID)
    console.log(docSnap.data().numItems)

    await updateDoc(userRef, {
        numItems: numItems - 1
    })
  }

  useEffect(() => {

    setLoading(true)
    const getItems = async () => {

        try {
            // Get reference
            const itemRef = collection(db, 'items')

            // Create a query
            const q = query(
                itemRef,
                where('userId', '==', userUID)
            )
            
            // Execute query
            const querySnap = await getDocs(q)

            let items = []
            querySnap.forEach((doc) => {
                return items.push({
                    id: doc.id,
                    data: doc.data()
                })
            })

            setItems(items)

        } catch(error) {
            console.log(error)
        }
    }

    getItems()
    setLoading(false)
  }, [userUID, refreshList])

  const { id, data } = items

  if(loading){
      return(
        <Spinner />
      )
  }

  //console.log(items[0].data)

  return (
    <div className='grid grid-cols-2 gap-2'>
        {items.map((item) => (
            <div className='m-4'>
                <div className='flex bg-base-200 p-4 rounded-lg'>
                    <div className='basis-1/4'>
                        <div className='bg-base-300 p-2 mx-auto rounded-md mr-0 mb-4'>
                            <img src={item.data.imgUrls[0]} alt='thumbnail' className='rounded-md hover:cursor-pointer' onClick={() => {setCurrentImage(url)}}/>
                        </div>
                        <div className='font-medium text-lg'>
                            {item.data.name}
                        </div>
                        <div className='font-medium text-md ml-4'>
                            {item.data.price}
                        </div>
                    </div>
                    <div className='basis-2/4 flex bg-base-200 rounded-lg ml-4'>
                        <div className='basis-1/3 bg-base-300 rounded-lg p-4'>
                            <div className='text-md font-medium'>
                                Description
                            </div>
                            <div className='text-sm font-light my-2'>
                                {item.data.description}
                            </div>
                        </div>
                    </div>
                    <div className='basis-1/4'>
                        <div className='flex flex-col '>
                            <button className='btn btn-warning mx-auto my-2 text-white'> <FiEdit className='mr-2' /> Edit</button>
                            <label for="my-modal" class="btn btn-error mx-auto my-2 modal-button text-white">
                                <FiTrash className='mr-2'/> Delete
                            </label>
                            <input type="checkbox" id="my-modal" className="modal-toggle"/>
                            <div className="modal">
                                <div className="modal-box">
                                    <h3 className="font-bold text-lg text-white">Are you sure you want to delete this item forever?</h3>
                                    <div className="modal-action">
                                        <label for="my-modal" className="btn btn-error text-white" onClick={() => deleteItem(item.id)}>Yes, delete item!</label>
                                        <label for="my-modal" className="btn btn-info text-white">No, keep my item!</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>           
        ))}
    </div>
  )
}

export default EditCreations