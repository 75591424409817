import { useEffect } from "react"

function Jobs() {

    useEffect(() => {
        document.title = 'Layolay | Jobs'
    }, [])

    return (
        <div className='bg-base-200 rounded-lg p-4 m-4 2xl:w-[40%] xl:w-[50%] lg:w-[60%] md:w-[70%]
        2xl:m-auto xl:m-auto lg:m-auto md:m-auto'>
            <h1 className="font-bold text-3xl text-center m-8">Jobs at Layolay</h1>
            <h1 className="font-light text-xl text-center m-8">
                There are currently no permanent positions available at Layolay.
                Please check back soon as we will be posting positions in the coming months!
            </h1>
        </div>
    )
}

export default Jobs
