import Layolay from '../media/layolay.svg'
import { Link, useNavigate } from 'react-router-dom'
import { FaCog, FaBell, FaShoppingCart, FaStore, FaHeart, FaTimes, FaCube, FaHome } from 'react-icons/fa'
import { BsPersonFill, BsPeopleFill, BsSunFill } from 'react-icons/bs'
import { IoCube } from 'react-icons/io'
import { GiCubes } from 'react-icons/gi'
import { RiLogoutCircleLine } from 'react-icons/ri'
import { AiOutlineMenu } from 'react-icons/ai'
import { MdRequestPage } from 'react-icons/md'
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { useState, useEffect, useContext, useRef } from "react"
import { updateDoc, doc, getDocs, where, collection, query } from 'firebase/firestore'
import { db } from '../firebase.config'
import CustomerContext from '../context/user/CustomerContext'
import { onLog } from 'firebase/app'
import { themeChange } from 'theme-change/index'
import { TiMessages } from 'react-icons/ti'

function Navbar() {

  const { cart, setCart, setDiscounts } = useContext(CustomerContext)
  const navigate = useNavigate()

  const [showMenu, setShowMenu] = useState(false)

  const [loggedIn, setLoggedIn] = useState(true)
  const [user, setUser] = useState(null)
  const isMounted = useRef(true)
  const auth = getAuth()

    const onLogout = () => {
      const storeCart = async () => {

        // Find private details for the current user
        // Get reference
        const privateCollectionRef = collection(db, 'private')
        
        // Create a query
        const q = query(
            privateCollectionRef,
            where('userId', '==', auth.currentUser.uid)
        )

        // Execute query
        const querySnap = await getDocs(q)
        
        let privateDetails = []
        querySnap.forEach((doc) => {
            return privateDetails.push({
                id: doc.id,
                data: doc.data()
            })
        })

        const privateRef = doc(db, 'private', privateDetails[0].id)
        await updateDoc(privateRef, {
            cart: cart
        })

        setCart([])
        setDiscounts([])
      }

      // Store cart in users private details
      storeCart()

      auth.signOut()
      navigate('/home')
  }

  useEffect(() => {
    themeChange(false)
  }, [])
  
  useEffect(() => {
      if(isMounted){
          onAuthStateChanged(auth, (user) => {
              if(user) {
                  setUser(user)
                  setLoggedIn(true)
              } else {
                  setUser(null)
                  setLoggedIn(false)
              }
          })
      }
      return () => {
          isMounted.current = false
      }

  }, [isMounted])

  return (
    <div className='sticky w-full  top-0 z-50'>
      <div className="navbar flex shadow-lg text-neutral-content bg-base-200">
        <div className="basis-1/3 dropdown my-auto block 2xl:hidden xl:hidden lg:hidden md:block sm:block" onClick={() => setShowMenu(!showMenu)}>
            <label tabIndex="0" className="">
              <button className="btn btn-square btn-ghost">
                <AiOutlineMenu size={20} />
              </button>
            </label>
            <ul tabIndex="0" className="p-2 shadow menu dropdown-content bg-base-200 rounded-box w-38 mt-3">
              <li key={'home'} className='btn btn-primary rounded-lg mb-2' onClick={() => navigate('/')}>
                <div className='flex w-full'>
                  <FaHome size={20} />
                  <span className='my-auto ml-4'>Home</span>
                </div>
              </li>
              <li key={'about'} className='btn btn-primary rounded-lg mb-2' onClick={() => navigate('/about')}>
                <div className='flex w-full'>
                  <BsPeopleFill size={20} />
                  <span className='my-auto ml-4'>About</span>
                </div>
              </li>
              <li key={'creators'} className='btn btn-primary rounded-lg' onClick={() => navigate('/creators')}>
                <div className='flex w-full'>
                  <GiCubes size={20} />
                  <span className='my-auto ml-4'>Creators</span>
                </div>
              </li>
            </ul>
          </div>
        {/* 2xl:self-start xl:self-start lg:self-start md:self-start sm:self-start */}
        <div className="basis-1/3 px-2 mx-auto 2xl:ml-2 xl:ml-2 lg:ml-2 md:ml-2 sm:ml-2">
          <img src = {Layolay} className='h-12 cursor-pointer' onClick={() => navigate('/home')}></img>
        </div>
        <div className='basis-1/3 px-2 mx-2 hidden 2xl:flex xl:flex lg:flex md:hidden sm:hidden'> 
          <div className="items-stretch mx-auto">
              <Link key='home' className='btn btn-ghost btn-sm rounded-btn' to='/home'>Home</Link>
              <Link key='about' className='btn btn-ghost btn-sm rounded-btn' to='/about'>About</Link>
              <Link key='creators' className='btn btn-ghost btn-sm rounded-btn' to='/creators'>Creators</Link>
          </div>
        </div>
        <div className='basis-1/3 flex'>
          <div className='flex ml-auto items-stretch'>
            <Link to='/cart' className='ml-auto'>
              <button className='btn btn-ghost'>
                <div className="indicator">
                  { (cart.length == 0) ? 
                      <></>
                      :
                      <span className="indicator-item indicator-bottom rounded-md badge-xs badge-primary">{cart.length}</span>
                  }
                  <FaShoppingCart size={20} />
                </div>
              </button>
            </Link>
            {(auth.currentUser !== null) ?
              <>
                  <div className='hidden 2xl:block xl:block lg:block md:block sm:block ml-2'>
                    <button className="btn btn-square btn-ghost" onClick={() => navigate('alerts')}>
                      <FaBell size={20}/>
                    </button>
                  </div>
                    
                  <div className='hidden 2xl:block xl:block lg:block md:block sm:block ml-2'>
                      <button className="btn btn-square btn-ghost " onClick={() => navigate('settings')}>
                        <FaCog size={20}/>
                      </button>
                  </div>

                  <div className="dropdown dropdown-end bg-base-200 ml-2 hidden 2xl:block xl:block lg:block md:block sm:hidden">
                    <button className="btn btn-square btn-ghost">
                      <BsSunFill size={20}/>
                    </button>
                    <ul tabIndex="0" className="p-2 shadow menu dropdown-content bg-base-200 rounded-box mt-4">
                        <li className='m-1 btn btn-primary btn-sm' onClick={themeChange(false)} data-set-theme="">
                            Default
                        </li>
                        <li className='m-1 btn btn-primary btn-sm' onClick={themeChange(false)} data-set-theme="synthwave">
                            Synthwave
                        </li>
                        {/* <li className='m-1 btn btn-primary btn-sm' onClick={themeChange(false)} data-set-theme="luxury">
                            Luxury
                        </li> */}
                        <li className='m-1 btn btn-primary btn-sm' onClick={themeChange(false)} data-set-theme="halloween">
                            Halloween
                        </li>
                        <li className='m-1 btn btn-primary btn-sm' onClick={themeChange(false)} data-set-theme="dracula">
                            Dracula
                        </li>
                        <li className='m-1 btn btn-primary btn-sm' onClick={themeChange(false)} data-set-theme="valentine">
                            Valentine
                        </li>
                    </ul>
                  </div>
                  
                  <div className="dropdown dropdown-end my-auto ml-2">
                    <label tabIndex="0" className="">
                      <img className="object-cover rounded-md avatar w-10 h-10 hover:cursor-pointer aspect-square" src={auth.currentUser.photoURL != null ? auth.currentUser.photoURL : ''}/>
                    </label> 
                    <ul tabIndex="0" className="p-2 shadow menu dropdown-content bg-base-200 rounded-box w-38 mt-4">
                      <li key={'profile'} className='btn btn-primary rounded-lg m-1' onClick={() => navigate(`profile/${user.uid}`)}>
                          <div className='flex w-full'>
                            <BsPersonFill className='my-auto' size={20}/>
                            {/* <CgProfile className='my-auto' size={20}/> */}
                            <span className='my-auto ml-4'>Profile</span>
                          </div>
                      </li>
                      <li key={'messages'} className='btn btn-primary rounded-lg m-1' onClick={() => navigate('messages')}>
                          <div className='flex w-full'>
                            <TiMessages size={20}/>
                            <span className='my-auto ml-4'>Messages</span>
                          </div>
                      </li>
                      <li key={'bell'} className='btn btn-primary rounded-lg m-1 flex 2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden' onClick={() => navigate('alerts')}>
                          <div className='flex w-full'>
                            <FaBell className='my-auto' size={20}/>
                            <span className='my-auto ml-4'>Alerts</span>
                          </div>
                      </li>
                      <li key={'favorites'} className='btn btn-primary rounded-lg m-1' onClick={() => navigate('favorites')}>
                          <div className='flex w-full'>
                            <FaHeart className='my-auto' size={20}/>
                            <div className='my-auto ml-4'>Favorites</div>
                          </div>
                      </li>
                      <li key={'orders'} className='btn btn-primary rounded-lg m-1' onClick={() => navigate('order-history')}>
                          <div className='flex w-full'>
                            <MdRequestPage className='my-auto' size={20}/>
                            <div className='my-auto ml-4'>Orders</div>
                          </div>
                      </li>
                      <li key={'toolbox'} className='btn btn-primary rounded-lg m-1' onClick={() => navigate('creator-tools')}>
                          <div className='flex w-full'>
                            <FaStore className='my-auto' size={20}/>
                            <span className='my-auto ml-4'>Toolbox</span>
                          </div>
                      </li>
                      <li key={'settings'} className='btn btn-primary rounded-lg m-1 flex 2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden' onClick={() => navigate('settings')}>
                          <div className='flex w-full'>
                            <FaCog size={20}/>
                            <span className='my-auto ml-4'>Settings</span>
                          </div>
                      </li>
                      <li key={'logout'} className='btn btn-primary rounded-lg m-1' onClick={onLogout}>
                          <div className='flex w-full'>
                            <RiLogoutCircleLine className='my-auto' size={20}/>
                            <span className='my-auto ml-4'>Logout</span>
                          </div>
                      </li>
                    </ul>
                  </div>
              </>
              :
              <>
                <div className="dropdown dropdown-end bg-base-200 ml-2 hidden 2xl:block xl:block lg:block md:block sm:hidden">
                    <button className="btn btn-square btn-ghost">
                      <BsSunFill size={20}/>
                    </button>
                    <ul tabIndex="0" className="p-2 shadow menu dropdown-content bg-base-200 rounded-box mt-4">
                        <li className='m-1 btn btn-primary btn-sm' onClick={themeChange(false)} data-set-theme="">
                            Default
                        </li>
                        <li className='m-1 btn btn-primary btn-sm' onClick={themeChange(false)} data-set-theme="synthwave">
                            Synthwave
                        </li>
                        {/* <li className='m-1 btn btn-primary btn-sm' onClick={themeChange(false)} data-set-theme="luxury">
                            Luxury
                        </li> */}
                        <li className='m-1 btn btn-primary btn-sm' onClick={themeChange(false)} data-set-theme="halloween">
                            Halloween
                        </li>
                        <li className='m-1 btn btn-primary btn-sm' onClick={themeChange(false)} data-set-theme="dracula">
                            Dracula
                        </li>
                        <li className='m-1 btn btn-primary btn-sm' onClick={themeChange(false)} data-set-theme="valentine">
                            Valentine
                        </li>
                    </ul>
                </div>
                <div className='ml-2'>
                  <Link to='/sign-in'><button className='btn btn-primary'>Sign In</button></Link>
                </div>
                <div className='ml-2'>
                  <Link to='/sign-up'><button className='btn btn-primary btn-outline hidden 2xl:block xl:block lg:block md:hidden sm:hidden'>Sign Up</button></Link>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
