import { Link } from 'react-router-dom'
import { v4 as uuidv4} from 'uuid'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect, useRef } from 'react'
import { FaPlus, FaTimes } from 'react-icons/fa'
import AnimatedNumber from './../components/AnimateNumber'
import { FiTrash, FiEdit } from 'react-icons/fi'
import { RiStarSLine, RiStarSFill, RiEyeFill, RiEyeOffFill } from 'react-icons/ri'
import { BsFillCaretLeftFill, BsFillCaretRightFill } from 'react-icons/bs'
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage'
import { doc, addDoc, updateDoc, getDoc, getDocs, deleteDoc, collection, where, query, serverTimestamp } from 'firebase/firestore'
import { db } from '../firebase.config'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Resizer from "react-image-file-resizer"

function CreatorTools() {

  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const [privateDetails, setPrivateDetails] = useState({})

  const [postTitle, setPostTitle] = useState('')
  const [postMessage, setPostMessage] = useState('')
  const [posts, setPosts] = useState([])
  const [currentPost, setCurrentPost] = useState(0)

  const [items, setItems] = useState([])
  const [itemCopies, setItemCopies] = useState([])
  const [editing, setEditing] = useState(-1)

  const [draggableImages, setDraggableImages] = useState([])
  const [imageFiles, setImageFiles] = useState([])

  const [submitFlag, setSubmitFlag] = useState(false)

  const isMounted = useRef(true)
  const auth = getAuth()

  useEffect(() => {
    document.title = 'Layolay | Creator Tools'
  }, [])

  useEffect(() => {
    if (submitFlag == true && editing != -1){
      setSubmitFlag(false)
      console.log('submitting', itemCopies[editing])
      submitItem(editing)
    }
  }, [submitFlag])

  const hideItem = (n) => {
    const updatedItems = []
    for (let i = 0; i < itemCopies.length; i++){
      if (i == n){
        const newItem = {
          id: itemCopies[i].id,
          data: {
            ...itemCopies[i].data,
            hidden: !itemCopies[i].data.hidden,
          }
        }
        // console.log(newItem)
        updatedItems.push(newItem)
      } else {
        updatedItems.push(itemCopies[i])
      }
    }
    setItemCopies(updatedItems)
  }

  const resetItem = (n) => {
    //setEditing(-1)
    const resetItems = []
    for (let i = 0; i < itemCopies.length; i++){
      if (i == n){
        resetItems.push(items[n])
        setDraggableImages(items[n].data.imgUrls)
        setImageFiles([...Array(items[n].data.imgUrls.length).keys()])
      } else {
        resetItems.push(itemCopies[i])
      }
    }
    setItemCopies(resetItems)
  }

  const submitItem = async (n) => {
    console.log('submitting', n)
    const itemRef = doc(db, 'items', items[n].id)

    const newImageReferenceArray = []

    const storage = getStorage()
    const storeImage = async (data, index) => {
        return new Promise((resolve, reject) => {
        
            const fileName = `${auth.currentUser.uid}-${uuidv4()}-${data.name}`
            const storageRef = ref(storage, 'images/' + fileName)
            const uploadTask = uploadBytesResumable(storageRef, data)

            // Replace reference at itemCopies[n].data.imageReferenceArray[index]
            newImageReferenceArray.push(fileName)
            // console.log('newImageReferenceArray', newImageReferenceArray)

            uploadTask.on('state_changed', 
            (snapshot) => {
              switch (snapshot.state) {
                case 'paused':
                  //console.log('Upload is paused')
                  break
                case 'running':
                  //console.log('Upload is running')
                  break
              }
            }, 
            (error) => {
                //console.log('Object or Image Storage Failed')
                reject(error)
            }, 
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                resolve(downloadURL)
              })
            }
          )

        })
    }

    // If there are any new images, resize, put them in storage
    // const imgUrls = await Promise.all(
    //   [...imageFiles].map((data, index) => storeImage(data, index))
    // ).catch(() => {
    //     return
    // })

    const newImgUrls = []

    // console.log('image files', imageFiles)
    for(let i = 0; i < imageFiles.length; i++){
      let file = imageFiles[i]
      if (![...Array(4).keys()].includes(file)){
        // Add file to storage
        // Replace reference in the correct index of itemCopies[n].data.imageReferenceArray
        // Replace link in the correct index of itemCopies[n].data.imgUrls

        let imgUrl = await storeImage(file, i).then((result) => {
          newImgUrls.push(result)
          console.log('result', result)
        })

      } else {
        newImageReferenceArray.push(i)
        newImgUrls.push(i)
      }
    }

    // console.log('newImageReferenceArray', newImageReferenceArray)
    // console.log('newImgUrls', newImgUrls)

    for(let i = 0; i < newImageReferenceArray.length; i++){
      if (![...Array(4).keys()].includes(newImageReferenceArray[i])){
        itemCopies[n].data.imageReferenceArray[i] = newImageReferenceArray[i]
      }
    }

    for(let i = 0; i < newImgUrls.length; i++){
      if (![...Array(4).keys()].includes(newImgUrls[i])){
        itemCopies[n].data.imgUrls[i] = newImgUrls[i]
      }
    }

    // console.log('going to update with', itemCopies[n].data)

    await updateDoc(itemRef, itemCopies[n].data)

    // Update items on the page so we don't need to reload
    const updatedItems = []
    for (let i = 0; i < items.length; i++){
      if (i == n){
        updatedItems.push(itemCopies[n])
      } else {
        updatedItems.push(items[i])
      }
    }
    setItems(updatedItems)
  }

  const deleteImage = async (n) => {
    // If the image is just a blob, remove it from the lists and state it appears in
    // Otherwise, get the storage reference and use deleteObject
    if (![...Array(4).keys()].includes(imageFiles[n])){

      console.log('deleting index', n)

      // Remove from imageFiles
      let newImageFiles = [...imageFiles]
      newImageFiles.splice(n, 1)
      setImageFiles(newImageFiles)

      // Remove from itemCopies[editing].data.imgUrls
      // Remove from itemCopies[editing].data.imageReferenceArray
      let newImageUrls = [...itemCopies[editing].data.imgUrls]
      newImageUrls.splice(n, 1) 
      let newImageReferences = [...itemCopies[editing].data.imageReferenceArray]
      newImageReferences.splice(n, 1) 

      updateImages(editing, [...newImageUrls], [...newImageReferences])

      // Remove from draggableImages
      setDraggableImages(newImageUrls)
      
    } else {
      const storage = getStorage()

      let reference = itemCopies[editing].data.imageReferenceArray[n]
      let deleteRefArray = ref(storage, 'images/' + reference)
      
      // Remove from imageFiles
      let newImageFiles = [...imageFiles]
      newImageFiles.splice(n, 1)
      setImageFiles(newImageFiles)

      // Remove from itemCopies[editing].data.imgUrls
      // Remove from itemCopies[editing].data.imageReferenceArray
      let newImageUrls = [...itemCopies[editing].data.imgUrls]
      newImageUrls.splice(n, 1) 
      let newImageReferences = [...itemCopies[editing].data.imageReferenceArray]
      newImageReferences.splice(n, 1)

      // Remove from draggableImages
      setDraggableImages(newImageUrls)

      updateImages(editing, [...newImageUrls], [...newImageReferences])

      setSubmitFlag(true)

      deleteObject(deleteRefArray).then(() => {
          console.log('Image Deletion Successful')
      }).catch((error) => {
          console.log('Image Deletion Failed', error)
      })
      
      // submitItem(editing)

    }
  }
  
  const deleteItem = async (n) => {
    const storage = getStorage()

    //let deleteRefArray = []
    //deleteRefArray = ref(storage, 'models/' + items[n].data.objectReferenceArray[0])
    
    items[n].data.imageReferenceArray.forEach((reference) => {
      // console.log('Delete Image Reference', reference)
      let deleteRefArray = ref(storage, 'images/' + reference)
      deleteObject(deleteRefArray).then(() => {
          // console.log('Image Deletion Successful')
      }).catch((error) => {
          // console.log('Image Deletion Failed', error)
      })
    })

    items[n].data.objectReferenceArray.forEach((reference) => {
      console.log('Delete Model Reference', reference)
      let deleteRefArray = ref(storage, 'models/' + reference)
      deleteObject(deleteRefArray).then(() => {
          // console.log('Model Deletion Successful')
      }).catch((error) => {
          // console.log('Model Deletion Failed', error)
      })
    })

    const updatedItems = []
    for (let i = 0; i < items.length; i++){
      if (i == n){
        continue
      } else if (i == editing) {
        updatedItems.push(itemCopies[i])
      } else {
        updatedItems.push(items[i])
      }
    }
    setItems(updatedItems)

    const updatedItems2 = []
    for (let i = 0; i < items.length; i++){
      if (i == n){
        continue
      } else if (i == editing) {
        updatedItems2.push(itemCopies[i])
      } else {
        updatedItems2.push(items[i])
      }
    }
    setItemCopies(updatedItems2)

    try {
      await deleteDoc(doc(db, "items", items[n].id))
    } catch(error) {
      // console.log(error)
    }

    const userRef = doc(db, 'users', auth.currentUser.uid)
    const docSnap = await getDoc(userRef)
    const numItems = docSnap.data().numItems
    await updateDoc(userRef, {
        numItems: numItems - 1
    })

    // TODO : DELETE REVIEWS
    // TODO : DELETE POINTER TO ITEM IN USER DOC
  }

  const updateImages = (n, imageUrls, imageReferences) => {
    const updatedItems = []
    for (let i = 0; i < itemCopies.length; i++){
      if (i == n){
        // console.log(itemCopies[i])
        const newItem = {
          id: itemCopies[i].id,
          data: {
            ...itemCopies[i].data,
            imageReferenceArray: imageReferences,
            imgUrls: imageUrls, 
          }
        }
        updatedItems.push(newItem)
      } else {
        updatedItems.push(itemCopies[i])
      }
    }
    setItemCopies(updatedItems)
  }

  const updateDescription = (n, description) => {
    const updatedItems = []
    for (let i = 0; i < itemCopies.length; i++){
      if (i == n){
        // console.log(itemCopies[i])
        const newItem = {
          id: itemCopies[i].id,
          data: {
            ...itemCopies[i].data,
            description: description,
          }
        }
        // console.log(newItem)
        updatedItems.push(newItem)
      } else {
        updatedItems.push(itemCopies[i])
      }
    }
    setItemCopies(updatedItems)
  }

  const updateName = (n, name) => {
    // console.log('updating name', n)
    const updatedItems = []
    for (let i = 0; i < itemCopies.length; i++){
      if (i == n){
        // console.log(itemCopies[i])
        const newItem = {
          id: itemCopies[i].id,
          data: {
            ...itemCopies[i].data,
            name: name,
          }
        }
        // console.log(newItem)
        updatedItems.push(newItem)
      } else {
        updatedItems.push(itemCopies[i])
      }
    }
    setItemCopies(updatedItems)
  }

  const fetchPosts = async(userId) => {
    try {
        // Get reference
        const postsRef = collection(db, 'posts')

        // Create a query
        const q = query(
            postsRef,
            where('userId', '==', userId),
        )

        // Execute query
        const querySnap = await getDocs(q)
        
        let posts = []
        querySnap.forEach((doc) => {
            return posts.push(
                doc.data()
            )
        })

        // Sort by timestamp (newest to oldest)
        posts.sort((post1, post2) => {return post2.timestamp - post1.timestamp})

        setPosts(posts)

    } catch(error) {
        // console.log(error)
    }
  }

  const fetchItems = async (userId) => {
    try {
        // Get reference
        const itemRef = collection(db, 'items')

        // Create a query
        const q = query(
            itemRef,
            where('userId', '==', userId)
        )
        
        // Execute query
        const querySnap = await getDocs(q)

        let items = []
        querySnap.forEach((doc) => {
            return items.push({
                id: doc.id,
                data: doc.data()
            })
        })

        setItems(items)
        setItemCopies([...items])

    } catch(error) {
        // console.log(error)
    }
  }

  const submitPost = async() => {
    if (auth.currentUser != null){
      await addDoc(collection(db, 'posts'), {
          title: postTitle,
          body: postMessage,
          timestamp: serverTimestamp(),
          userId: auth.currentUser.uid,
      })

      // Upload post
      setPostTitle('')
      setPostMessage('')

      // Reload posts
      fetchPosts(auth.currentUser.uid)
    }
  }

  useEffect(() => {

      const fetchUser = async (userId) => {
          const docRef = doc(db, 'users', userId)
          const docSnap = await getDoc(docRef)

          //console.log(docSnap.data())
          if(docSnap.exists()){
              setUser(docSnap.data())
          }
      }

      const fetchPrivate = async (userId) => {
        // Get reference
        const privateRef = collection(db, 'private')
        
        // Create a query
        const q = query(
            privateRef,
            where('userId', '==', userId)
        )

        // Execute query
        const querySnap = await getDocs(q)
        
        let privateDetails = []
        querySnap.forEach((doc) => {
            return privateDetails.push({
                id: doc.id,
                data: doc.data()
            })
        })
        setPrivateDetails(privateDetails[0].data)
      }

      if(isMounted){
          onAuthStateChanged(auth, (user) => {
              if(user) {
                  fetchPrivate(auth.currentUser.uid)
                  fetchUser(auth.currentUser.uid)
                  fetchPosts(auth.currentUser.uid)
                  fetchItems(auth.currentUser.uid)
              } else {
                  navigate('/sign-in')
              }
          })
      }
      
      return () => {
          isMounted.current = false
      }

  }, [isMounted])

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const newImgUrls = Array.from(draggableImages);
    const [reorderedImg] = newImgUrls.splice(result.source.index, 1);
    newImgUrls.splice(result.destination.index, 0, reorderedImg);

    const newImgReferences = Array.from(itemCopies[editing].data.imageReferenceArray)
    const [reorderedReference] = newImgReferences.splice(result.source.index, 1);
    newImgReferences.splice(result.destination.index, 0, reorderedReference);

    const newImgFiles = Array.from(imageFiles)
    const [reorderedFiles] = newImgFiles.splice(result.source.index, 1);
    newImgFiles.splice(result.destination.index, 0, reorderedFiles);

    updateImages(editing, newImgUrls, newImgReferences)
    setDraggableImages(newImgUrls);
    setImageFiles(newImgFiles)
  }

  const getThumbnailImage = (e) => {
    const files = e.target.files
    const file = e.target.files[0]
    if(!file){
        return
    }
    let images = []
    Array.from(files).forEach((file) => {
      return images.push({
          url: URL.createObjectURL(file)
      })
    })

    return images
  }

  const resizeFile = (image, imageType) => {
    return new Promise((resolve) => {
        Resizer.imageFileResizer(
            image,
            1000,
            1000,
            imageType,
            80,
            0,
            (uri) => {
                resolve(uri)
            },
            "file"
        )
    })
  }

  const onItemImageChange = async (e) => {
    e.preventDefault()
    if(e.target.files){
        const thumbnailUrl = getThumbnailImage(e)
        // Resizing images before putting them into itemData
        let resizedImages = []
        Array.from(e.target.files).forEach(async (file) => {
            try {
                const image = resizeFile(file, file.type.toString().substring(6).toUpperCase()).then(
                  resizedImage => {
                    setImageFiles([...imageFiles, resizedImage])
                    console.log(resizedImage)
                  }
                )

                let imageUrls = Array.from(itemCopies[editing].data.imgUrls)
                let imageReferences = Array.from(itemCopies[editing].data.imageReferenceArray)

                //console.log('thumbnailUrl', thumbnailUrl)

                updateImages(editing, [...imageUrls, thumbnailUrl[0].url], [...imageReferences, 'test'])
                setDraggableImages([...imageUrls, thumbnailUrl[0].url])
            } catch (error) {
                console.log('Image Resize unsuccessful', error)
            }
        })
    }
    // Clear the file input field
    // Without this, uploading, deleting, and trying to reupload the same file does not work
    e.target.value = null
  }

  if (user == null){
    return <>Loading</>
  }

  return (
    <div className='flex flex-col m-4 bg-base-200 rounded-lg 2xl:w-[60%] xl:w-[60%] lg:w-[70%] md:w-[80%]
    2xl:mx-auto xl:mx-auto lg:mx-auto md:mx-auto 2xl:my-8 xl:my-8 lg:my-8 md:my-8'>
      <div className='card p-8'>
        <div className='font-bold text-2xl'>
          Creator Tools
        </div>
        <div className='font-bold text-lg mt-4'>
          Analytics
        </div>
        <div className='font-light text-md'>
          Add widgets to customize the breakdown you want of your store.
        </div>
        <div className='grid grid-cols-2 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 gap-4 mt-4'>
          <div className='aspect-[1/1] flex bg-base-300 rounded-lg p-2'>
            <div className='flex flex-col mx-auto my-auto'>
              <div className='font-bold text-4xl flex mt-4 mx-auto'>
                <span>$</span>
                <AnimatedNumber number={Number(privateDetails.earnings).toFixed(2)}/>
              </div>
              <button className='btn btn-primary btn-sm mx-auto my-4' disabled={privateDetails.earnings == 0}>
                Withdraw
              </button>
            </div>
          </div>
          <div className='aspect-[1/1] flex flex-col bg-base-300 rounded-lg'>
            <div className='flex mx-auto mt-auto'>
              {
                user.numRatings > 0 ?
                <>{[...Array(5).keys()].map((n) => (
                  <>
                      {n < (user.totalRating / user.numRatings) ?
                          <RiStarSFill key={'star' + n.toString()} size={25} />
                          : <RiStarSLine key={'star' + n.toString()} size={25} />
                      }
                  </>
                ))}
                </>
                : <div className='font-light text-lg'>No Rating</div>
              }
            </div>
            <div className='font-light text-sm mx-auto mb-auto'>
                Out of {user.numRatings} review{user.numRatings == 1 ? '' : 's'}
            </div>
          </div>
          <div className='aspect-[1/1] flex flex-col bg-base-300 rounded-lg p-2'>
            <div className='mx-auto my-auto'>
              <div className='font-bold text-lg text-center'>
                Sales: <span className='font-light'>{user.numSales}</span>
              </div>
              <div className='font-bold text-lg text-center'>
                Revenue: <span className='font-light'>${Number(privateDetails.allTimeRevenue).toFixed(2).replace(/[.,]0$/, '')}</span>
              </div>
            </div>
          </div>
          <div className='aspect-[1/1] flex bg-base-300 rounded-lg'>
            <div className='tooltip p-1 my-auto mx-auto' data-tip={`More analytics coming soon!`}>
              <button className='btn btn-ghost'>
                <FaPlus size={25} />
              </button>
            </div>
          </div>
        </div>
        <div className='font-bold text-lg mt-4'>
          Posts
        </div>
        <div className='font-light text-md'>
          Create announcements about upcoming items, deals, and status updates to be posted on your profile.
        </div>
        <div className='grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-4 mt-4'>
          <div className='aspect-[2/1] flex flex-col bg-base-300 rounded-lg'>
            <div className='flex flex-row m-4 mb-auto'>
              <div className='font-bold text-lg my-auto'>
                Title
              </div>
              <input
                required
                minLength={1}
                maxLength={50}
                placeholder='title' 
                id='title' 
                value={postTitle}
                onChange={(e) => setPostTitle(e.target.value)}
                className="input flex-grow textarea textarea-primary ml-4 my-auto"
                type='text' 
              ></input>
            </div>
            <div className='flex flex-row mx-4 mb-auto'>
              <div className='font-bold text-lg my-auto'>
                Message
              </div>
              <input
                required
                minLength={1}
                maxLength={150}
                placeholder='message' 
                id='title' 
                value={postMessage}
                onChange={(e) => setPostMessage(e.target.value)}
                className="flex-grow textarea textarea-primary ml-4 mt-4"
                type='text' 
              ></input>
            </div>
            <button 
              className='btn btn-primary mx-auto my-4' 
              disabled={postTitle == '' || postMessage == ''}
              onClick={submitPost}
            >
              Post
            </button>
          </div>
          <div className='aspect-[2/1] flex bg-base-300 rounded-lg'>
            <div className='my-auto ml-4'>
              <button className={`btn btn-ghost`} disabled={currentPost == 0} onClick={() => setCurrentPost(currentPost-1)}>
                  <BsFillCaretLeftFill size={20} />
              </button>
              <div className='font-light text-xs text-center mt-2'>
                Newer
              </div>
            </div>
            { posts.length > 0 ?
                <div className='flex flex-col mx-auto my-auto p-4'>
                    <div className='font-bold text-lg text-center'>
                        {posts[currentPost].title}
                    </div>
                    <div className='font-light text-md mx-auto my-2'>
                        {posts[currentPost].body}
                    </div>
                    <div className='font-light text-xs mx-auto mt-4'>
                        {new Date(posts[currentPost].timestamp.seconds * 1000).toLocaleTimeString("en-US")}
                    </div>
                    <div className='font-light text-xs mx-auto'>
                        {new Date(posts[currentPost].timestamp.seconds * 1000).toLocaleDateString("en-US")}
                    </div>
                </div>
                : 
                <div className='font-light mx-auto my-auto'>
                  You have no posts.
                </div>
            }
            {/* ${currentPost < posts.length ? 'hidden' : 'block'} */}
            <div className='my-auto mr-4'>
              <button className={`btn btn-ghost`} disabled={currentPost == posts.length-1 || posts.length == 0} onClick={() => setCurrentPost(currentPost+1)}>
                  <BsFillCaretRightFill size={20} />
              </button>
              <div className='font-light text-xs text-center mt-2'>
                Older
              </div>
            </div>
          </div>
        </div>
        
        <div className='flex mt-4'>
          <div>
            <div className='font-bold text-lg'>
              Manage Items
            </div>
            <div className='font-light text-md'>
              Upload, edit, hide, and delete your items here. If you have any concerns about your items, please <Link className='text-primary' to={'/about'}>contact us.</Link>
            </div>
          </div>
          <Link className='text-primary ml-auto my-auto' to={'/item-upload'}>
            <button className='btn btn-primary'>
              Upload Item
            </button>
          </Link>
        </div>
      
        <div className='grid gap-4 mt-4 grid-cols-1'>
          {
            [...Array(itemCopies.length).keys()].map((n) => (
            <div key={'other' + n.toString()} className={`flex flex-col bg-base-300 rounded-lg p-4 ${itemCopies[n].data.hidden ? 'opacity-50' : ''}`}>
              <div className='flex flex-col'>
                <div className='flex flex-row'>
                  {editing != n ?
                    items[n].data.imgUrls.map((imageUrl, index) => (
                      <img className={`object-cover rounded-lg hover:cursor-pointer m-2 w-12 h-12 2xl:w-36 xl:w-36 lg:w-36 md:w-24 sm:w-24
                      2xl:h-36 xl:h-36 lg:h-36 md:h-24 sm:h-24`} 
                        src = {imageUrl}
                      />
                    ))
                    :
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId='itemImages' direction='horizontal'>
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef}
                              className='itemImages flex'
                              {...provided.droppableProps} 
                          >
                            {
                              draggableImages.map((imageUrl, index) => {
                                return (
                                  <Draggable key={'draggable' + imageUrl} draggableId={'draggable' + imageUrl} index={index}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <div className='indicator'>
                                          <div className='indicator-item mr-2 mt-2'>
                                            {
                                              draggableImages.length > 1 ?
                                                <label className='btn btn-error btn-xs btn-circle modal-button 2xl:btn-sm xl:btn-sm lg:btn-sm md:btn-sm sm:btn-sm'
                                                 htmlFor={'deleteimage' + index}>
                                                  <FaTimes size={15} />
                                                </label>
                                              : <></>
                                            }
                                            
                                          </div>
                                          <img className={`object-cover rounded-lg hover:cursor-pointer m-2 w-12 h-12 2xl:w-36 xl:w-36 lg:w-36 md:w-24 sm:w-24
                                            2xl:h-36 xl:h-36 lg:h-36 md:h-24 sm:h-24`} 
                                            src = {imageUrl}
                                          />
                                        </div>
                                        <input value={'deleteimage' + index} type="checkbox" id={'deleteimage' + index} className="modal-toggle"/>
                                        <div className="modal">
                                          <div className="modal-box flex flex-col">
                                            <h3 className="font-bold text-xl text-white">Are you sure you want to delete this image?</h3>
                                            <div className='font-light text-white mt-2'>
                                              You won't be able to recover it even after pressing reset.
                                            </div>
                                            <img className={`w-48 h-48 object-cover rounded-lg hover:cursor-pointer mx-auto mt-4`} 
                                              src = {imageUrl}
                                            />
                                            <div className="modal-action">
                                              <label htmlFor={'deleteimage' + index} className="btn btn-error text-white" onClick={() => {deleteImage(index)}}>Yes, delete image!</label>
                                              <label htmlFor={'deleteimage' + index} className="btn btn-info text-white">No, keep my image!</label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  }
                  
                  {
                    [...Array(4 - itemCopies[n].data.imgUrls.length).keys()].map((m, index) => (
                      <div className='rounded-lg bg-base-200 flex m-2 w-12 h-12 2xl:w-36 xl:w-36 lg:w-36 md:w-24 sm:w-24
                      2xl:h-36 xl:h-36 lg:h-36 md:h-24 sm:h-24'>
                        {(m == 0 && editing == n) ?
                          <div className='m-auto'>
                            <label for={'imageupload' + n.toString()} className='btn btn-ghost m-auto flex 2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden'>
                              <FaPlus size={15} className='m-auto' />
                            </label>
                            <label for={'imageupload' + n.toString()} className='btn btn-ghost m-auto hidden 2xl:flex xl:flex lg:flex md:flex sm:flex'>
                              <FaPlus size={25} className='m-auto'  />
                            </label>
                            <input
                                //required
                                style={{display: 'none'}}
                                placeholder={'imageupload' + n.toString()}
                                id={'imageupload' + n.toString()}
                                className=""
                                onChange={onItemImageChange}
                                max='1'
                                accept=".jpg,.png,.gif,.jpeg"
                                type="file"
                            />
                          </div>
                          : 
                          <></>
                        }
                      </div>
                    ))}
                </div>
                <div className='font-bold text-lg m-2'>
                  {editing == n ? 
                    <input
                      required
                      minLength={1}
                      maxLength={50}
                      placeholder='message' 
                      id='title' 
                      value={itemCopies[n].data.name}
                      onChange={(e) => updateName(n, e.target.value)}
                      className="textarea textarea-primary"
                      type='text' 
                    />
                  : items[n].data.name}
                </div>
                <div className='mt-2 ml-4'>
                  <div className='font-bold text-md'>
                    Item Sales: <span className='font-light text-md'>{items[n].data.numSales}</span>
                  </div>
                  {/* <div className='font-bold text-lg'>
                    Revenue from Item: <span className='font-light text-md'>$524.89</span>
                  </div> */}
                  <div className='font-bold text-md mt-4'>
                    Description
                  </div>
                  <div className='font-light text-md mt-2'>
                    {editing == n ?
                        <textarea
                          required
                          minLength={1}
                          maxLength={200}
                          placeholder='message' 
                          id='title' 
                          value={itemCopies[n].data.description}
                          onChange={(e) => updateDescription(n, e.target.value)}
                          className="w-[100%] textarea textarea-primary h-max"
                          type='text' 
                        />
                    : items[n].data.description}
                  </div>
                </div>
                <div className='flex flex-col mt-4 sm:flex-row'>
                  <button className='btn btn-primary btn-sm my-auto ml-4' onClick={() => navigate('/item/'+items[n].id)}>
                    Visit Item Page
                  </button>
                  <div className='ml-auto mr-auto mt-4 sm:mt-0 sm:mr-8'>
                    <div className='tooltip mr-2 ' data-tip='Hide this item from other users in searches and on your profile'>
                      <button className='btn btn-ghost' onClick={() => hideItem(n)}>
                        {itemCopies[n].data.hidden == false ? 
                          <RiEyeFill size={20}/>
                          : <RiEyeOffFill size={20}/>
                        }
                      </button>
                    </div>
                    <button className='btn btn-ghost mr-2' onClick={() => {
                      if (editing == n){
                        setEditing(-1)
                        resetItem(n)
                        setDraggableImages([])
                        setImageFiles([])
                      } else {
                        resetItem(n)
                        setEditing(n)
                        setDraggableImages(itemCopies[n].data.imgUrls)
                        setImageFiles([...Array(itemCopies[n].data.imgUrls.length).keys()])
                      }
                    }}>
                      <FiEdit size={20}/>
                    </button>

                    <label className='btn btn-ghost modal-button' htmlFor={(n+1)}>
                      <FiTrash size={20}/>
                    </label>
                    <input value={(n+1)} type="checkbox" id={(n+1)} className="modal-toggle"/>
                    <div className="modal">
                      <div className="modal-box">
                        <h3 className="font-bold text-xl text-white">Are you sure you want to delete this item forever?</h3>
                        <div className='font-light text-white mt-2'>
                          You won't be able to recover this item or any of its associated statistics. (It's reviews will still be reflected in your
                          accounts overall rating).
                        </div>
                        <div className="modal-action">
                          <label htmlFor={(n+1)} className="btn btn-error text-white" onClick={() => {deleteItem(n)}}>Yes, delete item!</label>
                          <label htmlFor={(n+1)} className="btn btn-info text-white">No, keep my item!</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className='mt-4 ml-auto mr-auto sm:mr-0 sm:mt-0 sm:ml-0'>
                    <button className='btn btn-primary' disabled={_.isEqual(items[n], itemCopies[n])} onClick={() => {
                      submitItem(n)
                      setEditing(-1)
                    }}>Save</button>
                    <button className='btn btn-neutral ml-2' disabled={_.isEqual(items[n], itemCopies[n])} onClick={() => resetItem(n)}>Reset</button>
                  </div>
                  
                </div>
              </div>
            </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default CreatorTools