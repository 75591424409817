import { useState, useEffect, useRef } from 'react'
import { v4 as uuidv4} from 'uuid'
import { db } from '../firebase.config'
import { updateDoc, addDoc, doc, getDoc, onSnapshot, createDoc, collection, getDocs, query, orderBy, where, limit, Timestamp, startAfter  } from 'firebase/firestore'
// import { useCollectionData } from 'react-firebase-hooks/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import Message from '../components/Message'
import MessageContent from '../components/MessageContent'
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage'
import Resizer from "react-image-file-resizer"
import { FiSend, FiUpload, FiPlus } from 'react-icons/fi'

function Messages() {

    const auth = getAuth()
    const [messageList, setMessageList] = useState('')
    const [messageArray, setMessageArray] = useState([])
    const [lastFetchedMessage, setLastFetchedMessage] = useState(null)
    const [moreMessagesToLoad, setMoreMessagesToLoad] = useState(true)
    const [thumbnailUrl, setThumbnailUrl] = useState([])
    const [submitFlag, setSubmitFlag] = useState(true)
    const [disableSend, setDisableSend] = useState(false)
    const [messageText, setMessageText] = useState('')
    const [resizedMessagePic, setResizedMessagePic] = useState('')
    const [convoName, setConvoName] = useState(null)
    const [currentConvo, setCurrentConvo] = useState(null)
    const isMounted = useRef(true)

    useEffect(() => {
        const getUsersInConvo = async () => {
            const messageRef = collection(db, "messages")
            const q = query(messageRef, where("userArray", "array-contains", auth.currentUser.uid))
            //const q = query(messageRef, where("userArray", "array-contains", auth.currentUser.uid))
            onSnapshot(q, (querySnapshot) => {
                let msgs = [];
                querySnapshot.forEach((doc) => {
                msgs.push({
                        id: doc.id,
                        reciever: doc.data().userArray.filter(function(item){return item !== auth.currentUser.uid}),
                        lastMessage: doc.data().lastMessage,
                        read: doc.data().read,
                        lastMessageSender: doc.data().lastMessageSender
                    })
                })
                setMessageList(msgs)
            })
        }

        if(isMounted){
            onAuthStateChanged(auth, (user) => {
                if(user) {
                    getUsersInConvo()
                } else {
                    setMessageList(null)
                }
            })
        }
        return () => {
            isMounted.current = false
        }
  
    }, [isMounted])

    const resizeFile = (image, imageType, maxWidth, maxHeight, quality) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                image,
                maxWidth,
                maxHeight,
                imageType,
                quality,
                0,
                (uri) => {
                    resolve(uri)
                },
                "file"
            )
        })
    }

    const getProfilePicPreview = (e) => {
        const files = e.target.files
        const file = e.target.files[0]
        if(!file){
            return
        }
        let images = []
        Array.from(files).forEach((file) => {
            return images.push({
                url: URL.createObjectURL(file)
            })
        })

        setThumbnailUrl(images)
        //console.log(thumbnailUrl)
    }

    const onMessagePicChange = (e) => {
        //Files
        if(e.target.files){
            getProfilePicPreview(e)
            console.log('setting new file', e.target.files)
            let resizedImages = []
            Array.from(e.target.files).forEach(async (file) => {
                try {
                    const image = resizeFile(file, file.type.toString().substring(6).toUpperCase(), 1000, 1000, 100).then(resizedImage => resizedImages.push(resizedImage))
                    setResizedMessagePic(resizedImages)
                } catch (error) {
                    console.log('Image Resize unsuccessful', error)
                }
            })
        }
    }

    const selectConvo = async (id, lastMessageSender) => {
        setCurrentConvo(id)
    
        if(lastMessageSender != auth.currentUser.uid){
            await updateDoc(doc(db, "messages", id), {
                read: true
            })
        }
        
        const msgsRef = collection(db, "messages", id, "message")
        const q = query(msgsRef, orderBy("timestamp", "desc"), limit(20))
        onSnapshot(q, (querySnapshot) => {
            let msgs = []
            querySnapshot.forEach((doc) => {
                msgs.push(doc.data())
                //console.log(doc.data())
            })
            if(msgs.length < 20){
                setMoreMessagesToLoad(false)
            }
            setLastFetchedMessage(querySnapshot.docs[querySnapshot.docs.length-1])
            msgs.reverse()
            setMessageArray(msgs)
        })
    }

    const loadPreviousMessages = async () => {
            // Get reference
            const msgsRef = collection(db, "messages", currentConvo, "message")

            // Create a query
            const q = query(
                msgsRef,
                orderBy("timestamp", "desc"), 
                limit(20),
                startAfter(lastFetchedMessage)
            )

            // Execute query
            const querySnap = await getDocs(q)
            const lastVisible = querySnap.docs[querySnap.docs.length-1]
            setLastFetchedMessage(lastVisible)

            let messages = []
            querySnap.forEach((doc) => {
                return messages.push(doc.data())
            })

            setMessageArray((prevState) => [...messages, ...prevState])

    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setDisableSend(true)

        const imageReferenceArray = []
        //Store image in firebase
        const storeImage = async (image) => {
            return new Promise((resolve, reject) => {
                const storage = getStorage()
                const filename = `${auth.currentUser.uid}-${uuidv4()}-${image.name}`
                const storageRef = ref(storage, 'messagePics/' + filename)
                const uploadTask = uploadBytesResumable(storageRef, image)
                console.log('Inside store image')
                imageReferenceArray.push(filename)

                uploadTask.on('state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        console.log('Upload is ' + progress + '% done')
                        switch (snapshot.state){
                            case 'paused':
                                console.log('Upload is paused')
                                break
                            case 'running':
                                console.log('Upload is running')
                                break
                        }
                    },
                    (error) => {
                        reject(error)
                    },
                    () => {
                        // Handle successful uploads on complete
                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            console.log(downloadURL)
                            resolve(downloadURL)
                        })
                    }
                )
            })
        }

        const imgUrls = await Promise.all(
            [...resizedMessagePic].map((data) => storeImage(data))
        ).catch(() => {
            //setLoading(false)
            return
        })

        if(submitFlag == true){
            {imgUrls.length == 0 ? 
            
                await addDoc(collection(db, "messages", currentConvo, "message"), {
                    messageText,
                    imageReferenceArray,
                    sender: auth.currentUser.uid,
                    timestamp: Timestamp.fromDate(new Date()),
                  })
                :
                await addDoc(collection(db, "messages", currentConvo, "message"), {
                    messageText,
                    imgUrls: imgUrls[0],
                    imageReferenceArray,
                    sender: auth.currentUser.uid,
                    timestamp: Timestamp.fromDate(new Date()),
                  })
                }
            
                await updateDoc(doc(db, "messages", currentConvo), {
                  lastMessage: messageText,
                  timestamp: Timestamp.fromDate(new Date()),
                  lastMessageSender: auth.currentUser.uid,
                  read: false
                })
        }

        setMessageText('')
        setResizedMessagePic([])
        setThumbnailUrl([])
        setDisableSend(false)
        setSubmitFlag(true)
    }

    const fetchConvoName = (name) => {
        setConvoName(name)
    }

    const imageInput = [
        <div className='mr-2'>
            <label for='image' className='btn btn-primary btn-sm'>
                <FiUpload size={20} color={'white'} />
            </label>
            <input
                //required
                style={{display: 'none'}}
                placeholder="image" 
                id="image"
                className=""
                onChange={onMessagePicChange}
                max='1'
                accept=".jpg,.png,.gif,.jpeg"
                type="file"
            />
        </div>
    ]

    return (
    <div className='flex flex-col 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col m-8'>
        <div className='basis-1/5 bg-base-100'>
            <span className='ml-2 text-lg font-medium'>Messages</span>
            {messageList.length
                ? messageList.map((msg, i) => (
                    <Message key={i} id={msg.id} recieverID={msg.reciever} setConvoName={fetchConvoName} selectConvo={selectConvo} lastMessage={msg.lastMessage} lastMessageSender={msg.lastMessageSender} currentUser={auth.currentUser.uid} read={msg.read} currentConvo={currentConvo} />
                  ))
                : null}
        </div>
        <div className='basis-4/5 flex flex-col justify-start rounded-lg p-2 mr-2'>
            <div className='self-start text-lg font-medium'>{convoName == null ? '' : `Conversation with ${convoName}`}</div>
            <div  style = {{height:"70vh"}} className='flex flex-col justify-between bg-base-300 rounded-lg'>
                <div className='overflow-y-auto flex flex-col last:mb-8'>
                    {/* Put the chat history here */}
                    <button type='button' className='btn btn-ghost btn-sm w-1/4 mx-auto mt-2 p-2' onClick={loadPreviousMessages}>load earlier messages</button>
                    {currentConvo != null ? 
                    auth.currentUser != null ? 
                        messageArray.length ?
                            messageArray.map((msg, i) => (
                                    <MessageContent key={i} messageText={msg.messageText} sender={msg.sender} timestamp={msg.timestamp} currentUser={auth.currentUser.uid} imgUrl={msg.imgUrls} />
                            ))
                            :
                            <div className='mx-auto my-60 opacity-75'>No Messages</div>
                        :
                        <></>
                        :
                        <div className='mx-auto mt-52'>Select a conversation to begin</div>
                    }
                </div>
                <div className={`flex flex-col justify-center w-full mb-4 ${currentConvo == null ? 'hidden' : ''}`}>
                    <div className="flex">
                        {thumbnailUrl.map((url) => (
                            <div className='my-2 mx-auto p-2'>
                                <img src={url.url} alt="Uploaded Image" className="rounded-lg max-h-32"/>
                            </div>
                        ))}
                    </div>
                    <form onSubmit={(e) => {
                        onSubmit(e),
                        setSubmitFlag(false)
                    }} className='flex justify-center mx-auto w-1/2'>
                        {imageInput}
                        <input minLength={1} maxLength={500} className='input input-sm w-full input-primary input-bordered mr-2' value={messageText} type="text" onChange={(e) => setMessageText(e.target.value)}/>
                        <button type='submit' disabled={(messageText.length == 0) && (thumbnailUrl.length == 0) | disableSend ? true : false} className='btn btn-primary btn-sm'>Send</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Messages