import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { doc, getDoc, getDocs, collection, where, query, orderBy } from 'firebase/firestore'
import { db } from '../firebase.config'
import OrderCard from '../components/OrderCard'

function OrderHistory() {

    const [orders, setOrders] = useState([])
    const isMounted = useRef(true)
    const auth = getAuth()
    const navigate = useNavigate()

    useEffect(() => {
        document.title = 'Layolay | Order History'
    }, [])

    useEffect(() => {
        const fetchOrders = async (userId) => {
            console.log('fetching orders for', userId)
            try {
                // Get reference
                const orderRef = collection(db, 'orders')
        
                // Create a query
                const q = query(
                    orderRef,
                    where('userId', '==', userId),
                    orderBy('timestamp')
                )
                
                // Execute query
                const querySnap = await getDocs(q)
        
                let orders = []
                querySnap.forEach((doc) => {
                    return orders.push({
                        id: doc.id,
                        data: doc.data()
                    })
                })

                orders = orders.reverse()
        
                setOrders(orders)
        
            } catch(error) {
                console.log(error)
            }
        }

        if(isMounted){
            onAuthStateChanged(auth, (user) => {
                if(user) {
                    fetchOrders(auth.currentUser.uid)
                } else {
                    navigate('/sign-in')
                }
            })
        }
        
        return () => {
            isMounted.current = false
        }
  
    }, [isMounted])

    return (
        <div className='flex flex-col m-4 bg-base-200 rounded-lg 2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[90%]
        2xl:mx-auto xl:mx-auto lg:mx-auto md:mx-auto 2xl:my-8 xl:my-8 lg:my-8 md:my-8'>
            <div className='card p-8'>
                <div className='font-bold text-2xl'>
                    Order History
                </div>
                {orders.length > 0 ?
                    orders.map((order) => (
                        <OrderCard key={order.id} id={order.id} data={order.data} />
                    ))
                    :
                    <div className='flex flex-col mt-4 mx-auto'>
                        <div className='font-light text-md mx-auto'>
                            You haven't purchased anything on Layolay yet.
                        </div>
                        <button className='btn btn-primary center mx-auto mt-4 w-64' onClick = {() => navigate('/')}>
                            Start Shopping
                        </button>
                    </div>
                    
                }
            </div>
        </div>
    )
}

export default OrderHistory