import { Link } from "react-router-dom"

function SignInCard() {
    return (
        <div className='pl-3'>
            <div className='card card-bordered text-center bg-base-200'>
                <div className="card-body p-4">
                    Already have an account?
                    <div className='pt-2'>
                        <Link to='/sign-in' className='btn btn-primary'>Sign In</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignInCard
