import { useRef, useEffect, useState } from 'react'

function MessageContent({ messageText, sender, currentUser, timestamp, imgUrl }) {

  const scrollRef = useRef()
  const date = timestamp.toDate().toString().slice(0,24)
  const [showDate, setShowDate] = useState('hidden')
  console.log(imgUrl)

  useEffect(() => {
    scrollRef.current?.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
  }, [messageText])

  return (
  <>  
    <div className={`${sender == currentUser ? `self-end ${showDate == 'flex' ? 'max-w-[80%]' : 'max-w-[50%]'}` : 'self-start w-full'} flex bottom-0`}>
        <div className={`self-center opacity-50 ${showDate} ${sender == currentUser ? 'order-first' : 'order-last'}`}>{date}</div> 
        <div ref={scrollRef} onMouseOver={() => {setShowDate('flex')}} onMouseLeave={() => {setShowDate('hidden')}} className={`flex ${sender == currentUser ? `${showDate == 'flex' ? '' : 'max-w-[100%]'}` : 'max-w-[50%]'} flex-col`}>
            {imgUrl != null ?
                <div className={`${sender == currentUser ? 'self-end bg-primary mr-4 rounded-tl-lg rounded-tr-lg rounded-bl-lg' : 'self-start bg-base-100 ml-4 rounded-tl-lg rounded-tr-lg rounded-br-lg'} p-2 m-2`}>
                    <img src={imgUrl} className='rounded-lg h-52'/>
                </div>
                
                :
                <></>
            }
            <div className={`${messageText.length == 0 ? 'hidden' : ''} ${sender == currentUser ? 'self-end bg-primary mr-4 rounded-tl-lg rounded-tr-lg rounded-bl-lg' : 'self-start bg-base-100 ml-4 rounded-tl-lg rounded-tr-lg rounded-br-lg'} p-2 m-2`}>
                {messageText}
            </div>
        </div>
    </div>
  </>
  )
}

export default MessageContent