import { useNavigate, Link } from "react-router-dom"
import { doc, getDoc } from 'firebase/firestore'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import { db } from '../firebase.config'
import { useState, useEffect } from 'react'
import Spinner from './Spinner'

function ItemCard({id, item: {description, imgUrls, name, prices, userId, imageReferenceArray}}) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [thumbnailUrl, setThumbnailUrl] = useState('')
    const [user, setUser] = useState({})

    useEffect(() => {
        const fetchUser = async () => {
            const docRef = doc(db, 'users', userId)
            const docSnap = await getDoc(docRef)
            
            if(docSnap.exists()){
                setUser(docSnap.data())
            }
            setLoading(false)
        }

        // const fetchThumbnailUrl = () =>{
        //     const storage = getStorage()
        //     getDownloadURL(ref(storage, `images/${imageReferenceArray[0]}`)).then((url) => setThumbnailUrl(url))
        // }

        if (id !== null){
            fetchUser()
        }
        
    }, [userId])

    // for blank item cards on user cards
    if (id == null){
        return (
            <div className='min-h-full rounded-md p-4 hover:shadow-[0_3px_4px_2px_rgba(0,0,0,0.5)] hover:shadow-base-300 bg-base-200' style={{boxShadow: 'inset'}}>
            </div>
        )
    }

    if (loading){
        return <Spinner />
    }

    return (
        <div className='rounded-md p-4 hover:shadow-[0_3px_4px_2px_rgba(0,0,0,0.5)] hover:shadow-base-400 bg-base-200' style={{boxShadow: 'inset'}}>
        {/* <div className='rounded-md p-4 hover:shadow-[0_3px_4px_2px_rgba(0,0,0,0.5)] hover:shadow-base-300' style={{boxShadow: 'inset'}}> */}
            {/* <div className='rounded-md p-4 hover:shadow-inner hover:shadow-slate-500 '> */}
            <div className="transition ease-in-out hover:cursor-pointer duration-150">
                <div className="mx-auto">
                    <Link to={`/item/${id}`}>
                        <div className='relative overflow-hidden pb-24 2xl:pb-60 xl:pb-60 lg:pb-60 md:pb-60 sm:pb-60'>
                            <img className='absolute h-24 w-full rounded-md object-cover 2xl:h-full xl:h-full lg:h-full md:h-full sm:h-full' 
                            src={imgUrls[0]} alt={name} onClick={() => {navigate(`/item/${id}`)}} />
                        </div>
                        <div className='truncate my-auto font-light text-lg mt-2'>
                            {name}
                        </div>
                    </Link>
                    {/* <div className='my-auto'>${Number(price).toFixed(2).replace(/[.,]0$/, '')}</div> */}
                    <div className='my-auto'>${Number(prices['Small']).toFixed(2).replace(/[.,]0$/, '')}</div>
                    <div className="pl-2">
                        <Link to={`/profile/${userId}`}><p className='font-light text-sm my-auto'>By {user.name}</p></Link>
                    </div>
                </div> 
            </div> 
        </div>

    )
}

export default ItemCard
