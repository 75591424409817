import { FaStar } from "react-icons/fa"
import { BsFillCartCheckFill } from 'react-icons/bs'
import { GiPaintBrush } from 'react-icons/gi'
import { RiStarSLine, RiStarSFill } from 'react-icons/ri'
import { Link, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { collection, getDocs, query, orderBy, where, limit } from 'firebase/firestore'
import { db } from '../firebase.config'
import ItemCard from "./ItemCard"

function UserCard({id, user: {name, bio, numItems, numSales, numRatings, totalRating, profilePic}}) { 

    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])

    const navigate = useNavigate()

    const blankItem = {
        id: null,
        data: {description: null,
                imgUrls: null,
                name: null,
                price: null,
                userId: null
            }
    }

    const getItems = async () => {
        setLoading(true)

        try {
            // Get reference
            const itemRef = collection(db, 'items')

            // Create a query
            const q = query(
                itemRef,
                where('userId', "==", id),
                where('hidden', '==', false),
                limit(4),
            )

            // Execute query
            const querySnap = await getDocs(q)
            
            let items = []
            querySnap.forEach((doc) => {
                return items.push({
                    id: doc.id,
                    data: doc.data()
                })
            })

            if (items.length < 4){
                const spotsToFill = 4 - items.length;
                for (let i = 0; i < spotsToFill; i++){
                    items.push(blankItem)
                }
            }

            setItems(items)

        } catch(error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getItems()
        setLoading(false)
    }, [])

    return (
        <div className="card lg:card-side shadow-lg drop-shadow-2xl card-bordered bg-base-300 flex flex-col">
            <div className="basis-1/3 flex flex-col 2xl:basis-1/4 xl:basis-1/4">
                <div className="basis-1/3 flex m-8 mb-4">
                    <div className="flex-none">
                        <Link to={`/profile/${id}`}><img src={profilePic} className="object-cover h-32 w-32 rounded-xl p-1"/></Link>
                    </div>
                    <div className="flex-col flex content-start p-2 pl-4 ">
                        <div className="text-white font-bold text-xl">
                            {name} 
                        </div>
                        <div className="mt-4">
                            {
                                numRatings === 0
                                    ? <>
                                        <div className="font-medium stat-title text-sm">No Ratings</div>
                                        <div className="stat-desc text-info"></div>
                                        </>
                                    : <>
                                        {/* <div className="font-medium text-xl">{(totalRating/numRatings).toFixed(1).replace(/[.,]0$/, '')} / 5</div> */}
                                        <div className='flex'>
                                            {[...Array(5).keys()].map((n) => (
                                                <>
                                                    {n < (totalRating / numRatings) ?
                                                        <RiStarSFill key={n} size={25} />
                                                        : <RiStarSLine key={n} size={25} />
                                                    }
                                                </>
                                            ))}
                                        </div>
                                        <div className="stat-desc stat-title">
                                            Out of {numRatings} {numRatings === 1 ? 'Review' : 'Reviews'}
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
                <div className="basis-2/3 mb-8 flex flex-col">
                    <div className='font-light text-sm ml-8 mr-8 2xl:mr-2 xl:mr-2 lg:mr-2'>
                        {bio}
                    </div>
                    <div className="flex flex-row mt-4 mx-auto">
                        <div className='flex flex-row'>
                            <div className='font-light text-white opacity-50 basis-1/3'>
                                Creations  
                            </div>
                            <div className='basis-2/3 font-light ml-2'>
                                {numItems}
                            </div>
                        </div>
                        <div className='flex flex-row ml-4'>
                            <div className='font-light text-white opacity-50 basis-1/3'>
                                Sales 
                            </div>
                            <div className='basis-2/3 font-light ml-2'>
                                {numSales}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="basis-2/3 flex flex-row my-2 bg-base-300 rounded-xl mx-2 2xl:basis-3/4 xl:basis-3/4">
                {items.map((item) => (
                        <div className="basis-1/4 m-2 hidden 2xl:block xl:block">
                            <ItemCard key={item.id} id={item.id} item={item.data} />
                        </div>
                    ))}
                {items.slice(0,3).map((item) => (
                        <div className="basis-1/3 m-2 hidden 2xl:hidden xl:hidden lg:block md:block">
                            <ItemCard key={item.id} id={item.id} item={item.data} />
                        </div>
                    ))}
                {/* {items.slice(0,2).map((item) => (
                        <div className="basis-1/2 m-2 hidden 2xl:hidden xl:hidden lg:hidden md:hidden sm:block">
                            <ItemCard key={item.id} id={item.id} item={item.data} />
                        </div>
                    ))} */}
                <div className='mr-2 hidden flex-row mb-2 2xl:hidden xl:hidden lg:hidden md:hidden sm:flex'>
                    {items.length > 0 ?
                        <>
                            <div className='basis-1/3 ml-2 my-auto'>
                                <img src={items[0].id != null ? items[0].data.imgUrls[0] : ''} className='rounded-lg' onClick={() => {navigate(`/item/${items[0].id}`)}}></img>
                            </div>
                            <div className={`basis-1/3 ml-4 my-auto ${items[1].id != null ? '' : 'bg-base-200 rounded-lg'}`}>
                                <img src={items[1].id != null ? items[1].data.imgUrls[0] : ''} className='rounded-lg' onClick={() => {
                                        if(items[1].id != null){
                                            navigate(`/item/${items[1].id}`)
                                        }
                                    }}
                                ></img>
                            </div>
                            <div className={`basis-1/3 ml-4 my-auto ${items[2].id != null ? '' : 'bg-base-200 rounded-lg'}`}>
                                <div className={`${items[2].id != null ? 'w-full bg-base-200 rounded-lg' : 'hidden'}`}>

                                </div>
                                <img src={items[2].id != null ? items[2].data.imgUrls[0] : ''} className='rounded-lg' onClick={() => {
                                        if(items[2].id != null){
                                            navigate(`/item/${items[2].id}`)
                                        }
                                    }}
                                ></img>
                            </div>
                        </>
                        : <></>    
                    }
                </div>
                <div className='mr-2 flex flex-row mb-2 2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden'>
                    {items.length > 0 ?
                        <>
                            <div className='basis-1/2 ml-2'>
                                <img src={items[0].id != null ? items[0].data.imgUrls[0] : ''} className='rounded-lg' onClick={() => {navigate(`/item/${items[0].id}`)}}></img>
                            </div>
                            <div className={`basis-1/2 ml-4 ${items[1].id != null ? '' : 'bg-base-200 rounded-lg'}`}>
                                <img src={items[1].id != null ? items[1].data.imgUrls[0] : ''} className='rounded-lg' onClick={() => {
                                        if(items[1].id != null){
                                            navigate(`/item/${items[1].id}`)
                                        }
                                    }}
                                ></img>
                            </div>
                        </>
                        : <></>    
                    }
                </div>
            </div>
        </div>


    )
}

export default UserCard