import { useState, useEffect, useRef } from "react"
import { FaFacebook, FaTwitter, FaYoutube } from 'react-icons/fa'
import { RiEyeFill, RiEyeCloseFill, RiInstagramFill } from 'react-icons/ri'
import { getAuth, onAuthStateChanged, deleteUser, reauthenticateWithPopup, reauthenticateWithCredential, signInWithPopup, GoogleAuthProvider, signInWithRedirect, reauthenticateWithRedirect, signInWithEmailAndPassword } from 'firebase/auth'
import { updateDoc, doc, getDocs, where, collection, query, getDoc, deleteDoc, serverTimestamp } from 'firebase/firestore'
import { getStorage, ref, deleteObject } from 'firebase/storage'
import { db } from '../firebase.config'
import { Link, useNavigate, useNavigationType } from "react-router-dom"
import SignInCard from "../components/SignInCard"
import Spinner from "../components/Spinner"
import  _, { set }  from "lodash"
import { themeChange } from 'theme-change/index'
import { VscQuestion } from 'react-icons/vsc'

function Settings() {

    const auth = getAuth()
    const [loading, setLoading] = useState(true)
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
    const [loggedIn, setLoggedIn] = useState(null)
    const isMounted = useRef(true)
    const [docId, setDocId] = useState('')
    const [itemIds, setItemIds] = useState([])
    const [typeDelete, setTypeDelete] = useState('')
    const [deleteIsTyped, setDeleteIsTyped] = useState(false)
    const [privateDetails, setPrivateDetails] = useState(null)
    const [privateDetailsCopy, setPrivateDetailsCopy] = useState({})
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [settingsCopy, setSettingsCopy] = useState()
    const [settings, setSettings] = useState({
        twitter:{
            handle: '',
            permission: false,
        },
        facebook:{
            handle: '',
            permission: false,
        },
        instagram:{
            handle: '',
            permission: false,
        },
        youtube:{
            handle: '',
            permission: false,
        },
        experience:{
            handle: '',
            permission: false,
        },
        softwareUsed:{
            handle: '',
            permission: false,
        },
        profession:{
            handle: '',
            permission: false,
        },
        email:{
            handle: '',
            permission: false,
        },
    })
    const { twitter, instagram, facebook, youtube, experience, softwareUsed, profession, email } = settings

    const navigate = useNavigate()

    useEffect(() => {
        document.title = 'Layolay | Settings'
    }, [])

    useEffect(() => {
        if(isMounted){
            onAuthStateChanged(auth, (privateDetails) => {
                if(privateDetails) {
                    setLoggedIn(true)
                } else {
                    navigate('/sign-in')
                    setLoggedIn(false)
                }
            })
        }
        return () => {
            isMounted.current = false
        }
  
    }, [isMounted])

    useEffect(() => {
        setLoading(true)

        const fetchPrivate = async () => {
            // Get reference
            const privateRef = collection(db, 'private')
            
            // Create a query
            const q = query(
                privateRef,
                where('userId', '==', auth.currentUser.uid)
            )

            // Execute query
            const querySnap = await getDocs(q)
            
            let privateDetails = []
            querySnap.forEach((doc) => {
                return privateDetails.push({
                    id: doc.id,
                    data: doc.data()
                })
            })

            setDocId(privateDetails[0].id)
            setPrivateDetails(privateDetails[0].data)

            // //console.log(docSnap.data())
            // if(querySnap.exists()){
            //     setPrivateDetails(querySnap[0].data())
            // }
        }

        if(auth.currentUser != null){
            fetchPrivate()
        }

        setLoading(false)
 
    }, [loggedIn])

    useEffect(() => {
        themeChange(false)
    }, [])
    
    useEffect(() => {
        setPrivateDetailsCopy({
            ...privateDetails,
        })
    }, [privateDetails])

    useEffect(() => {
        if(_.isEqual(settings, settingsCopy)){
            setSaveButtonDisabled(true)
        }
    }, [settings])

    useEffect(() => {
        //console.log(privateDetails)
        if(privateDetailsCopy.settings != undefined){
            setSettings({
                ...privateDetailsCopy.settings,
            })
            setSettingsCopy({
                ...privateDetailsCopy.settings,
            })
        }
    }, [privateDetailsCopy])

    const determineSettingToChange = (e) => {
        switch(e.target.id) {
            case 'youtube':
              return youtube
            case 'twitter':
              return twitter
            case 'instagram':
                return instagram
            case 'facebook':
                return facebook
            case 'experience':
                return experience
            case 'softwareUsed':
                return softwareUsed
            case 'profession':
                return profession
            case 'email':
                return email
          }
    }

    const eyeDisplay = (showPassword ? <RiEyeFill size={25} /> : <RiEyeCloseFill size={25} />)

    const onPasswordChange = (e) => {
        setPassword(e.target.value)
    }

    const onDeleteInputChange = (e) => {        
        if(e.target.value == 'Delete'){
            setDeleteIsTyped(true)
        } else {
            setDeleteIsTyped(false)
        }
        setTypeDelete(e.target.value)
    }

    const onChange = (e) => {

        const settingToChange = determineSettingToChange(e)

        setSaveButtonDisabled(false)

        //console.log('settingToChange', settingToChange)

        if (e.target.type == 'checkbox'){
            setSettings((prevState) => ({
                ...prevState,
                [e.target.id]: {
                    handle: settingToChange.handle,
                    permission: e.target.checked,   
                }
            }))
        } else {
            let newValue = ''
            if (e.target.value.indexOf('twitter.com/') != -1){
                newValue = e.target.value.substring(12 + e.target.value.indexOf('twitter.com/'))
            } else if (e.target.value.indexOf('facebook.com/') != -1){
                newValue = e.target.value.substring(13 + e.target.value.indexOf('facebook.com/'))
            } else if (e.target.value.indexOf('instagram.com/') != -1){
                newValue = e.target.value.substring(14 + e.target.value.indexOf('instagram.com/'))
            } else if (e.target.value.indexOf('youtube.com/') != -1){
                newValue = e.target.value.substring(12 + e.target.value.indexOf('youtube.com/'))
            } else {
                newValue = e.target.value
            }

            setSettings((prevState) => ({
                ...prevState,
                [e.target.id]: {
                    handle: newValue,
                    permission: settingToChange.permission,
                }
            }))
        }
    }

    // const onChangeEmail = (e) => {
    //         setSaveButtonDisabled(false)
    //         setSettings((prevState) => ({
    //             ...prevState,
    //             email: e.target.checked
    //         }))
    // }

    const onSubmit = async (e) => {
        e.preventDefault()
        setPrivateDetails((prevState) => ({
            ...prevState,
            settings: settings
        }))
        setSettingsCopy(settings)
        setSaveButtonDisabled(true)
        try{
            const privateRef = doc(db, 'private', docId)
            await updateDoc(privateRef, {
                settings: settings
            })

            // Show only the public settings in the user object
            const publicSettings = {}
            for (const [key, value] of Object.entries(settings)) {
                publicSettings[key] = {}
                if (value.permission == true){
                    publicSettings[key].handle = value.handle
                    publicSettings[key].permission = true
                } else {
                    publicSettings[key].handle = ''
                    publicSettings[key].permission = false
                }                  
            }

            const userRef = doc(db, 'users', auth.currentUser.uid)
            await updateDoc(userRef, {
                settings: publicSettings
            })

            //console.log(publicSettings)

        } catch (error) {
            console.log(error)

        }
    }

    const deleteUserAccount = async () => {
        //console.log('inside delete user account!')
        //TODO: 
        // 1 Get user's item list
        // 2 Query iterate over each item in the array, deleting all images and models 
        // 3 Delete the item itself
        // 4 Repeat steps 2 & 3 until there are no items left
        // 5 Delete User >:^)
        
        // 1 Get User Item List
        //const userRef = doc(db, 'users', auth.currentUser.uid)
        //const userData = await getDoc(userRef)
        const itemRef = collection(db, 'items')
        const q = query(
            itemRef,
            where('userId', '==', auth.currentUser.uid),
        )
        const querySnap = await getDocs(q)
        let items = []
        querySnap.forEach((doc) => {
            return items.push({
                id: doc.id,
                itemData: doc.data()
            })
        })
        //console.log('Item reference list', itemIds)
        //2 Iterate and delete all images and models
        //console.log(items)
        items.map((item) => {
            //console.log('Getting item reference', item)
            const storage = getStorage()
            const itemRef = doc(db, 'items', item.id)
            getDoc(itemRef).then((item) => {
                //console.log(item.data())
                item.data().imageReferenceArray.forEach((imageRef) => {
                    let deleteRefArray = ref(storage, 'images/' + imageRef)
                    deleteObject(deleteRefArray)
                })
                item.data().objectReferenceArray.forEach((objectRef) => {
                    let deleteRefArray = ref(storage, 'models/' + objectRef)
                    deleteObject(deleteRefArray)
                })
                //3 Delete Item
                deleteDoc(doc(db, "items", item.id))
            })
        })
        deleteDoc(doc(db, "users", auth.currentUser.uid))

        //Step 5
        //const provider = new GoogleAuthProvider()
        const credential = await signInWithEmailAndPassword(auth, auth.currentUser.email, password)
        //console.log(credential)
        await reauthenticateWithCredential(credential.user, credential).then(deleteUser(auth.currentUser)).then(() => {
            console.log('User Deleted')
          }).catch((error) => {
            console.log('Error when deleting user', error)
          }).then(navigate('/'))
        
    }


    const twitterHandle = [
        <input 
            placeholder="twitter handle" 
            id='twitter'
            value={twitter.handle} 
            onChange={onChange} 
            className="input w-full input-primary input-bordered input-sm"
        />
    ]

    const twitterPermission = [
        <input 
            type='checkbox'
            id="twitter" 
            value={twitter.permission} 
            onChange={onChange}
            className="checkbox ml-4"
            checked={twitter.permission}
        />
    ]

    const youtubeHandle = [
        <input 
            placeholder="youtube handle" 
            extra="meme"
            id="youtube" 
            value={youtube.handle} 
            onChange={onChange} 
            className="input w-full input-primary input-bordered input-sm" 
        />
    ]
    
    const youtubePermission = [
        <input 
            type='checkbox'
            id="youtube"
            value={youtube.permission} 
            onChange={onChange}
            className="checkbox ml-4" 
            checked={youtube.permission}
        />
    ]

    const instagramHandle = [
        <input 
            placeholder="instagram handle" 
            id="instagram" 
            value={instagram.handle} 
            onChange={onChange} 
            className="input w-full input-primary input-bordered input-sm" 
        />
    ]

    const instagramPermission = [
        <input 
        type='checkbox'
        id="instagram" 
        value={instagram.permission} 
        onChange={onChange}
        className="checkbox ml-4" 
        checked={instagram.permission}
        />
    ]

    const facebookHandle = [
        <input 
            placeholder="facebook handle" 
            id="facebook" 
            value={facebook.handle} 
            onChange={onChange} 
            className="input w-full input-primary input-bordered input-sm" 
        />
    ]

    const facebookPermission = [
        <input 
        type='checkbox'
        id="facebook" 
        value={facebook.permission} 
        onChange={onChange}
        className="checkbox ml-4" 
        checked={facebook.permission}
        />
    ]

    const experienceHandle = [
        <input 
            placeholder="List relavant experience" 
            id="experience" 
            value={experience.handle} 
            onChange={onChange} 
            className="input w-full input-primary input-bordered input-sm" 
        />
    ]

    const experiencePermission = [
        <input 
        type='checkbox'
        id="experience" 
        value={experience.permission} 
        onChange={onChange}
        className="checkbox ml-4" 
        checked={experience.permission}
        />
    ]

    const softwareUsedPermission = [
        <input 
        type='checkbox'
        id="softwareUsed" 
        value={softwareUsed.permission} 
        onChange={onChange}
        className="checkbox ml-4" 
        checked={softwareUsed.permission}
        />
    ]

    const softwareUsedHandle = [
        <input 
            placeholder="Types of 3D software you use" 
            id="softwareUsed" 
            value={softwareUsed.handle} 
            onChange={onChange} 
            className="input w-full input-primary input-bordered input-sm" 
        />
    ]

    const professionPermission = [
        <input 
        type='checkbox'
        id="profession" 
        value={profession.permission} 
        onChange={onChange}
        className="checkbox ml-4" 
        checked={profession.permission}
        />
    ]

    const professionHandle = [
        <input 
            placeholder="Artist, Engineer, Architect, etc...." 
            id="profession" 
            value={profession.handle} 
            onChange={onChange} 
            className="input w-full input-primary input-bordered input-sm" 
        />
    ]

    const emailPermission = [
        <input 
        type='checkbox'
        id="email"
        value={email.permission}
        onChange={onChange}
        className="checkbox ml-4" 
        checked={email.permission}
        />
    ]

    if (loading) {
        return(
            <div className='mx-auto my-auto'>
                <Spinner />
            </div>
        )
    }

    //console.log(deleteIsTyped)

    return (
        <div className='flex flex-col m-4 2xl:w-[40%] xl:w-[50%] lg:w-[60%] md:w-[70%]
        2xl:mx-auto xl:mx-auto lg:mx-auto md:mx-auto 2xl:my-8 xl:my-8 lg:my-8 md:my-8'>
            <div className="card bg-base-200 p-8">
                <h6 className='pb-6 font-bold text-2xl'>Settings</h6>
                <div className='flex pb-6'>
                    <h2 className='pb-2 font-bold text-lg self-center'>Color Theme</h2>
                    <div className="dropdown dropdown-hover dropdown-end bg-base-200 mx-2">
                        <label tabIndex="0" className="mb-2 btn btn-primary btn-sm">Select theme!</label>
                        <ul tabIndex="0" className="p-2 shadow menu dropdown-content bg-base-100 rounded-box">
                            <li className='m-1 btn btn-primary btn-sm' onClick={themeChange(false)} data-set-theme="">
                                Default
                            </li>
                            <li className='m-1 btn btn-primary btn-sm' onClick={themeChange(false)} data-set-theme="synthwave">
                                Synthwave
                            </li>
                            {/* <li className='m-1 btn btn-primary btn-sm' onClick={themeChange(false)} data-set-theme="luxury">
                                Luxury
                            </li> */}
                            <li className='m-1 btn btn-primary btn-sm' onClick={themeChange(false)} data-set-theme="dracula">
                                Dracula
                            </li>
                            <li className='m-1 btn btn-primary btn-sm' onClick={themeChange(false)} data-set-theme="halloween">
                                Halloween
                            </li>
                            <li className='m-1 btn btn-primary btn-sm' onClick={themeChange(false)} data-set-theme="valentine">
                                Valentine
                            </li>
                        </ul>
                    </div>
                </div>

                <h2 className='pb-2 font-bold text-lg'>Socials</h2>
                <span>Enabling these socials will display links to your socials on your profile page</span>
                <form onSubmit={onSubmit} className='mt-4'>
                    <div className='flex flex-col'>
                        <div className="pb-4 shrink flex justify-between">
                            <div className='w-full 2xl:basis-3/4 xl:basis-3/4 lg:basis-3/4 ml-4'>
                                <div className="input-group">
                                    <span>
                                        <FaTwitter className='2xl:mr-2 xl:mr-2 lg:mr-2 md:mr-2 sm:mr-2' size={18}/>
                                        <span className='hidden 2xl:block xl:block lg:block md:block sm:block'>www.twitter.com/</span>
                                    </span>
                                    {twitterHandle}
                                </div>
                            </div>
                            <div className="flex ml-auto my-auto">
                                {twitterPermission}
                            </div>
                        </div>

                        <div className="pb-4 flex justify-between">
                            <div className='w-full 2xl:basis-3/4 xl:basis-3/4 lg:basis-3/4 ml-4'>
                                
                                <div className="input-group">
                                    <span>
                                        <RiInstagramFill className='2xl:mr-2 xl:mr-2 lg:mr-2 md:mr-2 sm:mr-2' size={18}/>
                                        <span className='hidden 2xl:block xl:block lg:block md:block sm:block'>www.instagram.com/</span>
                                    </span>
                                    {instagramHandle}
                                </div>
                            </div>
                            <div className="flex ml-auto my-auto">
                                {instagramPermission}
                            </div>
                        </div>

                        <div className="pb-4 flex justify-between">
                            <div className='w-full 2xl:basis-3/4 xl:basis-3/4 lg:basis-3/4 ml-4'>
                                
                                <div className="input-group">
                                    <span>
                                        <FaFacebook className='2xl:mr-2 xl:mr-2 lg:mr-2 md:mr-2 sm:mr-2' size={18}/>
                                        <span className='hidden 2xl:block xl:block lg:block md:block sm:block'>www.facebook.com/</span>
                                    </span>
                                    {facebookHandle}
                                </div>
                            </div>
                            <div className="flex ml-auto my-auto">
                                {facebookPermission}
                            </div>
                        </div>

                        <div className="pb-4 flex justify-between">
                            <div className='w-full 2xl:basis-3/4 xl:basis-3/4 lg:basis-3/4 ml-4'>
                                
                                <div className="input-group">
                                    <span>
                                        <FaYoutube className='2xl:mr-2 xl:mr-2 lg:mr-2 md:mr-2 sm:mr-2' size={18}/>
                                        <span className='hidden 2xl:block xl:block lg:block md:block sm:block'>www.youtube.com/</span>
                                    </span>
                                    {youtubeHandle}
                                </div>
                            </div>
                            <div className="flex ml-auto my-auto">
                                {youtubePermission}
                            </div>
                        </div>

                        <h2 className='pb-2 font-bold text-lg'>Email</h2>
                        <div className="flex">
                            <div className='my-auto'>
                                Allow contact through {auth.currentUser != null ? <span className='font-bold'>{auth.currentUser.email}</span> : ''}? 
                            </div>
                            <div className='flex ml-4 my-auto'>
                                <div className='p-1 tooltip cursor-pointer my-auto' data-tip={`Enabling this will allow customers to contact you directly about requests through your profile`}>
                                    <VscQuestion size={25} />
                                </div>
                            </div>
                            <div className="ml-auto">
                                {emailPermission}
                            </div>
                        </div>
                    </div>

                    <h2 className='pb-2 pt-3 font-bold text-lg'>Facts</h2>
                    <span>Enabling these facts will display them profile page</span>
                    <div className='flex flex-col mt-4'>
                        <div className="pb-4 shrink flex justify-between">
                            <div className='w-full 2xl:basis-3/4 xl:basis-3/4 lg:basis-3/4 my-auto ml-4'>
                                
                                <div className="input-group">
                                    <span>Experience</span>
                                    {experienceHandle}
                                </div>
                            </div>
                            <div className="flex ml-auto my-auto">
                                {experiencePermission}
                            </div>
                        </div>

                        <div className="pb-4 flex justify-between">
                            <div className='w-full 2xl:basis-3/4 xl:basis-3/4 lg:basis-3/4 ml-4'>
                                
                                <div className="input-group">
                                    <span>Software</span>
                                    {softwareUsedHandle}
                                </div>
                            </div>
                            <div className="flex ml-auto my-auto">
                                {softwareUsedPermission}
                            </div>
                        </div>

                        <div className="pb-4 flex justify-between">
                            <div className='w-full 2xl:basis-3/4 xl:basis-3/4 lg:basis-3/4 ml-4'>
                                
                                <div className="input-group">
                                    <span>Profession</span>
                                    {professionHandle}
                                </div>
                            </div>
                            <div className="flex ml-auto my-auto">
                                {professionPermission}
                            </div>
                        </div>
                    </div>

                    <div className="card-actions justify-end">
                        <button type="submit" className='btn btn-primary' disabled={saveButtonDisabled}>Save</button>
                        <button type="button" className='btn btn-neutral' disabled={saveButtonDisabled} onClick={() => {
                            setSettings(privateDetails.settings)
                            setSaveButtonDisabled(true)
                        }}>Reset</button>
                    </div>
                </form>
                <label htmlFor="my-modal" className="btn btn-error mx-auto mt-4 modal-button text-white self-end">
                    Delete Account
                </label>
                <input type="checkbox" id="my-modal" className="modal-toggle"/>
                <div className="modal p-4">
                    <div className="modal-box">
                        <div className="font-bold text-lg text-white">Are you sure you want to delete your account and all your items forever?</div>
                        <div className="font-normal text-lg text-white mt-2">Please type your current account password</div>
                        <div className="flex justify-items-start space-x-2 mt-2">
                            <input
                                type={showPassword ? 'text' : 'password'} 
                                id="password" 
                                value={password} 
                                onChange={onPasswordChange}
                                className="input input-primary input-bordered"
                            />
                            <button type="button" onClick={() => setShowPassword((prevState => !prevState))} className="btn btn-primary">{eyeDisplay}</button>
                        </div>
                        <label className="label">
                            <Link to={'/forgot-password'} className="label-text-alt">Forgot password?</Link>
                        </label>
                    
                        <div className="font-normal text-lg text-white mt-3">Please type "Delete" in the box below before deleting your account</div>
                        <input 
                            type='text'
                            id="typeDelete" 
                            value={typeDelete} 
                            onChange={onDeleteInputChange}
                            className="input w-1/3 input-primary input-bordered mt-2 mx-auto"
                        />
                        
                        <div className="modal-action">
                            <label htmlFor="my-modal" className="btn btn-error text-white" disabled={!deleteIsTyped} onClick={() => deleteUserAccount()}>Yes, delete my account!</label>
                            <label htmlFor="my-modal" className="btn btn-info text-white">No, keep my account!</label>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Settings
