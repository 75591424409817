import { Suspense, useRef, useState } from 'react'
import Layolay from '../media/layolay.svg'
import { Canvas, useFrame, useLoader, useThree } from "@react-three/fiber"
import {  PerspectiveCamera, useProgress, Html, Center, OrbitControls, useAspect } from '@react-three/drei'
import * as THREE from "three"
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'
import Spinner from '../components/Spinner'
import ModelScene from '../components/ModelScene'

function Hero() {

    const dodecaherdon = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/models%2F6ASJdqHwsvd3l5pwzlMsFKiD1BG2-510bd21c-19a2-4f23-b37f-590d154587c7-Dodecahedron.stl?alt=media&token=b5acc592-268d-4c66-9c92-cbd8e07b2bd9'
    const icosohedron = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/models%2F6ASJdqHwsvd3l5pwzlMsFKiD1BG2-Icosahedron.stl-f4cfc7d1-da8a-4df3-bb34-48c70e8f700d?alt=media&token=93d121c1-c9a6-45d8-8637-f9a5d49c56b6'
    const frog = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/models%2F3YPTYjwmJZYR2tnSxIn7x8kaCC83-frog.stl-a4e765c1-c8a1-42f5-87a8-33329577b83b?alt=media&token=6c35e355-83b6-4e62-b2f1-d5a534ed3769'
    const bear = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/models%2F3YPTYjwmJZYR2tnSxIn7x8kaCC83-bear.stl-b8ec91c3-3ef0-4643-bcb3-b36578aad0a3?alt=media&token=a20370b0-20f8-4f76-8029-4823cd39f7f9'

    return (
        <div className="flex bg-gradient-to-b from-primary">
            <div className="flex-col mx-auto lg:flex-row-reverse my-16">
                <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row">
                    <div className='flex flex-col 2xl:flex-row xl:flex-row lg:flex-row md:flex-row'>
                        <div className="hidden flex-col 2xl:flex xl:flex lg:hidden md:hidden sm:hidden">
                            <div>
                                <ModelScene fileName={bear} color='#dad5d1' />
                            </div>
                            <div className='mr-36'>
                                <ModelScene fileName={icosohedron} color='#dad5d1' />
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <img src = {Layolay} className='px-4'></img>
                            <p className="font-light text-2xl text-center mx-auto mb-5 px-4">
                                High-quality custom 3D prints delivered straight to you
                            </p>
                        </div>
                        <div className="hidden flex-col 2xl:flex xl:flex lg:hidden md:hidden sm:hidden">
                            <div className='mx-auto hidden 2xl:flex xl:flex lg:flex md:flex sm:hidden'>
                                <ModelScene fileName={dodecaherdon} color='#dad5d1' />
                            </div>
                            <div className='ml-0 hidden 2xl:flex xl:flex lg:flex md:flex sm:hidden 2xl:ml-36 xl:ml-36 lg:ml-36 md:ml-36'>
                                <ModelScene fileName={frog} color='#dad5d1' />
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Hero
