import { useEffect, useState } from "react"
import { db } from '../firebase.config'
import { updateDoc, doc, getDoc, onSnapshot, createDoc, collection, getDocs, query, orderBy, where, limit } from 'firebase/firestore'
function Message({ recieverID, setConvoName, lastMessage, selectConvo, id, lastMessageSender, currentUser, read, currentConvo }) {

    //console.log(recieverID)
    const [reciever, setReciever] = useState(null)

    useEffect(() =>{
        const fetchReciever = async () => {
            const docRef = doc(db, 'users', recieverID[0])
            const docSnap = await getDoc(docRef)
            //console.log(docSnap.data())
            if(docSnap.exists()){
                setReciever(docSnap.data())
            }
        }

        try {
            fetchReciever()
        } catch (error) {
            console.log(error)
            //console.log(recieverID)
        }

    }, [])

    const setConvoNameLocal = () => {
        setConvoName(reciever.name)
        selectConvo(id, lastMessageSender)
    }

    console.log((lastMessageSender != currentUser) && (read == false))
    return (
        <>
            {reciever != null ?
            <>
                <div onClick={setConvoNameLocal} 
                    className={`flex flex-col mt-2 p-2 bg-base-300 rounded-lg hover:cursor-pointer ${(currentConvo != null) && currentConvo == id ? 'border-2' : ''}`}
                >
                    <div className='flex flex-row'>
                        <img src={reciever.profilePic} className='object-cover h-12 w-12 rounded-xl p-1' />
                        <div className='self-center'>
                            {reciever.name}
                            <div className={`${(lastMessageSender != currentUser) && (read == false) ? '' : 'hidden'} ml-4 badge badge-primary`}>New</div>
                        </div>
                    </div>
                    <div className='truncate w-64 opacity-70 ml-2'>
                        {lastMessage}
                    </div>
                </div>
            </>
            :
            <>
            </>}
        </>
    )
}

export default Message