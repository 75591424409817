import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { doc, getDoc, getDocs, collection, where, query, documentId } from 'firebase/firestore'
import { db } from '../firebase.config'

function OrderCard({id, data}) {

    const [items, setItems] = useState([])

    // useEffect(() => {
    //     let fetchedItems = []
    //     for (let i = 0; i < data.items.length; i++){
    //         const fetchItem = async (itemId) => {
    //             const docRef = doc(db, 'items', itemId)
    //             const docSnap = await getDoc(docRef)
    //             fetchedItems.push(docSnap.data())
    //         }
    //         fetchItem(data.items[i].itemId)
    //         if (fetchedItems.length == data.items.length){
    //             setItems(fetchedItems)
    //         }
    //     }

    // }, [id, data])

    useEffect(() => {
        const fetchItems = async () => {
            // Get reference
            const itemRef = collection(db, 'items')
            
            let itemIds = []
            data.items.forEach((item) => (
                itemIds.push(item.itemId)
            ))

            console.log(itemIds)

            // Create a query
            const q = query(
                itemRef,
                where(documentId(), 'in', itemIds)
            )

            // Execute query
            const querySnap = await getDocs(q)

            let items = []
            querySnap.forEach((doc) => {
                return items.push({
                    id: doc.id,
                    data: doc.data()
                })
            })

            setItems(items)
        }

        fetchItems()

    }, [id, data])

    return (
        <div className='bg-base-300 rounded-lg mt-4 p-4'>
            <div className={`font-bold text-lg 
            ${data.status == 'Order Received' ? 'text-white' : ''}
            ${data.status == 'Printing' ? 'text-warning' : ''}
            ${data.status == 'Shipping' ? 'text-info' : ''}
            ${data.status == 'Delivered' ? 'text-success' : ''}`}>
                {data.status}
            </div>
            <div className='flex flex-col mt-2 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-row'>
            <div className='font-bold text-lg mt-2 mx-auto 2xl:mx-4 xl:mx-4 lg:mx-4 md:mx-4 sm:mx-4
                2xl:my-auto xl:my-auto lg:my-auto md:my-auto sm:my-auto'>
                    ${data.cost}
                </div>
                <div className='flex flex-row'>
                    <div className='font-bold text-normal my-auto'>
                        Order Placed: 
                    </div>
                    <div className='font-light text-sm my-auto ml-4'>
                        {new Date(data.timestamp.seconds * 1000).toLocaleDateString("en-US")}
                    </div>
                    <div className='font-light text-sm my-auto ml-4'>
                        {new Date(data.timestamp.seconds * 1000).toLocaleTimeString("en-US")}
                    </div>
                </div>
            </div>
            <div className='flex flex-col 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-row
            mx-auto 2xl:ml-4 xl:ml-4 lg:ml-4 md:ml-4 sm:ml-4'>
                {[...Array(items.length).keys()].map((n) => (
                    
                    <div className='flex mt-4 flex-col 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-row'>
                        <div className='basis-1/4 flex'>
                            <Link to={`/item/${data.items[n].itemId}`} className='mx-auto'>
                                <img className='w-full rounded-md object-cover mx-auto' src = {items[n].data.imgUrls[0]}/>
                            </Link>
                        </div>
                        <div className='flex flex-col basis-1/2 mx-auto 2xl:ml-4 xl:ml-4 lg:ml-4 md:ml-4 sm:ml-4'>
                            <div className='font-bold text-md mt-2 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 sm:mt-0'>
                                {items[n].data.name}
                            </div>
                            <div className='font-bold text-md mt-2 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 sm:mt-0'>
                                Description
                            </div>
                            <div className='font-light text-sm'>
                                {items[n].data.description}
                            </div>
                        </div>
                        <div className='flex flex-col basis-1/4 ml-4 mt-4 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 sm:mt-0'>
                            <div className='font-bold text-md'>
                                Color: 
                                <span style = {{color: data.items[n].options.color.hex.substring(4, 11)}} className={`ml-4 font-light`}>
                                    {data.items[n].options.color.name}
                                </span>
                            </div>
                            <div className='font-bold text-md'>
                                Size: <span className='ml-4 font-light'>{data.items[n].options.size}</span>
                            </div>
                            <div className='font-bold text-md'>
                                Quantity: <span className='ml-4 font-light'>{data.items[n].quantity}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default OrderCard