import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTrail, animated, to } from 'react-spring'

function DynamicItemCard({ imgUrls, name, prices, user, id }) {

    const config = { mass: 5, tension: 2000, friction: 200 }
    const navigate = useNavigate()
    const price = prices.split(',').pop().slice(8, -1)
    const [resetBoolean, setResetBoolean] = useState(false)
    const trail = useTrail(1, {
        config,
        opacity: 1,
        x:  0,
        height: 80,
        from: { opacity: 0, x: 20, height: 0 },
        reset: resetBoolean,
        onRest: () => {setResetBoolean(true)}
    })

  return (
    <>
        {/* {trail.map(({ x, height, ...rest }, index) => (
                <animated.div
                    key={id}
                    style={{
                    ...rest,
                    transform: x.to(x => `translate3d(0,${x}px,0)`)
                    }}
                > */}
                    <div className='rounded-md p-4 hover:shadow-[0_3px_4px_2px_rgba(0,0,0,0.5)] hover:shadow-base-400 bg-base-200' style={{boxShadow: 'inset'}}>
                        <div className="transition ease-in-out hover:cursor-pointer duration-150">
                            <div className="mx-auto">
                                <div className='relative overflow-hidden pb-24 2xl:pb-60 xl:pb-60 lg:pb-60 md:pb-60 sm:pb-60'>
                                    <img className='absolute h-24 w-full rounded-md object-cover 2xl:h-full xl:h-full lg:h-full md:h-full sm:h-full' 
                                    src={imgUrls[0]} alt={name} onClick={() => {navigate(`/item/${id}`)}} />
                                </div>
                                <div className='my-auto font-light text-lg mt-2'>
                                    {name}
                                </div>
                                <div className='my-auto'>${Number(price).toFixed(2).replace(/[.,]0$/, '')}</div>
                                {/* <div className="pl-2">
                                    <p className='font-light text-sm my-auto' onClick={() => navigate()}>Created by {user}</p>
                                </div> */}
                            </div> 
                        </div> 
                    </div>
                    {/* <DynamicItemCard key={results[index].id.raw} name={results[index].name.raw} imgUrls={results[index].imgurls.raw} prices={results[index].prices.raw} id={results[index].id.raw}/> */}
                {/* </animated.div>
            ))} */}
    </>

  )
}

export default DynamicItemCard