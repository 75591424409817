import { useState, useEffect, Suspense, useRef } from "react"
import Anime, { anime } from 'react-anime'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { db } from '../firebase.config'
import { addDoc, collection, serverTimestamp, updateDoc, doc, getDoc } from "firebase/firestore"
import { useNavigate, Link } from "react-router-dom"
import { v4 as uuidv4} from 'uuid'
import { FaCheck } from 'react-icons/fa'
import { Canvas, useFrame, useLoader, useThree } from "@react-three/fiber"
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'
import * as THREE from "three";
import {  PerspectiveCamera,  useProgress, Html, Center  } from '@react-three/drei'
import Resizer from "react-image-file-resizer"

function ItemForm() {

    THREE.Object3D.DefaultUp.set(0, 0, 1)

    const auth = getAuth()
    
    const [thumbnailUrl, setThumbnailUrl] = useState([])
    const [itemImageReferences, setItemImageReferences] = useState([])
    const [itemObjectReferences, setItemObjectReferences] = useState([])
    const [resizedImageArray, setResizedImageArray] = useState(0)
    const [progressBar, setProgressBar] = useState(0)
    const [disableButton, setDisableButton] = useState(false)
    const [objectUrl, setObjectURL] = useState([])
    const [numItems, setNumItems] = useState(0)
    const [itemIds, setItemIds] = useState([])
    const [loading, setLoading] = useState(false)
    const [fileData, setFileData] = useState(null)
    const [itemData, setItemData] = useState({
        name: '',
        description: '',
        attribution: '',
        discount: 0,
        images: '',
        object: '',
        objectType: '',
        prices: {
            'Small': 10,
            'Medium': 20,
            'Large': 30
        },
        hidden: false,
        modHidden: false,
        userId: '',
        user: '',
        totalRating: 0,
        numRatings: 0,
        numSales: 0,
    })

    const {name, description, images, price, object} = itemData

    const [message, setMessage] = useState('')
    const [scaleBy, setScaleBy] = useState(0.05)
    const [scaleByFlag, setScaleByFlag] = useState(false)
    const [sliceStatus, setSliceStatus] = useState(0)
    const [prepareStatus, setPrepareStatus] = useState(0)
    const [sliceStage, setSliceStage] = useState('')
    const [sliceFlag, setSliceFlag] = useState(false)
    const [sliceResult, setSliceResult] = useState('')
    const [estimatedPrice, setEstimatedPrice] = useState(0)
    //{Number(sliceResult[0] / 60 / 60 * 5).toFixed(2).replace(/[.,]0$/, '')}
    const [gcode, setGcode] = useState('')
    
    const display_message = (msg) => {
        setMessage(msg)
    }
    const display_gcode = (gcode) => {
        setGcode(gcode)
    }
    const runCode = (gcode) => {
        display_message()
    }
    //console.log(message)
    //console.log(gcode)

    useEffect(() => {
        document.title = 'Layolay | Upload Item'
    }, [])

    useEffect(() => {

        const setSliceMode = (e) => {
            setPrepareStatus(0)
            setSliceFlag(true)
            if(e.slice.update <= 1){
                setSliceStatus(e.slice.update)
            } else {
                setSliceStatus(1)
            }
            setSliceStage({title :'Slice Stage', stage: e.slice.updateStatus})
        }
        const setPrepareMode = (e) => {
            if(e.prepare.update <= 1){
                setPrepareStatus(e.prepare.update)
            } else {
                setPrepareStatus(1)
            }
            setSliceStage({title :'Prepare Stage', stage: e.prepare.updateStatus})
        }
        const setExportMode = (e) => {
            //setSliceStatus(0)
            setSliceStage({title :'Export Stage', stage: ''})
        }
        const setGCODEData = (e) => {
            const time = e.slice(-20).match(/(\d+)/)
            setSliceResult(time)
            setSliceStage({title :'Export Stage', stage: ''})
        }

        const fetchModel = () => {
                kiri.newEngine()
                .setController({
                    threaded: true
                })
                .setListener(e => 
                    (e.slice != null ? 
                        setSliceMode(e) 
                        :
                        (e.prepare != null ? 
                            setPrepareMode(e) 
                            :
                            setExportMode(e) 
                        )
                    )
                )
                .load(objectUrl[0].url)
                .then(e => e.scale(scaleBy, scaleBy, scaleBy))
                .then(eng => eng.setProcess({
                    sliceShells: 1,
                    sliceHeight: 0.2,
                    sliceFillSparse: 0.2,
                    sliceTopLayers: 2,
                    sliceSolidLayers: 2,
                    sliceBottomLayers: 2,
                    sliceFillType: 'linear',
                    sliceFillFraction: 0.1,
                }))
                .then(eng => eng.setDevice({
                    mode: 'FDM',
                    controller: 'threaded',
                    gcodePre: [
                        'M862.3 P "[printer_model]"', 
                        'M862.1 P[nozzle_diameter]',
                        'M115 U3.10.0',
                        'G90',
                        'M83',
                        'M104 S[first_layer_temperature]',
                        'M140 S[first_layer_bed_temperature]',
                        'M190 S[first_layer_bed_temperature]',
                        'M109 S[first_layer_temperature]',
                        'G28 W',
                        'G80',
                        'G1 Y-3 F1000',
                        'G1 Z0.4 F1000',
                        'G1 X55 E8 F2000',
                        'G1 Z0.3 F1000',
                        'G92 E0',
                        'G1 X240 E25 F2200',
                        'G1 Y-2 F1000',
                        'G1 X55 E25 F1400',
                        'G1 Z0.2 F1000',
                        'G1 X5 E4 F1000',
                        'G92 E0',
                    ],
                    gcodePost: [
                        'G1 X0 Y210 F7200',
                        'G1 E2 F5000',
                        'G1 E2 F5500',
                        'G1 E2 F6000',
                        'G1 E-15 F5800',
                        'G1 E-20 F5500',
                        'G1 E10 F3000',
                        'G1 E-10 F3100',
                        'G1 E10 F3150',
                        'G1 E-10 F3250',
                        'G1 E10 F3300',
                        'M140 S0',
                        'M107',
                        'M702 C',
                        'G4',
                        'M221 S100',
                        'M900 K0',
                    ],
                }))
                .then(eng => eng.slice())
                .then(eng => eng.prepare())
                .then(eng => eng.export())
                .then(e => setGCODEData(e))
        }

        if(scaleByFlag == true){
            fetchModel()
        }
    }, [scaleBy])

    const navigate = useNavigate()

    const isMounted = useRef(true)
    useEffect(() => {

        const fetchUser = async (userId) => {
            const docRef = doc(db, 'users', userId)
            const docSnap = await getDoc(docRef)

            //console.log(docSnap.data())
            if(docSnap.exists()){
                setNumItems(docSnap.data().numItems)
                setItemIds(docSnap.data().itemIds)
                setItemData({...itemData, user: docSnap.data().name, userId: userId})
            }
            setLoading(false)
        }

        if(isMounted){
            onAuthStateChanged(auth, (authUser) => {
                //console.log(user)
                if(authUser) {
                    fetchUser(authUser.uid)
                } else {
                    navigate('/sign-in')
                }
            })
        }

        return () => {
            isMounted.current = false
        }

    }, [isMounted])

    const onTextChange = (e) => {
        //Text
        if(!e.target.files){
            setItemData((prevState) => ({
                ...prevState,
                [e.target.id]: e.target.value
            }))
        }
    }

    const resizeFile = (image, imageType) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                image,
                1000,
                1000,
                imageType,
                80,
                0,
                (uri) => {
                    resolve(uri)
                },
                "file"
            )
        })
    }

    const onItemImageChange = async (e) => {
        if(e.target.files){
            getThumbnailImage(e)
            //console.log(e.target.files[0].type.toString().substring(6).toUpperCase())
            // Resizing images before putting them into itemData
            let resizedImages = []
            Array.from(e.target.files).forEach(async (file) => {
                try {
                    const image = resizeFile(file, file.type.toString().substring(6).toUpperCase()).then(resizedImage => resizedImages.push(resizedImage))
                    setResizedImageArray(resizedImages)
                } catch (error) {
                    //console.log('Image Resize unsuccessful', error)
                }
            })
        }
    }

    const onObjectChange = (e) => {
        // console.log()
        if(e.target.files){
            getObjectPreview(e)
            setFileData(e.target.files[0])
            setItemData((prevState) => ({
                ...prevState,
                object: e.target.files,
                objectType: (e.target.files[0].name).split('.').pop()
            }))
        }
    }

    const getThumbnailImage = (e) => {
        const files = e.target.files
        const file = e.target.files[0]
        if(!file){
            return
        }
        let images = []
        Array.from(files).forEach((file) => {
            return images.push({
                url: URL.createObjectURL(file)
            })
        })

        setThumbnailUrl(images)
        //console.log(thumbnailUrl)
    }

    // console.log(fileData)
    
    const getObjectPreview = (e) => {
        const files = e.target.files
        const file = e.target.files[0]
        //console.log(file)
        if(!file){
            return
        }
        
        let models = []
        Array.from(files).forEach((file) => {
            return models.push({
                url: URL.createObjectURL(file)
            })
        })

        setObjectURL(models)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setDisableButton(true)
        
        const objectReferenceArray = []
        const imageReferenceArray = []

        const fetchModel = () => {
            kiri.newEngine()
            .setListener(e => 
                (e.slice != null ? 
                    setSliceMode(e) 
                    :
                    (e.prepare != null ? 
                        setPrepareMode(e) 
                        :
                        setExportMode(e) 
                    )
                )
            )
            .load(objectUrl[0].url)
            .then(e => e.scale(scaleBy, scaleBy, scaleBy))
            .then(eng => eng.setProcess({
                sliceShells: 1,
                sliceHeight: 0.2,
                sliceFillSparse: 0.2,
                sliceTopLayers: 2,
                sliceSolidLayers: 2,
                sliceBottomLayers: 2,
                sliceFillType: 'linear',
                sliceFillFraction: 0.1,
            }))
            .then(eng => eng.setDevice({
                mode: 'FDM',
                gcodePre: [
                    'M862.3 P "[printer_model]"', 
                    'M862.1 P[nozzle_diameter]',
                    'M115 U3.10.0',
                    'G90',
                    'M83',
                    'M104 S[first_layer_temperature]',
                    'M140 S[first_layer_bed_temperature]',
                    'M190 S[first_layer_bed_temperature]',
                    'M109 S[first_layer_temperature]',
                    'G28 W',
                    'G80',
                    'G1 Y-3 F1000',
                    'G1 Z0.4 F1000',
                    'G1 X55 E8 F2000',
                    'G1 Z0.3 F1000',
                    'G92 E0',
                    'G1 X240 E25 F2200',
                    'G1 Y-2 F1000',
                    'G1 X55 E25 F1400',
                    'G1 Z0.2 F1000',
                    'G1 X5 E4 F1000',
                    'G92 E0',
                ],
                gcodePost: [
                    'G1 X0 Y210 F7200',
                    'G1 E2 F5000',
                    'G1 E2 F5500',
                    'G1 E2 F6000',
                    'G1 E-15 F5800',
                    'G1 E-20 F5500',
                    'G1 E10 F3000',
                    'G1 E-10 F3100',
                    'G1 E10 F3150',
                    'G1 E-10 F3250',
                    'G1 E10 F3300',
                    'M140 S0',
                    'M107',
                    'M702 C',
                    'G4',
                    'M221 S100',
                    'M900 K0',
                ],
            }))
            .then(eng => eng.slice())
            .then(eng => eng.prepare())
            .then(eng => eng.export())
            .then(e => setGCODEData(e))
        }


        //Store image in firebase
        const storage = getStorage()
        const storeData = async (data, fileLocation) => {
            return new Promise((resolve, reject) => {
                const fileName = `${auth.currentUser.uid}-${uuidv4()}-${data.name}`
                const storageRef = ref(storage, fileLocation + fileName)
                const uploadTask = uploadBytesResumable(storageRef, data)

                //Storing image and object references for deletion later
                if(fileLocation == 'models/'){
                    objectReferenceArray.push(fileName)
                } else if (fileLocation == 'images/'){
                    imageReferenceArray.push(fileName)
                }

                uploadTask.on('state_changed', 
                (snapshot) => {
                  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  setProgressBar(progress)
                  switch (snapshot.state) {
                    case 'paused':
                      //console.log('Upload is paused')
                      break
                    case 'running':
                      //console.log('Upload is running')
                      break
                  }
                }, 
                (error) => {
                    //console.log('Object or Image Storage Failed')
                    reject(error)
                }, 
                () => {
                  // Handle successful uploads on complete
                  // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                  getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    resolve(downloadURL)
                  })
                }
              )

            })
        }

        const imgUrls = await Promise.all(
            [...resizedImageArray].map((data) => storeData(data, 'images/'))
        ).catch(() => {
            setLoading(false)
            //console.log("image upload failed")
            return
        })
        
        const objectUrls = await Promise.all(
            [...object].map((data) => storeData(data, 'models/'))
        ).catch(() => {
            setLoading(false)
            //console.log("object upload failed")
            return
        })

        fetchModel()
        
        const hourlyRate = 3.5
        const smallPrice = Number(sliceResult[0] / 60 / 60 * hourlyRate).toFixed(2).replace(/[.,]0$/, '')
        const itemDataCopy = {
            ...itemData,
            imgUrls,
            objectUrls,
            objectReferenceArray,
            imageReferenceArray,
            prices: {Small: smallPrice * 1, Medium: smallPrice * 3, Large: smallPrice * 7},
            timestamp: serverTimestamp()
        }

        delete itemDataCopy.images
        delete itemDataCopy.object

        const docRef = await addDoc(collection(db, 'items'), itemDataCopy)

        const userRef = doc(db, 'users', auth.currentUser.uid)
        await updateDoc(userRef, {
            numItems: numItems + 1,
            itemIds: [...itemIds, docRef.id]
        })

        setLoading(false)

        setItemObjectReferences(objectReferenceArray)
        setItemImageReferences(imageReferenceArray)
        navigate(`/item/${docRef.id}`)
    }

    function CustomLoader() {
        const { progress } = useProgress()
        // console.log(progress)
        return (
            <Html center>
                <span style={{ color: 'white' }}>{progress} % loaded</span>
            </Html>
        )
    }

    function Scene({fileName}) {
        const mesh = useRef(null)
        //const scaleTemp = 0.05
        let scale = 0.05
        if (itemData.objectType == 'obj'){
            const obj = useLoader(OBJLoader, fileName)
            const box = useMemo(() => new THREE.Box3().setFromObject(obj), [])
            //console.log("OBJ Dimensions", box)
            useFrame(() => (mesh.current.rotation.y += 0.01))
            return (
                <mesh scale={.05} ref={mesh} position={[0,-2,0]}>
                    <primitive object={obj}  dispose={null} />
                </mesh>
            )
        } else if (itemData.objectType == 'stl') {
            const stl = useLoader(STLLoader, fileName)
            const { viewport } = useThree()
            stl.computeBoundingBox()
            const dimensions = stl.boundingBox
            computeScaleBy(dimensions)
            stl.center()
            stl.computeVertexNormals()
            stl.normalizeNormals()
            const largestDimension = computeLargestDimension(stl.boundingBox)
            const smallestCanvasDimension = Math.min(viewport.width, viewport.height)
            useFrame(() => (mesh.current.rotation.z -= 0.005))
            return (
                    <mesh scale={smallestCanvasDimension / (largestDimension * 1.4)} ref={mesh} position={[0,0,0]}>
                        <primitive object={stl} attach="geometry"/>
                        <meshStandardMaterial color={'gray'}/>
                    </mesh>
            )
        }  
    }

    const computeLargestDimension = (dimensions) => {
        const x = dimensions.max.x - dimensions.min.x
        const y = dimensions.max.y - dimensions.min.y
        const z = dimensions.max.z - dimensions.min.z
        return Math.max(x, y, z)
    }

    const computeScaleBy = (dimensions) => {
        const x = dimensions.max.x - dimensions.min.x
        const y = dimensions.max.y - dimensions.min.y
        const z = dimensions.max.z - dimensions.min.z
        if((x > y) && (x > z)){
            setScaleByFlag(true)
            setScaleBy(80 / x)
        } else if (y > z){
            setScaleByFlag(true)
            setScaleBy(80 / y)
        } else {
            setScaleByFlag(true)
            setScaleBy(80/ z)
        }
    }

    function signedVolumeOfTriangle(p1, p2, p3) {
        return p1.dot(p2.cross(p3)) / 6.0;
    }  
    
    const nameInput = [
        <input 
            required
            minLength={1}
            maxLength={50}
            placeholder="item name" 
            id="name" 
            value={name} 
            onChange={onTextChange} 
            className="input w-full input-primary input-bordered" 
            type="text" 
        />
    ]

    const descriptionInput = [
        <textarea
            required
            minLength={1}
            maxLength={500}
            placeholder="description" 
            id="description" 
            value={description} 
            onChange={onTextChange} 
            className="input textarea w-full h-24 textarea-primary textarea-bordered col-span" 
            type='text' 
        ></textarea>
    ]

    const imageInput = [
        <div className=''>
            <label for='image' className='btn btn-primary btn-sm'>
                Upload Images
            </label>
            <input
                //required
                style={{display: 'none'}}
                placeholder="image" 
                id="image"
                className=""
                onChange={onItemImageChange}
                max='4'
                accept=".jpg,.png,.gif,.jpeg"
                type="file" 
                multiple
            />
        </div>
    ]

    const objectInput = [
        <div className=''>
            <label for='object' className='btn btn-primary btn-sm'>
                Upload Model
            </label>
            <input
                //required
                style={{display: 'none'}}
                placeholder="object" 
                id="object"
                className=""
                onChange={onObjectChange}
                max={1}
                accept=".stl"
                type="file" 
                multiple
            />
        </div>
    ]

    // const priceInput = [
    //     <CurrencyInput
    //         required
    //         id='price'
    //         name='price'
    //         prefix='$'
    //         min={5.00}
    //         max={5000.00}
    //         allowNegativeValue={false}
    //         placeholder='price'
    //         decimalsLimit={2}
    //         onValueChange={(value) => setItemData((prevState) => ({
    //             ...prevState,
    //             price: Number(value)
    //         }))}
    //         className='input w-full input-primary input-bordered col-span' 
    //     />
    // ]

    if(loading){
        <div>
            Loading!!!
        </div>
    }

    //console.log('Slice Status', sliceStatus)
    //console.log('Slice Stage', sliceStage)
    return (
    <div className='flex flex-col mx-4 my-4 2xl:w-[60%] xl:w-[60%] lg:w-[70%] md:w-[80%]
    2xl:mx-auto xl:mx-auto lg:mx-auto md:mx-auto 2xl:my-8 xl:my-8 lg:my-8 md:my-8'>
        <div className="p-10 card bg-base-200">
            <h6 className='pb-6 font-bold text-2xl'>Upload Creation</h6>
            <div className="font-light text-md ml-4">The info in this form will be used to create both the item page and item card. You can go to your creator tools later to edit your items name, description and images.</div>
            <form onSubmit={onSubmit}>
                <div className="form-control mb-4">
                    <label className="label">
                        <span className="label-text font-bold text-lg">Item Name</span>
                    </label>
                        {nameInput}
                </div>
                
                <div className="form-control mb-4">
                    <label className="label">
                        <span className="label-text font-bold text-lg">Description</span>
                    </label>
                        {descriptionInput}
                </div>

                <div className="form-control mb-4 w-full">
                    <label className="label">
                        <span className="label-text font-bold text-lg">Image Upload</span>
                    </label>
                        {imageInput}
                    <div className='grid grid-cols-4 justify-items-center auto-cols-auto bg-base-100 rounded-2xl mt-2'>
                        {thumbnailUrl.map((url) => (
                            <div className='my-2 mx-2 p-2'>
                                <img src={url.url} alt="Uploaded Image" className="rounded-lg max-h-32"/>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="form-control mb-4 w-full">
                    <label className="label">
                        <span className="label-text font-bold text-lg">Model Upload <span className="label-text opacity-40">(.stl)</span></span>
                    </label>
                    <span className="font-light text-md ml-4 mb-4 mt-0">Layolay items only take stl file types right now. If you have a different 3D file  type (obj, 3mf, amf, wrl, etc.) you can click <a className='text-primary' target='_blank' href="https://anyconv.com/file-formats/">here</a> to convert the file to an stl file.</span>
                        {objectInput}
                        {fileData != null ? 
                            <div className='flex flex-wrap flex-row justify-around mt-4'>
                                <p>File Name: {fileData.name}</p>
                                <p>File Size: {fileData.size}</p>
                                <p>File Type: {fileData.type}</p>
                            </div> 
                            : 
                            <></>
                        }
                        {objectUrl.map((url) => (
                            <div className='mt-2'>
                                <Canvas className='rounded-lg' style={{ height: 300, width: 300 }} camera ={{ fov: 75, near: 10, far: 1000, position: [ 10, -30, 15] }}>
                                    <Suspense fallback={<CustomLoader />}>
                                        <ambientLight intensity={0.3} />
                                        <pointLight intensity={0.3} position={[ 0, -60, 15]} />
                                        {/* <spotLight intensity={0.3} position={[30, 30, 50]} angle={0.2} penumbra={1} castShadow /> */}
                                        <Center>
                                            <Scene fileName={url.url}  />
                                        </Center>
                                        <PerspectiveCamera />
                                    </Suspense>
                                </Canvas> 
                            </div>
                        ))}
                </div>

                <div className="form-control mb-4 w-full">
                    <label className="label">
                        <span className="label-text font-bold text-lg">Price</span>
                    </label>
                    <div className='font-light text-md ml-4'>
                        We calculate the price of your model by estimating its print time on our machines. If you have any questions regarding this calculation
                        or feel the price should be set at a different amount, please <Link className='text-primary' to={'/about'}>contact us.</Link>
                    </div>
                    
                    {sliceFlag ? 
                    
                        <div className='flex flex-col mt-4'>
                            <div className='self-center'>
                                    <div className='font-semibold self-center text-lg'>
                                            Calculating price, this may take a few moments
                                    </div>
                            </div>
                            <div className='flex sm:flex-col md:flex-col self-center justify-between mt-2'>
                                    <div className='flex flex-col self-center basis-1/3 my-2'>
                                        {/* {sliceStage.title} */}
                                        <span className='self-center font-normal text-lg'>Slicing Stage</span>
                                        <span className='self-center'>
                                            {sliceStage.stage != null ? 
                                                <>{sliceStage.stage}</>
                                                :
                                                <></>
                                            }
                                        </span>
                                        <div className='flex self-center my-2'>
                                            <progress className="progress progress-primary w-56 mr-4 self-center" value={sliceStatus * 100} max="100"></progress>
                                            <div className='self-center font-normal text-sm'>
                                                {Number(sliceStatus * 100).toFixed(2).replace(/[.,]0$/, '') < 100 ? <>{Number(sliceStatus * 100).toFixed(2).replace(/[.,]0$/, '')}%</>:<><FaCheck /></>}
                                            </div>
                                        </div> 
                                    </div>

                                    <div className='flex flex-col self-center basis-1/3 my-2'>
                                        <span className='self-center font-normal text-lg'>Prepare Stage</span>
                                        <div className='flex self-center my-2'>
                                            <progress className="progress progress-primary w-56 mr-4 self-center" value={prepareStatus * 100} max="100"></progress>
                                            <div className='self-center font-light text-sm'>
                                                {Number(prepareStatus * 100).toFixed(2).replace(/[.,]0$/, '') < 100 ? <>{Number(prepareStatus * 100).toFixed(2).replace(/[.,]0$/, '')}%</>:<><FaCheck /></>}
                                            </div>
                                        </div> 
                                    </div>

                                {sliceStatus == 1 && prepareStatus == 1 ? 

                                        <div className='flex flex-col self-center basis-1/3 my-2'>
                                            <span className='self-center font-normal text-lg'>Slicing Complete</span>
                                        </div> 

                                    :
                                    <></>
                                }
                            </div>
        
                            <div className='flex flex-col'>
                                {sliceStatus == 1 && prepareStatus == 1 ?
                                    <div className='self-center'>
                                    <Anime opacity={[0, 1]} loop={false} translateY={'1em'} delay={(el, index) => index * 700}>
                                        <Anime opacity={[0, 1]} loop={false} translateY={'1em'} delay={(el, index) => index * 700}>
                                            <div className='self-center font-normal text-md'>
                                                Estimated Print Time: {sliceResult != '' ? 
                                                        <span className='font-light'> {Number(sliceResult[0] / 60 / 60).toFixed(2).replace(/[.,]0$/, '')} Hrs</span>
                                                        :
                                                        <></>
                                                        }
                                            </div>
                                        </Anime>
                                        <div className='flex flex-col self-center mt-2 font-normal text-lg'>
                                            {sliceResult != '' ? 
                                                <div className='self-center'>
                                                <Anime opacity={[0, 1]} loop={false} translateY={'1em'} delay={(el, index) => index * 500}>
                                                    <div className='mx-auto'>
                                                        Small: <span className='font-light'>${Number(sliceResult[0] / 60 / 60 * 5).toFixed(2).replace(/[.,]0$/, '')}</span>
                                                    </div>
                                                    <div className='mx-auto'>
                                                        Medium: <span className='font-light'>${Number(sliceResult[0] / 60 / 60 * 5 * 2).toFixed(2).replace(/[.,]0$/, '')}</span>
                                                    </div>
                                                    <div className='mx-auto'>
                                                        Large: <span className='font-light'>${Number(sliceResult[0] / 60 / 60 * 5 * 4).toFixed(2).replace(/[.,]0$/, '')}</span>
                                                    </div>
                                                </Anime>
                                                </div>
                                                :
                                                <></>
                                            }
                                        </div>
                                        </Anime>
                                    </div>
                                    
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                        
                        :
                        <></>
                    }
                   


                </div>

                <div className="card-actions justify-end">
                    <button type="submit" className='btn btn-primary' disabled={((itemData.objectType == 'stl') && (sliceStatus == 1 && prepareStatus == 1) && !disableButton && sliceResult[0] != '') ? false : true}>Upload</button>
                </div>
            </form>
            
            {loading ? 
             <div className='flex flex-col'>
                 <div className='font-semibold self-center'>
                     Uploading your model to our database, this may take a few moments
                 </div>
                 <div className='self-center mt-2'>
                    <progress className="progress progress-primary w-56 mr-4" value={progressBar} max="100"></progress> 
                    {Number(progressBar).toFixed(2).replace(/[.,]0$/, '')}% Completed
                </div>
             </div>
             : 
             <></>}
        </div>
    </div>
    )
}

export default ItemForm