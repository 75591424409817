import { createContext, useReducer } from "react"
import { Cookies } from 'react-cookie'
import customerReducer from './CustomerReducer'

const CustomerContext = createContext()

export const CustomerProvider = ({children}) => {

    const cookies = new Cookies();
    const cartCookie = (cookies.get('cartCookie') == undefined) ? [] : cookies.get('cartCookie')
    const discountCookie = (cookies.get('discountCookie') == undefined) ? [] : cookies.get('discountCookie')

    // cart item shape:
    // {itemId: str, userId: str, quantity: int, options: {size: str, color: str, note: str, gift: bool}}
    const initialState = {
      cart: cartCookie,
      cartTotal: 0,
      cartSize: 0,
      discounts: discountCookie,
      discountTotal: 0,
      savedItems: [],
    }

    const [state, dispatch] = useReducer(customerReducer, initialState)

    const addItem = (cartItem) => dispatch({
      type: 'ADD_ITEM',
      payload: cartItem
    })
    
    const updateItem = (cartItem) => dispatch({
      type: 'UPDATE_ITEM',
      payload: cartItem
    })

    const removeItem = (itemId) => dispatch({
      type: 'REMOVE_ITEM',
      payload: itemId
    })

    const addDiscount = (discount) => dispatch({
      type: 'ADD_DISCOUNT',
      payload: discount
    })

    const removeDiscount = (code) => dispatch({
      type: 'REMOVE_DISCOUNT',
      payload: code
    })

    const getTotal = () => dispatch({
      type: 'GET_TOTAL'
    })

    const getSize = () => dispatch({
      type: 'GET_SIZE'
    })
    
    const getDiscount = () => dispatch({
      type: 'GET_DISCOUNT'
    })

    const setCart = (newCart) => dispatch({
      type: 'SET_CART',
      payload: newCart
    })

    const setDiscounts = (newDiscounts) => dispatch({
      type: 'SET_DISCOUNTS',
      payload: newDiscounts
    })

    return (
      <CustomerContext.Provider 
        value={{
          cart: state.cart,
          cartTotal: state.cartTotal,
          cartSize: state.cartSize,
          discounts: state.discounts,
          discountTotal: state.discountTotal,
          addItem,
          updateItem,
          removeItem,
          addDiscount,
          removeDiscount,
          getTotal,
          getSize,
          getDiscount,
          setCart,
          setDiscounts
        }}>
          {children}
      </CustomerContext.Provider>
    )
}

export default CustomerContext