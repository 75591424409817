import { useState, useEffect } from 'react'
import { useNavigate, Link } from "react-router-dom"
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../firebase.config'
import { RiStarSLine, RiStarSFill } from 'react-icons/ri'

function ProfileReviewCard({review: {body, rating, timestamp, itemId, postingUserId, owningUserId}}) {

    const [postingUser, setPostingUser] = useState({})
    const [owningUser, setOwningUser] = useState({})
    const [item, setItem] = useState(null)

    useEffect(() => {
        const fetchPostingUser = async () => {
            const docRef = doc(db, 'users', postingUserId)
            const docSnap = await getDoc(docRef)
            
            if(docSnap.exists()){
                setPostingUser(docSnap.data())
            }
        }

        const fetchOwningUser = async () => {
            const docRef = doc(db, 'users', owningUserId)
            const docSnap = await getDoc(docRef)
            
            if(docSnap.exists()){
                setOwningUser(docSnap.data())
            }
        }

        const fetchItem = async () => {
            const docRef = doc(db, 'items', itemId)
            const docSnap = await getDoc(docRef)
            
            if(docSnap.exists()){
                setItem(docSnap.data())
            }
        }

        fetchPostingUser()
        fetchOwningUser()
        fetchItem()
        
    }, [postingUserId])

    return (
        <div className='flex bg-base-200 rounded-lg p-4 mt-4'>
            <div>
                <div className='flex items-center'>
                    <div className="avatar">
                        <div className="rounded-md w-10 h-10 m-1">
                            <Link to={`/profile/${postingUserId}`}>
                                <img src={postingUser.profilePic} />
                            </Link>
                        </div>
                        <Link to={`/profile/${postingUserId}`}><p className='font-medium text-md ml-2 my-3'>{postingUser.name}</p></Link>
                        {/* <div className='font-normal text-md mx-2 my-3'>
                            gave {owningUser.name}
                        </div> */}
                    </div>
                    <div className='ml-4 flex font-bold text-md'>
                        {[...Array(5).keys()].map((n) => (
                            <>
                                {n < rating ?
                                    <RiStarSFill key={n} size={25} />
                                    : <RiStarSLine key={n} size={25} />
                                }
                            </>
                        ))}
                    </div>
                </div>
                <div className='font-light text-lg ml-2 mt-4'>
                    {body}
                </div>
            </div>
            <div className='ml-auto'>
                {item != null ?
                    <Link to={`/item/${itemId}`}>
                        <img src={item.imgUrls[0]} className='bg-base-300 p-2 rounded-lg h-24 object-cover' />
                    </Link>
                    : <></>
                }
            </div>
        </div>
    )
}

export default ProfileReviewCard