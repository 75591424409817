import { startCase } from 'lodash'
import { Cookies } from 'react-cookie'

const customerReducer = (state, action) => {
    const cookies = new Cookies()

    switch(action.type) {
        case 'ADD_ITEM':
            // Check if item is already in cart, if so, increase its quantity
            // for (let i = 0; i < state.cart.length; i++){
            //     if (state.cart[i].itemId == action.payload.itemId){
            //         var newCart = state.cart.map((cartItem) => {
            //             let newItem = {...cartItem}
            //             if (cartItem.itemId == action.payload.itemId){
            //                 newItem.quantity = newItem.quantity + action.payload.quantity
            //             }
            //             return newItem
            //         })

            //         // Update cookie
            //         cookies.set('cartCookie', JSON.stringify(newCart), {path: '/'});

            //         return {
            //             ...state,
            //             cart: newCart
            //         }
            //     }
            // }

            // Update cookie
            let cartCookie = (cookies.get('cartCookie') == undefined) ? [] : cookies.get('cartCookie')
            var newCart = (cartCookie.length === 0) ? [action.payload] : [...cartCookie, action.payload]
            cookies.set('cartCookie', JSON.stringify(newCart), {path: '/'});

            return {
                ...state,
                cart: newCart,
            }

        case 'UPDATE_ITEM':
            // Find item to update
            for (let i = 0; i < state.cart.length; i++){
                if (state.cart[i].cartItemId == action.payload.cartItemId){
                    var newCart = state.cart.map((cartItem) => {
                        let newItem = {...cartItem}
                        if (cartItem.cartItemId == action.payload.cartItemId){
                            newItem = {...action.payload}
                        }
                        return newItem
                    })

                    // Update cookie
                    cookies.set('cartCookie', JSON.stringify(newCart), {path: '/'});

                    return {
                        ...state,
                        cart: newCart
                    }
                }
            }

            // Update cookie
            cookies.set('cartCookie', JSON.stringify(newCart), {path: '/'});

            return {
                ...state,
            }
            
        case 'REMOVE_ITEM':
            var newCart = state.cart
            var index = state.cart.findIndex((cartItem) => {
                return (cartItem.cartItemId === action.payload)
            })

            // Remove item from cart
            if (index > -1) {
                newCart.splice(index, 1); // 2nd parameter means remove one item only
            }

            // Update cookie
            cookies.set('cartCookie', JSON.stringify(newCart), {path: '/'});
            return {
                ...state,
                cart: newCart
            }
        
        case 'ADD_DISCOUNT':
            // If code is already on the list of discounts, don't add it
            for (let i = 0; i < state.discounts.length; i++){
                if (state.discounts[i].code == action.payload.code){
                    return {
                        ...state
                    }
                }
            }    
        
            // Update cookie
            cookies.set('discountCookie', [...state.discounts, action.payload], {path: '/'});
            return {
                ... state,
                discounts: [...state.discounts, action.payload]
            }
        
        case 'REMOVE_DISCOUNT':
            var newDiscounts = state.discounts
            var index = state.discounts.findIndex((discount) => {
                return (discount.code === action.payload)
            })

            // Remove item from cart
            if (index > -1) {
                newDiscounts.splice(index, 1); // 2nd parameter means remove one item only
            }

            // Update cookie
            cookies.set('discountCookie', JSON.stringify(newDiscounts), {path: '/'});
            return {
                ...state,
                discounts: newDiscounts
            }

        case 'GET_TOTAL':
            let total = 0
            for (let i = 0; i < state.cart.length; i++){
                if (state.cart[i].options.size == 'Small'){
                    total += state.cart[i].quantity * state.cart[i].prices['Small']
                } else if (state.cart[i].options.size == 'Medium'){
                    total += state.cart[i].quantity * state.cart[i].prices['Medium']
                } else if (state.cart[i].options.size == 'Large'){
                    total += state.cart[i].quantity * state.cart[i].prices['Large']
                } 
            }
            return {
                ...state,
                cartTotal: total,
            }  

        case 'GET_SIZE':
            let size = 0
            for (let i = 0; i < state.cart.length; i++){
                if (state.cart[i].options.size == 'Small'){
                    size += state.cart[i].quantity * 1
                } else if (state.cart[i].options.size == 'Medium'){
                    size += state.cart[i].quantity * 2
                } else if (state.cart[i].options.size == 'Large'){
                    size += state.cart[i].quantity * 4
                } 
            }
            let shippingPrice = (Math.floor(size / 4) + 1) * 3.5 
            return {
                ...state,
                cartSize: shippingPrice,
            }
        
        case 'GET_DISCOUNT':
            // TODO: MAKE THIS COUNT NON-FLAT DISCOUNT VALUES
            let discount = 0
            for (let i = 0; i < state.discounts.length; i++){
                discount += state.discounts[i].value.flat
            }  

            return {
                ...state,
                discountTotal: discount
            }

        case 'SET_CART':
            // Update cookie
            cookies.set('cartCookie', action.payload, {path: '/'});
            return {
                ...state,
                cart: action.payload,
            }
        
        case 'SET_DISCOUNTS':
            // Update cookie
            cookies.set('discountCookie', action.payload, {path: '/'});
            return {
                ...state,
                discounts: action.payload,
            }

        default:
            return state
    }
}

export default customerReducer