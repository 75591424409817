import { useState, useEffect } from 'react'
import Layolay from '../media/layolay.svg'
import Design from '../media/design.png'
import Upload from '../media/upload.png'
import Print from '../media/print.png'
import Recieve from '../media/recieve.png'
import { useNavigate } from 'react-router-dom'
import VisibilitySensor from 'react-visibility-sensor'
import { Spring } from 'react-spring'
import Aos from 'aos'
import 'aos/dist/aos.css'


function About() {
    
    const navigate = useNavigate()
    //const [offScreen, setOffScreen] = useState(true)

    useEffect (() => {
        Aos.init({
            duration: 1000,
            easing: 'ease-in-sine',
            once: true,
        })
    }, [])

    useEffect(() => {
        document.title = 'Layolay | About'
    }, [])

    return (
        <>
            <div className="flex flex-col mx-auto">
                <div className='mx-auto hidden mt-8 2xl:flex xl:flex lg:flex'>
                    <img src = {Layolay} className='mx-auto h-48'></img>
                </div>
                <div className='grid my-8 mx-8 grid-cols-2 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-4'>
                    <div data-aos='fade-right' className='flex flex-col rounded-lg p-4'>
                        <img src={Design} className='mx-auto w-48' />
                        <div className='font-light text-xl mx-auto mb-4'>
                            Artists design...
                        </div>
                    </div>
                    <div data-aos='fade-right' data-aos-delay="100" className='flex flex-col rounded-lg p-4'>
                        <img src={Upload} className='mx-auto w-48' />
                        <div className='font-light text-xl mx-auto mb-4'>
                            ...and upload.
                        </div>
                    </div>
                    <div data-aos='fade-right' data-aos-delay="200" className='flex flex-col rounded-lg p-4'>
                        <img src={Print} className='mx-auto w-48' />
                        <div className='font-light text-xl mx-auto mb-4'>
                            We print...
                        </div>
                    </div>
                    <div data-aos='fade-right' data-aos-delay="300" className='flex flex-col rounded-lg p-4'>
                        <img src={Recieve} className='mx-auto w-48' />
                        <div className='font-light text-xl mx-auto mb-4'>
                            ...you recieve!
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="flex flex-col mx-auto 2xl:w-[70%] xl:w-[70%] lg:w-[80%] md:w-[80%]">
                <div className='flex flex-col px-4 2xl:flex-row xl:flex-row lg:flex-row'>
                    <div className='flex flex-col basis-1/2 p-4'>
                        <div className='font-bold text-2xl 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-4xl'>
                            Layolay for Creators
                        </div>
                        <div className='font-light text-base 2xl:text-lg xl:text-lg lg:text-lg md:text-lg mt-4'>
                            Upload your existing models or design new ones for your shop! Let us handle manufacturing and shipping and you get paid!
                        </div>
                        <button className='btn btn-primary mx-auto mt-8 w-42 2xl:w-64 xl:w-64 lg:w-64' onClick = {() => {navigate('/creator-tools')}}>
                            Become an Artist!
                        </button>
                    </div>
                    <div className='flex flex-col basis-1/2 p-4 mt-4 2xl:mt-0 xl:mt-0 lg:mt-0'>
                        <div className='font-bold text-2xl 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-4xl'>
                            Layolay for Consumers
                        </div>
                        <div className='font-light text-base 2xl:text-lg xl:text-lg lg:text-lg md:text-lg mt-4'>
                            Find custom 3D prints for gifts, home decor, or even common missing parts. Support your favorite shops and get discounts and deals!
                        </div>
                        <button className='btn btn-primary mx-auto mt-8 w-42 2xl:w-64 xl:w-64 lg:w-64' onClick = {() => {navigate('/')}}>
                            Find something cool!
                        </button>
                    </div>
                </div>
                <div data-aos='fade-right' className='flex 2xl:mt-8 xl:mt-8 lg:mt-8'>
                    <div className='mx-auto px-8 2xl:ml-24 xl:ml-24 lg:ml-24 2xl:basis-1/2 xl:basis-2/3 lg:basis-2/3 md:basis-2/3'>
                        <div className='font-bold text-2xl 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-4xl mt-8'>
                            Our Company
                        </div>
                        <div className='font-light text-base 2xl:text-lg xl:text-lg lg:text-lg md:text-lg mt-4'>
                            Layolay is a new company wanting to connect the everyday consumer with high quality prints designed by 3D artists in the community.
                            We make it easy for 3D modelers to sell their work with no effort beyond uploading their model. Find your favorite 3D models and get
                            them delivered to you with custom colors and sizes.
                        </div>
                    </div>
                </div>
                <div data-aos='fade-left' className='flex'>
                    <div className='2xl:mr-24 xl:mr-24 lg:mr-24 2xl:basis-1/3 xl:basis-1/4 lg:basis-1/4 md:basis-1/4 '>
                    </div>
                    <div className='mx-auto px-8 2xl:basis-1/2 xl:basis-3/4 lg:basis-3/4 md:basis-3/4'>
                        <div className='font-bold text-2xl 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-4xl mt-8'>
                            Our Promise
                        </div>
                        <div className='font-light text-base 2xl:text-lg xl:text-lg lg:text-lg md:text-lg mt-4'>
                            We want to optimize the process of ordering and recieving custom 3D artwork and parts. With our proprietary 3D automation software
                            we will:
                        </div>
                        <div className='font-light text-base 2xl:text-lg xl:text-lg lg:text-lg md:text-lg ml-4 mt-2'>
                            &#8226; Make ordering easy
                        </div>
                        <div className='font-light text-base 2xl:text-lg xl:text-lg lg:text-lg md:text-lg ml-4 mt-2'>
                            &#8226; Print quickly with high quality
                        </div>
                        <div className='font-light text-base 2xl:text-lg xl:text-lg lg:text-lg md:text-lg ml-4 mt-2'>
                            &#8226; Ship to you within 2 weeks
                        </div>
                    </div>
                </div>
                <div data-aos='fade-right' className='flex'>
                    <div className='mx-auto px-8 2xl:ml-24 xl:ml-24 lg:ml-24 2xl:basis-1/2 xl:basis-2/3 lg:basis-2/3 md:basis-2/3'>
                        <div className='font-bold text-2xl 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-4xl mt-8'>
                            Our Team
                        </div>
                        <div className='font-bold text-xl mt-4'>
                            Christian Boswell<span className='font-light text-sm'>, Co-Founder</span>
                        </div>
                        <div className='font-light text-base 2xl:text-lg xl:text-lg lg:text-lg md:text-lg mt-4'>
                            A senior at The University of Texas at Austin majoring in Electrical Engineering, Christian has been
                            a 3D-Printing enthusiast for 6+ years. He works at Layolay as a Full Stack Developer and Print Automator.
                        </div>
                        <div className='font-bold text-xl mt-4'>
                            Samuel Perales<span className='font-light text-sm'>, Co-Founder</span>
                        </div>
                        <div className='font-light text-base 2xl:text-lg xl:text-lg lg:text-lg md:text-lg mt-4'>
                            Graduating from The University of Texas at Austin with a degree in Mathematics, Samuel has been a friend of Christian's
                            since middle school. He works at Layolay as a Full Stack Developer and Operations Manager.
                        </div>
                    </div>
                </div>
                <div className='font-bold text-2xl mt-16 mx-auto'>
                    Have a question for us?
                </div>
                <button className='btn btn-primary mt-8 mb-16 mx-auto' onClick = {() => navigate('/jobs')}>
                    Contact Now
                </button>
                {/* <div className='font-bold text-2xl mt-16 mx-auto'>
                    Interested in growing Layolay with us?
                </div>
                <button className='btn btn-primary my-8 mx-auto' onClick = {() => navigate('/jobs')}>
                    See Job Availability
                </button> */}
            </div>
        </>
    )
}

export default About
