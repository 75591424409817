import { Suspense, useRef, useState } from 'react'
import Layolay from '../media/layolay.svg'
import { Canvas, useFrame, useLoader, useThree } from "@react-three/fiber"
import {  PerspectiveCamera, useProgress, Html, Center, OrbitControls, useAspect } from '@react-three/drei'
import * as THREE from "three"
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'
import Spinner from '../components/Spinner'

function ModelScene({fileName, color}) {

    THREE.Object3D.DefaultUp.set(0, 0, 1)
    const [stopModelRotation, setStopModelRotation] = useState(false)
    const [objectHasBeenRenderedFlag, setObjectHasBeenRenderedFlag] = useState(false)

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    }

    function Scene() {
            const mesh = useRef(null)
            const stl = useLoader(STLLoader, fileName)
            const { viewport } = useThree()
            if(objectHasBeenRenderedFlag == false){
                stl.computeBoundingBox()
                stl.center() 
                stl.computeVertexNormals()
                stl.normalizeNormals()
                setObjectHasBeenRenderedFlag(true)
            }
            const largestDimension = computeLargestDimension(stl.boundingBox)
            const smallestCanvasDimension = Math.min(viewport.width, viewport.height)
            const rotationSpeed =  getRandomInt(2, 7) / 1000
            if(stopModelRotation != true){
                useFrame(() => (mesh.current.rotation.z -= rotationSpeed))
                // console.log(rotationSpeed)
            } else {
                useFrame(() => (mesh.current.rotation.z = mesh.current.rotation.z))
            }
            return (
                    <mesh scale={0.65 * smallestCanvasDimension / largestDimension} ref={mesh} position={[0,0,0]}>
                        <primitive object={stl} attach="geometry"/>
                        <meshStandardMaterial color={color}/>
                    </mesh>
            )
    }

    const computeLargestDimension = (dimensions) => {
        const x = dimensions.max.x - dimensions.min.x
        const y = dimensions.max.y - dimensions.min.y
        const z = dimensions.max.z - dimensions.min.z
        return Math.max(x, y, z)
    }

    const mod = (x, y) => {
        return ((x % y) + y) % y
    }

    function CustomLoader() {
        const { progress } = useProgress()
        // console.log(progress)
        return (
            <Html center>
                {/* <span className='font-light text-md'>
                    <Spinner />
                    Loading!
                </span> */}
            </Html>
        )
    }


  return (
        <Canvas onClick={() => setStopModelRotation(true)} className='rounded-lg' camera={{ fov: 75, near: .01, far: 1000, position: [ 0, -30, 15] }} shadowMap>
            <Suspense fallback={<CustomLoader />}>
                <ambientLight intensity={0.4} />
                <pointLight intensity={0.4} position={[ 0, -60, 15]} />
                <pointLight intensity={0.4} position={[ 0, 60, 15]} />
                <pointLight intensity={0.4} position={[ 0, 0, 70]} />
                <OrbitControls enableZoom={false}  enableRotate={true} />
                {/* Enable the axes helper below for easier debugging */}
                {/* <primitive object={new THREE.AxesHelper(3000)} /> */}
                <Center>
                    <Scene />
                </Center>
            </Suspense>
        </Canvas>
  )
}

export default ModelScene