import SearchBar from '../components/SearchBar'
import UserList from '../components/UserList'
import { useEffect } from 'react'

function Creators() {

    useEffect(() => {
        document.title = 'Layolay | Creators'
    }, [])

    return (
        <>
            <div className='font-bold text-4xl mx-auto mt-8'>
                Creators
            </div>
            <div className='font-light text-xl text-center mt-4 px-4'>
                Find and support shops that make the type of content you enjoy!
            </div>
            <div className='container mx-auto my-8 px-4'>
                {/* <SearchBar /> */}
                <UserList />
            </div>
        </>
        
    )
}

export default Creators
