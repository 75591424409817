import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

function ThankYou() {
    
    const navigate = useNavigate()
    const [loggedIn, setLoggedIn] = useState(false)
    
    useEffect(() => {
        document.title = 'Layolay | Thank You'
    }, [])

    const isMounted = useRef(true)
    const auth = getAuth()
    useEffect(() => {
        if(isMounted){
            onAuthStateChanged(auth, (user) => {
                if(user) {
                    setLoggedIn(true)
                } else {
                    setLoggedIn(false)
                }
            })
        }
        return () => {
            isMounted.current = false
        }
  
    }, [isMounted])

    return (
        <div className='flex flex-col bg-base-200 rounded-lg m-4 p-8 2xl:mx-auto xl:mx-auto lg:mx-auto md:mx-auto sm:mx-auto
        2xl:my-auto xl:my-auto lg:my-auto md:my-auto sm:my-auto'>
            <div className="font-bold text-3xl text-center">Order Complete!</div>
            <div className="font-light text-2xl text-center m-8">
                Thank you for your business!
            </div>
            {loggedIn ?
                <button className='btn btn-normal center mx-auto mt-8 w-64' onClick = {() => navigate('/order-history')}>
                    View My Orders
                </button>
            :
                <></>
            }

            <button className='btn btn-primary center mx-auto mt-4 w-64' onClick = {() => navigate('/')}>
                Keep Shopping
            </button>
        </div>
    )
}

export default ThankYou