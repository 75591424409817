import { useState  } from 'react'
import Hero from '../components/Hero'
import ItemList from '../components/ItemList'
import { useEffect } from 'react'
import { WithSearch } from "@elastic/react-search-ui"
import DynamicItemCard from '../components/DynamicItemCard'
import DynamicItemCardList from '../components/DynamicItemCardList'
import { FaSearch } from 'react-icons/fa'
//import { useTrail, animated, to } from 'react-spring'

function Home() {

    const [searchTerm, setSearchTerm] = useState('')
    const [resultsLength, setResultsLength] = useState(0)

    useEffect(() => {
        document.title = 'Layolay | Custom 3D Prints'
    }, [])

    return (
        <>
            <Hero />
            <div className='container mx-auto px-2 mb-8'>
                <WithSearch
                    mapContextToProps={({ searchTerm, setSearchTerm, results }) => ({
                    searchTerm,
                    setSearchTerm,
                    results
                    })}
                >
                    {({ searchTerm, setSearchTerm, results }) => {
                    return (
                        <>
                            <div className='input-group justify-center mb-8'>
                                <span><FaSearch size={20} className='my-auto'/></span>
                                <input
                                    className='w-1/2 input input-primary input-bordered'
                                    value={searchTerm}
                                    placeholder='Search for a creation'
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value)
                                        console.log(results)
                                    }}
                                />
                            </div>
                            <div className={`${searchTerm == '' ? 'hidden' : ''}`}>
                                <DynamicItemCardList data={results} />
                            </div>
                            <div className={`${searchTerm == '' ? '' : 'hidden'}`}>
                                <ItemList />
                            </div>
                        </>
                    )
                    }}
                </WithSearch>
            </div>
        </>
    )
}

export default Home
