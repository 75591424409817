import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import SmallModels from '../media/small-models.png'

const availableColors = [
    {name: 'Galaxy Black', hex: 'bg-[#2c2e3a]', text: 'text-white'},
    {name: 'Galaxy Purple', hex: 'bg-[#483470]', text: 'text-white'},
    {name: 'Hatchbox Black', hex: 'bg-[#383934]', text: 'text-white'},
    {name: 'Vanilla White', hex: 'bg-[#dad5d1]', text: 'text-black'},
    {name: 'Royal Blue', hex: 'bg-[#2b4ea1]', text: 'text-white'},
    {name: 'Silk Light Blue', hex: 'bg-[#a1b4e5]', text: 'text-black'},
    {name: 'Silver', hex: 'bg-[#7d787c]', text: 'text-white'},
    {name: 'Gold', hex: 'bg-[#ceb61e]', text: 'text-black'},
    {name: 'Hatchbox Red', hex: 'bg-[#bd131b]', text: 'text-white'},
    {name: 'Burnt Orange', hex: 'bg-[#e97418]', text: 'text-white'},
    {name: 'Opal Green', hex: 'bg-[#05654a]', text: 'text-white'},
]

function Options() {

    useEffect(() => {
        document.title = 'Layolay | Options'
    }, [])

    return (
        <div className='flex flex-col bg-base-200 rounded-lg m-4 p-8 2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[90%]
            2xl:mx-auto xl:mx-auto lg:mx-auto md:mx-auto 2xl:my-8 xl:my-8 lg:my-8 md:my-8'>
            <div className="font-bold text-3xl">
                Order Options
            </div>
            <div className="font-normal text-xl mt-8">
                Sizes and Pricing
            </div>
            <div className='font-light text-md mt-4'>
                Layolay currently offers three print sizes; <strong>small <span className='text-md'>(8cm)</span>, 
                medium <span className='text-md'>(12cm)</span>,</strong> and <strong>large <span className='text-md'>(16cm)</span></strong>.
                When sizing a particular model, we set it's largest dimension to the selected size. Both of the models below would be considered smalls.
            </div>
            <div className='flex'>
                <img src={SmallModels} className='mx-auto w-[600px]'/>
            </div>
            <div className='font-light text-md mt-4'>
                The price of a model is determined by its volume and its print time. If a model takes longer to print or uses more material, the price will increase.
                Generally, mediums are double the price of smalls, and larges are double the price of mediums.
            </div>
            <div className="font-normal text-xl mt-8">
                Colors
            </div>
            <div className='font-light text-md mt-4'>
                Layolay offers a wide variety of PLA plastic color options. Currently, Layolay is offering the following colors:
            </div>
            <div className='grid grid-cols-4 gap-2 mt-4'>
                {availableColors.map((color) => (
                    <div key={color.name} 
                        className={`flex rounded-md font-light text-xs p-2 text-center cursor-pointer ${color.hex} ${color.text} hover:brightness-125`} 
                        onClick={() => setColor(color.hex)}
                    >
                        <span className='mx-auto my-auto'>{color.name}</span>
                    </div>
                ))}
            </div>
            <div className="font-normal text-xl mt-8">
                Quantity
            </div>
            <div className='font-light text-md mt-4'>
                Layolay will print any number of models with each order, but expect an additional week of print time for every 4-5 models you add.
            </div>
            <div className="font-normal text-xl mt-8">
                Special Instructions
            </div>
            <div className='font-light text-md mt-4'>
                Some models include the option to leave Special Instructions. These typically have the purpose of giving Layolay important customization information
                such as a custom name or design change. Please look in the item description to see whether we will accept Special Instructions for a particular model.
                Special Instructions are NOT a place to request custom models or make large changes to the design which are not offered.
            </div>
            <div className="font-normal text-xl mt-8">
                Gifting
            </div>
            <div className='font-light text-md mt-4'>
                The gift option allows you to select an additional shipping address to send a subset of your order to. You can also leave a note in the
                Special Instructions and we will attach this to the model before shipping.
            </div>
            <div className="font-normal text-xl my-8 mx-auto">
                Please <Link className='text-primary' to={'/about'}>contact us</Link> if you have any further questions about your order options.
            </div>
        </div>
    )
}

export default Options
