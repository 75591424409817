import { useState, useEffect } from 'react'
import { collection, getDocs, query, orderBy, where, limit, startAfter } from 'firebase/firestore'
import { db } from '../firebase.config'
import ItemCard from './ItemCard'
import { useTrail, animated, config } from 'react-spring'

function ItemList() {

    const [loading, setLoading] = useState(false)
    const [reanimate, setReanimate] = useState(true)
    const [items, setItems] = useState([])
    const [lastFetchedItem, setLastFetchedItem] = useState(null)
    const [moreItemsToLoad, setMoreItemsToLoad] = useState(true)
    const getItems = async () => {
        setLoading(true)

        try {
            // Get reference
            const itemRef = collection(db, 'items')

            // Create a query
            const q = query(
                itemRef,
                where('hidden', '==', false),
                limit(15)
            )

            // Execute query
            const querySnap = await getDocs(q)
            const lastVisible = querySnap.docs[querySnap.docs.length-1]
            setLastFetchedItem(lastVisible)
            
            let items = []
            querySnap.forEach((doc) => {
                return items.push({
                    id: doc.id,
                    data: doc.data()
                })
            })

            if(items.length < 15){
                setMoreItemsToLoad(false)
            }
            setItems(items)

        } catch(error) {
            console.log(error)
        }
    }

    const getMoreItems = async () => {
        setLoading(true)

        try {
            // Get reference
            const itemRef = collection(db, 'items')

            // Create a query
            const q = query(
                itemRef,
                where('hidden', '==', false),
                startAfter(lastFetchedItem),
                limit(15)
            )

            // Execute query
            const querySnap = await getDocs(q)
            const lastVisible = querySnap.docs[querySnap.docs.length-1]
            setLastFetchedItem(lastVisible)
            
            let items = []
            querySnap.forEach((doc) => {
                return items.push({
                    id: doc.id,
                    data: doc.data()
                })
            })

            if(items.length < 15){
                setMoreItemsToLoad(false)
            }
            setItems((prevState) => [...prevState, ...items])

        } catch(error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getItems()
    }, [])

    // useEffect(() => {
    //     if(items != []){

    //     }
    // }, [items])

    //const config = { mass: 5, tension: 2000, friction: 200 }
    const trail = useTrail(items.length, {
        config: config.gentle,
        opacity: 1,
        // reset: reanimate,
        x:  0,
        height: 80,
        from: { opacity: 0, x: 20, height: 0 },
        // onStart: () =>  setReanimate(false), 
        // onChange: () => setReanimate(true),
    })

    return (
        <div>
            {items.length > 0 ? 
                <>
                    <div className='grid grid-cols-2 gap-2 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2
                    2xl:gap-4 xl:gap-4 lg:gap-4 md:gap-4 sm:gap-4'>
                        {trail.map(({ x, height, ...rest }, index) => (
                        <animated.div
                            key={items[index].id}
                            className="trails-text"
                            style={{
                            ...rest,
                            transform: x.to(x => `translate3d(0,${x}px,0)`)
                            }}
                        >
                            <ItemCard key={items[index].id} id={items[index].id} item={items[index].data}/>
                        </animated.div>
                        ))}
                        {/* {items.map((item) => (
                            <ItemCard key={item.id} id={item.id} item={item.data}/>
                        ))} */}
                    </div>
                    {moreItemsToLoad ?
                        <div className='flex container'> 
                            <button className='btn btn-primary mx-auto my-4' onClick={getMoreItems}>Load More Items</button>
                        </div>
                        : <></>
                    }
                    
                </> : <p>No items found</p>
            }
        </div>
    )
}

export default ItemList