import { useState, useEffect } from 'react'
import { useNavigate, Link } from "react-router-dom"
import { getAuth } from 'firebase/auth'
import { doc, getDoc, setDoc, deleteDoc, serverTimestamp } from 'firebase/firestore'
import { db } from '../firebase.config'
import { RiStarSLine, RiStarSFill } from 'react-icons/ri'
import { FiEdit, FiTrash } from 'react-icons/fi'

function Review({id: reviewId, review: initialReview}) {
    
    const [body, setBody] = useState(initialReview.body)
    const [rating, setRating] = useState(initialReview.rating)
    const {itemId, timestamp, postingUserId, owningUserId} = initialReview

    const [postingUser, setPostingUser] = useState({})
    const [owningUser, setOwningUser] = useState({})

    const [userRating, setUserRating] = useState(rating)
    const [userReview, setUserReview] = useState(body)
    const [editing, setEditing] = useState(false)
    const [deleted, setDeleted] = useState(false)

    const auth = getAuth()

    useEffect(() => {
        const fetchUsers = async () => {
            const postingUserRef = doc(db, 'users', postingUserId)
            const postingUserSnap = await getDoc(postingUserRef)
            
            if(postingUserSnap.exists()){
                setPostingUser(postingUserSnap.data())
            }

            const owningUserRef = doc(db, 'users', owningUserId)
            const owningUserSnap = await getDoc(owningUserRef)
            
            if(owningUserSnap.exists()){
                setOwningUser(owningUserSnap.data())
            }
        }

        fetchUsers()
        
    }, [postingUserId, owningUserId])
    
    const updateReview = async() => {
        await setDoc(doc(db, 'reviews', reviewId), {
            body: userReview,
            itemId: itemId,
            rating: userRating,
            timestamp: serverTimestamp(),
            postingUserId: postingUserId,
            owningUserId: owningUserId,
        })
        
        await setDoc(doc(db, 'users', owningUserId), {
            ...owningUser,
            totalRating: owningUser.totalRating + (userRating - rating)
        })

        setBody(userReview)
        setRating(userRating)
        
        fetchUsers()
    }

    const deleteReview = async () => {
        setDeleted(true)
        await deleteDoc(doc(db, 'reviews', reviewId))
        await setDoc(doc(db, 'users', owningUserId), {
            ...owningUser,
            numRatings: owningUser.numRatings - 1,
            totalRating: owningUser.totalRating - userRating
        })
    }

    if (deleted == true){
        return <></>
    }

    return (
        <div className='bg-base-200 rounded-lg p-4 mb-2'>
            <div className='flex'>
                <div className="avatar">
                    <div className="rounded-md w-10 h-10 m-1">
                        <Link to={`/profile/${postingUserId}`}>
                            <img src={postingUser.profilePic} />
                        </Link>
                    </div>
                    <Link to={`/profile/${postingUserId}`} className='flex'>
                        <p className='font-medium text-sm ml-2 my-auto'>
                            {postingUser.name}
                        </p>
                    </Link>
                </div>
                {/* <div className='font-light text-sm mx-2 my-3 hidden 2xl:block xl:block lg:block md:block sm:hidden'>
                    gave this item
                </div> */}
                <div className='flex font-bold text-lg ml-4'>
                    {editing ?
                        <>
                            {[...Array(5).keys()].map((n) => (
                                <>
                                    {n < userRating ?
                                        <RiStarSFill key={n} size={25} className='cursor-pointer my-auto' onClick = {() => setUserRating(n+1)}/>
                                        : <RiStarSLine key={n} size={25} className='cursor-pointer my-auto' onClick = {() => setUserRating(n+1)}/>
                                    }
                                </>
                            ))}
                        </>
                        :
                        <>
                            {[...Array(5).keys()].map((n) => (
                                <>
                                    {n < rating ?
                                        <RiStarSFill key={n} size={25} className='my-auto'/>
                                        : <RiStarSLine key={n} size={25} className='my-auto'/>
                                    }
                                </>
                            ))}
                        </>
                    }
                    
                </div>
                {
                    auth.currentUser != null ?
                        auth.currentUser.uid == postingUserId ?
                            <div className='ml-auto hidden 2xl:flex xl:flex lg:flex md:flex sm:flex'>
                                <button className="btn btn-square btn-ghost mr-2" onClick={() => {
                                    setEditing(!editing)
                                    setUserRating(rating)
                                    setUserReview(body)
                                }}>
                                    <FiEdit size={20} />
                                </button>

                                {editing ?
                                    <>
                                        <label for="my-modal" class="btn btn-square btn-ghost mr-2 modal-button">
                                            <FiTrash size={20} />
                                        </label>
                                        <input type="checkbox" id="my-modal" className="modal-toggle"/>
                                        <div className="modal">
                                            <div className="modal-box">
                                                <h3 className="font-bold text-lg text-white">Are you sure you want to delete your review?</h3>
                                                <div className="modal-action">
                                                    <label for="my-modal" className="btn btn-error text-white" onClick={deleteReview}>Yes, delete review!</label>
                                                    <label for="my-modal" className="btn btn-info text-white">No, keep my review!</label>
                                                </div>
                                            </div>
                                        </div>
                                        <button className='btn btn-primary' onClick = {() => {
                                            updateReview()
                                            setEditing(false)
                                        }}>Update</button>
                                    </>
                                    :
                                    <></>
                                }
                            </div>
                        : <></>
                    : <></>
                }
                
            </div>
            <div className='font-light text-lg mt-4 flex flex-col'>
                {
                    editing ? 
                    <textarea
                        required
                        minLength={1}
                        maxLength={500}
                        value={userReview}
                        onChange={(e) => setUserReview(e.target.value)}
                        placeholder="leave a review" 
                        id="description"
                        className="input textarea h-24 mt-4 textarea-primary textarea-bordered" 
                        type='text' 
                    ></textarea>
                    : <div className='ml-2'>{body}</div>
                }
            </div>
            <div className='flex mt-4'>
                {
                    auth.currentUser != null ?
                        auth.currentUser.uid == postingUserId ?
                            <div className='flex ml-auto 2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden'>
                                <button className="btn btn-square btn-ghost mr-2" onClick={() => {
                                    setEditing(!editing)
                                    setUserRating(rating)
                                    setUserReview(body)
                                }}>
                                    <FiEdit size={20} />
                                </button>

                                {editing ?
                                    <>
                                        <label for="my-modal-mobile" class="btn btn-square btn-ghost mr-2 modal-button">
                                            <FiTrash size={20} />
                                        </label>
                                        <input type="checkbox" id="my-modal-mobile" className="modal-toggle"/>
                                        <div className="modal">
                                            <div className="modal-box">
                                                <h3 className="font-bold text-lg text-white">Are you sure you want to delete your review?</h3>
                                                <div className="modal-action">
                                                    <label for="my-modal-mobile" className="btn btn-error text-white" onClick={deleteReview}>Yes, delete review!</label>
                                                    <label for="my-modal-mobile" className="btn btn-info text-white">No, keep my review!</label>
                                                </div>
                                            </div>
                                        </div>
                                        <button className='btn btn-primary' onClick = {() => {
                                            updateReview()
                                            setEditing(false)
                                        }}>Update</button>
                                    </>
                                    :
                                    <></>
                                }
                            </div>
                        : <></>
                    : <></>
                }
            </div>
        </div>
    )
}

export default Review