import { useState, useContext, useEffect } from 'react'
import CustomerContext from '../context/user/CustomerContext'
import CartItem from '../components/CartItem'
import { useNavigate } from 'react-router-dom'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../firebase.config'
import Spinner from '../components/Spinner'

function Cart() {

    const [promoCode, setPromoCode] = useState('')
    const [promoStatus, setPromoStatus] = useState(0)

    const { cart, discounts, cartSize, cartTotal, discountTotal, getTotal, getSize, getDiscount, addDiscount, removeDiscount } = useContext(CustomerContext)

    const navigate = useNavigate()

    useEffect(() => {
        document.title = 'Layolay | Cart'
    }, [])

    useEffect(() => {
       getTotal()
       getSize()
       getDiscount()
    }, [cart])

    const checkPromoCode = async () => {
        const fetchItem = async () => {
            const docRef = doc(db, 'promos', promoCode)
            const docSnap = await getDoc(docRef)
            
            if (docSnap.exists()){
                addDiscount(docSnap.data())
                getDiscount()
                setPromoCode('')
                setPromoStatus(2)
                // console.log('discounts', discounts)
            } else {
                setPromoCode('')
                setPromoStatus(1)
            }
        }
        fetchItem()
    }

    function addWorkDays(startDate, days) {
        if(isNaN(days)) {
            console.log("Value provided for \"days\" was not a number");
            return
        }
        if(!(startDate instanceof Date)) {
            console.log("Value provided for \"startDate\" was not a Date object");
            return
        }
        // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
        var dow = startDate.getDay();
        var daysToAdd = parseInt(days);
        // If the current day is Sunday add one day
        if (dow == 0)
            daysToAdd++;
        // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
        if (dow + daysToAdd >= 6) {
            //Subtract days in current working week from work days
            var remainingWorkDays = daysToAdd - (5 - dow);
            //Add current working week's weekend
            daysToAdd += 2;
            if (remainingWorkDays > 5) {
                //Add two days for each working week by calculating how many weeks are included
                daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
                //Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
                if (remainingWorkDays % 5 == 0)
                    daysToAdd -= 2;
            }
        }
        startDate.setDate(startDate.getDate() + daysToAdd);
        return startDate;
    }

    const earliestShipping = addWorkDays(new Date(), 3)
    const latestShipping = addWorkDays(new Date(), 5)

    return (
        <>
        { cart.length > 0 ?
            <div className='flex flex-col 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col p-4
            2xl:p-8 xl:p-8 lg:p-8 md:p-8 sm:p-8'>

                <div className='flex flex-col basis-2/3 mt-4 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 sm:mt-0'>
                    <div className='font-medium text-2xl mb-4 mx-auto 2xl:mx-0 xl:mx-0 lg:mx-0 md:mx-0 sm:mx-0'>
                        You have {cart.length == 0 ? 'no' : cart.length} item{cart.length == 1 ? '' : 's'} in your cart
                    </div>
                    {cart != null ? (cart.map((cartItem) => (
                        <CartItem key = {cartItem.cartItemId} cartItem = {cartItem} />
                    )))
                    : <></>}
                </div>
                
                <div className='flex flex-col basis-1/3 order-first 2xl:order-last xl:order-last lg:order-last md:order-first sm:order-first
                2xl:mt-16 xl:mt-16 lg:mt-16 2xl:ml-4 xl:ml-4 lg:ml-4 md:ml-0 sm:ml-0'>
                    <div className='flex flex-col rounded-2xl bg-base-200 p-8'>
                        <div className='font-bold text-2xl mx-auto mb-4'>
                            Order Summary
                        </div>
                        <div className='font-light mx-auto mb-4'>
                            <span className='font-bold'>Estimated delivery:</span> {earliestShipping.toLocaleString('default', { month: 'long'})} {earliestShipping.getDate()}&nbsp;-&nbsp;{latestShipping.toLocaleString('default', { month: 'long'})} {latestShipping.getDate()} 
                        </div>
                        <div className='flex text-xl my-4'>
                            <div>
                                Price of Item{cart.length == 1 ? '' : 's'}
                            </div>
                            <div className='ml-auto'>
                                ${Number(cartTotal).toFixed(2).replace(/[.,]0$/, '')}
                            </div>
                        </div>
                        {discounts.length > 0 ?
                            <div className='flex text-xl my-4'>
                                <div className='flex flex-col'>
                                    Discount{discounts.length > 1 ? 's' : ''}
                                    <div className='flex mt-2'>
                                        {
                                            discounts.map((discount) => (
                                                <div className='font-light text-xs ml-2 badge badge-primary hover:cursor-pointer hover:badge-error'
                                                onClick={() => {
                                                    removeDiscount(discount.code)
                                                    getDiscount()
                                                }}>
                                                    {discount.code}
                                                </div>
                                                // <div className='font-light text-xs ml-2 indicator'>
                                                //     {discount.code}
                                                // </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='ml-auto'>
                                    -${Number(discountTotal).toFixed(2).replace(/[.,]0$/, '')}
                                </div>
                            </div>
                            :
                            <></>
                        }
                        <div className='flex text-xl my-4'>
                            <div>
                                Shipping
                            </div>
                            <div className='ml-auto '>
                                ${Number(cartSize).toFixed(2).replace(/[.,]0$/, '')}
                            </div>
                        </div>
                        <div className='divide-y'>
                            <div className='flex text-xl my-4'>
                                <div>
                                    Tax
                                </div>
                                <div className='ml-auto '>
                                    ${Number((Math.max(cartTotal- discountTotal, 0) + cartSize)*0.0825).toFixed(2).replace(/[.,]0$/, '')}
                                </div>
                            </div>
                            <div className='flex text-xl my-8'>
                                <div>
                                    Total <span className='font-light text-sm'>({cart.length} item{cart.length == 1 ? '' : 's'})</span>
                                </div>
                                <div className='flex flex-col ml-auto'>
                                    <div className='ml-auto'>
                                        ${Number((Math.max(cartTotal- discountTotal, 0) + cartSize)*1.0825).toFixed(2).replace(/[.,]0$/, '')}
                                    </div>
                                    {discounts.length > 0 ?
                                        <div className='text-sm font-light ml-auto'>
                                            you save ${Number(Math.min(cartTotal, discountTotal)*1.0825).toFixed(2).replace(/[.,]0$/, '')}
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='flex mx-auto flex-col 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-row'>
                            <div className='text-lg my-auto mx-auto'>
                                Promo Code
                            </div>
                            <div className='flex mt-2'>
                                <input
                                    minLength={1}
                                    maxLength={10}
                                    value={promoCode}
                                    onChange={(e) => setPromoCode(e.target.value)}
                                    placeholder="Enter Code"
                                    id="description"
                                    className={`input textarea my-auto ml-4 textarea-bordered ${['textarea-primary', 'textarea-error', 'textarea-success'][promoStatus]}`}
                                    type='text'
                                ></input>
                                <button className='btn btn-base ml-4 my-auto' onClick={checkPromoCode}>
                                    Apply
                                </button>
                            </div>
                        </div>
                        <div>

                        </div>
                        <button className='btn btn-primary mx-auto mt-8' onClick={() => navigate('/checkout')}>Checkout</button>
                    </div>
                    <button className='btn btn-primary center mx-auto my-8 w-64' onClick = {() => navigate('/')}>
                        Keep Shopping
                    </button>
                </div>
            </div>
            : <div className='flex flex-col my-auto'>
                <div className='font-light text-4xl mx-auto mt-16'>
                    Your cart is empty
                </div>
                <button className='btn btn-primary mx-auto my-16 w-64' onClick = {() => navigate('/')}>
                    Find something cool
                </button>
            </div>
        }
    </>
    )
}

export default Cart
