import { Suspense, lazy } from "react"
import Navbar from "./components/Navbar"
import Footer from "./components/Footer"
import About from "./pages/About"
import Home from "./pages/Home"
import Item from './pages/Item'
import Profile from "./pages/Profile"
import Creators from "./pages/Creators"
import Favorites from './pages/Favorites'
import SignIn from "./pages/SignIn"
import SignUp from "./pages/SignUp"
import Settings from './pages/Settings'
import EditCreations from "./pages/EditCreations"
import CreatorTools from './pages/CreatorTools'
import OrderHistory from './pages/OrderHistory'
import Cart from './pages/Cart'
import Checkout from './pages/Checkout'
import ThankYou from './pages/ThankYou'
import Bugs from './pages/Bugs'
import Jobs from './pages/Jobs'
import Alerts from './pages/Alerts'
import Options from './pages/Options'
import ProhibitedItems from './pages/ProhibitedItems'
import ItemForm from "./pages/ItemForm"
import Messages from './pages/Messages'
import ForgotPassword from "./pages/ForgotPassword"
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import PrivateRoute from "./components/PrivateRoute"
import { CustomerProvider } from "./context/user/CustomerContext"

import React from "react";
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector"
import { SearchProvider } from "@elastic/react-search-ui"

// import DocMod from "./DocMod"

import "@elastic/react-search-ui-views/lib/styles/styles.css";

const connector = new AppSearchAPIConnector({
  searchKey: "search-g7t14zfcuxnaastj822ojfua",
  engineName: "layolay-search-engine",
  endpointBase: "https://layolay.ent.us-central1.gcp.cloud.es.io",
  cacheResponses: false
})
// import { Scrollbars } from 'react-custom-scrollbars-2'
// const Home = lazy(() => import("./pages/Home"))
// const Item = lazy(() => import("./pages/Item"))
// const Profile = lazy(() => import("./pages/Profile"))
// const Creators = lazy(() => import("./pages/Creators"))
// const Favorites = lazy(() => import("./pages/Favorites"))
// const SignIn = lazy(() => import("./pages/SignIn"))
// const SignUp = lazy(() => import("./pages/SignUp"))
// const Settings = lazy(() => import("./pages/Settings"))
// const EditCreations = lazy(() => import("./pages/EditCreations"))
// const CreatorTools = lazy(() => import("./pages/CreatorTools"))
// const OrderHistory = lazy(() => import("./pages/OrderHistory"))
// const Cart = lazy(() => import("./pages/Cart"))
// const Checkout = lazy(() => import("./pages/Checkout"))
// const ThankYou = lazy(() => import("./pages/ThankYou"))
// const Bugs = lazy(() => import("./pages/Bugs"))
// const Jobs = lazy(() => import("./pages/Jobs"))
// const Alerts = lazy(() => import("./pages/Alerts"))
// const Options = lazy(() => import("./pages/Options"))
// const ItemForm = lazy(() => import("./pages/ItemForm"))
// const ForgotPassword = lazy(() => import("./pages/ForgotPassword"))

function App() {
  return (
    <Router>
      <SearchProvider config={{ apiConnector: connector }}>
        <CustomerProvider>
            <div className='flex flex-col h-screen'>
              <Navbar />
              {/* <Scrollbars style={{ width: '100%', height: '100%' }}> */}
                <div className='flex flex-col justify-between h-full'>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                      {/* <Route path='/documentmodification' element={<DocMod />} /> */}
                      <Route path='/home' element={<Home />} />
                      <Route path='/' element={<Home />} />
                      <Route path='/item/:itemId' element={<Item />} />
                      <Route path='/order-options' element={<Options />} />
                      <Route path='/prohibited-items' element={<ProhibitedItems />} />
                      <Route path='/about' element={<About />} />
                      <Route path='/profile/:userId' element={<Profile />} />
                      <Route path='/messages' element={<Messages />} />
                      <Route path='/favorites' element={<Favorites />} />
                      <Route path='/creators' element={<Creators />} />
                      <Route path='/settings' element={<Settings />} />
                      <Route path='/cart' element={<Cart />} />
                      <Route path='/checkout' element={<Checkout />} />
                      <Route path='/thank-you' element={<ThankYou />} />
                      <Route path='/alerts' element={<Alerts />} />
                      <Route path='/sign-in' element={<SignIn />} />
                      <Route path='/sign-up' element={<SignUp />} />
                      <Route path='/item-upload' element={<ItemForm />} />
                      <Route path='/order-history' element={<OrderHistory />} />
                      <Route path='/creator-tools' element={<CreatorTools />} />
                      <Route path='/edit-creations' element={<EditCreations />} />
                      <Route path='/forgot-password' element={<ForgotPassword />} />
                      <Route path='/jobs' element={<Jobs />} />
                      <Route path='/bugs' element={<Bugs />} />
                    </Routes>
                  </Suspense>
                  <Footer />
                </div>
              {/* </Scrollbars> */}
            </div>
        </CustomerProvider>
      </SearchProvider>
    </Router>
  );
}

export default App;
