import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomerContext from '../context/user/CustomerContext'
import { updateDoc, doc, addDoc, getDocs, where, collection, query, setDoc, serverTimestamp, increment, deleteDoc } from 'firebase/firestore'
import { db } from '../firebase.config'
import { getAuth } from 'firebase/auth'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Cookies } from 'react-cookie'

function Checkout() {

    const auth = getAuth()
    const navigate = useNavigate()

    const [orderId, setOrderId] = useState(null)

    const { cart, setCart, cartSize, cartTotal, getSize, getTotal, discounts, discountTotal, getDiscount, setDiscounts, removeDiscount } = useContext(CustomerContext)

    const [items, setItems] = useState([])

    let total = 0

    useEffect(() => {
        document.title = 'Layolay | Checkout'
    }, [])

    useEffect(() => {
        getTotal()
        getSize()
        getDiscount()
    }, [cart])

    function addWorkDays(startDate, days) {
        if(isNaN(days)) {
            console.log("Value provided for \"days\" was not a number");
            return
        }
        if(!(startDate instanceof Date)) {
            console.log("Value provided for \"startDate\" was not a Date object");
            return
        }
        // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
        var dow = startDate.getDay();
        var daysToAdd = parseInt(days);
        // If the current day is Sunday add one day
        if (dow == 0)
            daysToAdd++;
        // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
        if (dow + daysToAdd >= 6) {
            //Subtract days in current working week from work days
            var remainingWorkDays = daysToAdd - (5 - dow);
            //Add current working week's weekend
            daysToAdd += 2;
            if (remainingWorkDays > 5) {
                //Add two days for each working week by calculating how many weeks are included
                daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
                //Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
                if (remainingWorkDays % 5 == 0)
                    daysToAdd -= 2;
            }
        }
        startDate.setDate(startDate.getDate() + daysToAdd);
        return startDate;
    }

    const earliestShipping = addWorkDays(new Date(), 3)
    const latestShipping = addWorkDays(new Date(), 5)

    // const stateNames = [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ];

    return (
            <div className='flex flex-col bg-base-200 rounded-2xl m-4 p-8 2xl:w-[30%] xl:w-[40%] lg:w-[50%] md:w-[60%]
            2xl:mx-auto xl:mx-auto lg:mx-auto md:mx-auto 2xl:my-8 xl:my-8 lg:my-8 md:my-8'>
                <div className='font-bold text-2xl mx-auto mb-4'>
                    Order Summary
                </div>
                <div className='font-light mx-auto mb-4'>
                    <span className='font-bold'>Estimated delivery:</span> {earliestShipping.toLocaleString('default', { month: 'long'})} {earliestShipping.getDate()}&nbsp;-&nbsp;{latestShipping.toLocaleString('default', { month: 'long'})} {latestShipping.getDate()} 
                </div>
                <div className='flex text-xl my-4'>
                    <div>
                        Price of Item{cart.length == 1 ? '' : 's'}
                    </div>
                    <div className='ml-auto'>
                        ${Number(cartTotal).toFixed(2).replace(/[.,]0$/, '')}
                    </div>
                </div>
                {discounts.length > 0 ?
                    <div className='flex text-xl my-4'>
                        <div className='flex flex-col'>
                            Discount{discounts.length > 1 ? 's' : ''}
                            <div className='flex mt-2'>
                                {
                                    discounts.map((discount) => (
                                        <div className='font-light text-xs ml-2 badge badge-primary hover:cursor-pointer hover:badge-error'
                                        onClick={() => {
                                            removeDiscount(discount.code)
                                            getDiscount()
                                        }}>
                                            {discount.code}
                                        </div>
                                        // <div className='font-light text-xs ml-2 indicator'>
                                        //     {discount.code}
                                        // </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='ml-auto'>
                            -${Number(discountTotal).toFixed(2).replace(/[.,]0$/, '')}
                        </div>
                    </div>
                    :
                    <></>
                }
                <div className='flex text-xl my-4'>
                    <div>
                        Shipping
                    </div>
                    <div className='ml-auto '>
                        ${Number(cartSize).toFixed(2).replace(/[.,]0$/, '')}
                    </div>
                </div>
                <div className='divide-y'>
                    <div className='flex text-xl my-4'>
                        <div>
                            Tax
                        </div>
                        <div className='ml-auto '>
                            ${Number((Math.max(cartTotal- discountTotal, 0) + cartSize)*0.0825).toFixed(2).replace(/[.,]0$/, '')}
                        </div>
                    </div>
                    <div className='flex text-xl my-8'>
                        <div>
                            Total <span className='font-light text-sm'>({cart.length} item{cart.length == 1 ? '' : 's'})</span>
                        </div>
                        <div className='flex flex-col ml-auto'>
                            <div className='ml-auto'>
                                ${Number((Math.max(cartTotal- discountTotal, 0) + cartSize)*1.0825).toFixed(2).replace(/[.,]0$/, '')}
                            </div>
                            {discounts.length > 0 ?
                                <div className='text-sm font-light ml-auto'>
                                    you save ${Number(Math.min(cartTotal, discountTotal)*1.0825).toFixed(2).replace(/[.,]0$/, '')}
                                </div>
                                :
                                <></>
                            }
                            
                        </div>
                    </div>
                </div>
                <div className='mx-auto font-lg text-lg my-2'>
                    Select Payment Method
                </div>
                <div className='mx-auto mt-4 w-full 2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[90%] sm:w-full'>
                    <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID}}>
                        <PayPalButtons 
                            style={{ layout: "vertical", color: "blue"}}
                            createOrder={(data, actions) => {
                                // QUERY FOR FINAL CART VALUE
                                // TODO

                                return actions.order.create({
                                    purchase_units: [
                                        {
                                            amount: {
                                                currency_code: 'USD',
                                                value: ((Number(Math.max(cartTotal- discountTotal, 0)) + Number(cartSize))*1.0825).toFixed(2).replace(/[.,]0$/, ''),
                                            },
                                        },
                                    ],
                                })
                                .then((orderId) => {
                                    setOrderId(orderId)
                                    return orderId;
                                });
                            }}
                            onApprove={(data, actions) => {
                                return actions.order.capture().then((details) => {
                                    console.log('Capture result', details);
                                    const addOrderToDatabase = async() => {
                                        if (auth.currentUser != null){
                                            // Create the order
                                            await addDoc(collection(db, 'orders'), {
                                                name: details.payer.name,
                                                email: details.payer.email_address,
                                                address: details.purchase_units[0].shipping.address,
                                                items: cart,
                                                cost: details.purchase_units[0].amount.value,
                                                status: 'Order Received',
                                                timestamp: serverTimestamp(),
                                                userId: auth.currentUser.uid
                                            })

                                            // await addDoc(collection(db, 'mail'), {
                                            //     to: details.payer.email_address,
                                            //     message: {
                                            //     subject: 'Layolay - Thank you for your purchase',
                                            //     text: `${details.payer.name}, thank you for shoping at layolay!`,
                                            //     html: '',
                                            //     }
                                            // })
                                            
                                            // Get reference
                                            const privateRef = collection(db, 'private')
                                                                                        
                                            // Create a query
                                            const q = query(
                                                privateRef,
                                                where('userId', '==', auth.currentUser.uid)
                                            )

                                            // Execute query
                                            const querySnap = await getDocs(q)

                                            let privateDetails = []
                                            querySnap.forEach((doc) => {
                                                return privateDetails.push({
                                                    id: doc.id,
                                                    data: doc.data()
                                                })
                                            })

                                            const privateOrderRef = doc(db, 'private', privateDetails[0].id)

                                            await updateDoc(privateOrderRef, {
                                                cart: []
                                            })

                                        } else {
                                            // Create the order
                                            await addDoc(collection(db, 'orders'), {
                                                name: details.payer.name,
                                                email: details.payer.email_address,
                                                address: details.purchase_units[0].shipping.address,
                                                items: cart,
                                                cost: details.purchase_units[0].amount.value,
                                                status: 'Order Received',
                                                timestamp: serverTimestamp(),
                                                userId: 'guest'
                                            })

                                            // Store the order in a cookie for 1 hour to add to new user sign up
                                            // const cookies = new Cookies();
                                            // const recentOrderCookie = (cookies.get('recentOrderCookie') == undefined) ? [] : cookies.get('recentOrderCookie')
                                            // newRecentOrderCookie = [...recentOrderCookie, order]
                                            // cookies.set('cartCookie', JSON.stringify(newCart), {path: '/'});
                                        }
                                    }

                                    const updateEarnings = async() => {
                                        cart.forEach((cartItem) => {
                                            const privateRef = doc(db, 'private', cartItem.userId)
                                            updateDoc(privateRef, {
                                                earnings: increment(Number(cartItem.prices[cartItem.options.size]) * Number(cartItem.quantity) * 0.1),
                                                allTimeRevenue: increment(Number(cartItem.prices[cartItem.options.size]) * Number(cartItem.quantity) * 0.1)
                                            })

                                            const userRef = doc(db, 'users', cartItem.userId)
                                            updateDoc(userRef, {
                                                numSales: increment(1)
                                            })

                                            const itemRef = doc(db, 'items', cartItem.itemId)
                                            updateDoc(itemRef, {
                                                numSales: increment(1)
                                            })
                                        })
                                    }

                                    const updatePromos = async() => {
                                        discounts.forEach((discount) => {
                                            const promoRef = doc(db, 'promos', discount.code)
                                            if (discount.uses > 1){
                                                updateDoc(promoRef, {
                                                    uses: increment(-1),
                                                })
                                            } else {
                                                deleteDoc(doc(db, "promos", discount.code))
                                            }
                                        })
                                    }

                                    addOrderToDatabase()
                                    updateEarnings()
                                    updatePromos()

                                    setCart([])
                                    setDiscounts([])

                                    navigate('/thank-you')
                                });
                            }}
                            onError={(data, actions) => {
                                console.log('There was an error with your payment.')
                            }}
                        />
                    </PayPalScriptProvider>
                </div>
            </div>
    )
}

export default Checkout