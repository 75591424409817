import { FaFacebook, FaTwitter, FaYoutube, FaInstagram, FaTiktok, FaTwitch } from 'react-icons/fa'
import { AiFillInstagram } from 'react-icons/ai'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <footer className="p-10 footer bg-base-200 shadow-lg">
        <div>
            <span className="footer-title">Services</span> 
            <Link to='' className="link link-hover">3D Printing</Link>
            <Link to='/order-options' className="link link-hover">Order Options</Link>
            <Link to='/creator-tools' className="link link-hover">Creator Link</Link>
        </div> 
        <div>
            <span className="footer-title">Company</span> 
            <Link to='/about' className="link link-hover">About Us</Link> 
            <Link to='/about' className="link link-hover">Contact</Link> 
            <Link to='/jobs' className="link link-hover">Jobs</Link>
            <Link to='/bugs' className="link link-hover">Report a Bug </Link>
        </div> 
        <div>
            <span className="footer-title">Social</span> 
            <div className="flex">
                <a className='btn btn-ghost btn-circle' href='https://twitter.com/LayolayOfficial' target="_blank" rel="noopener noreferrer">
                    <FaTwitter size={30} />
                </a> 
                <a className='btn btn-ghost btn-circle' href='https://www.instagram.com/layolayofficial/' target="_blank" rel="noopener noreferrer">
                    <AiFillInstagram size={30} />
                </a>
                <a className='btn btn-ghost btn-circle' href='https://www.facebook.com/Layolay-104697222152761' target="_blank" rel="noopener noreferrer">
                    <FaFacebook size={30} />
                </a> 
            </div>
            <div className='flex'>
                <a className='btn btn-ghost btn-circle' href='https://www.youtube.com/channel/UCQYE870Mg3tEbWgVzM4cGsw' target="_blank" rel="noopener noreferrer">
                    <FaYoutube size={30} />
                </a>
                <a className='btn btn-ghost btn-circle' href='https://www.tiktok.com/@layolayofficial' target="_blank" rel="noopener noreferrer">
                    <FaTiktok size={30} />
                </a>
                <a className='btn btn-ghost btn-circle' href='https://www.twitch.tv/layolayofficial' target="_blank" rel="noopener noreferrer">
                    <FaTwitch size={30} />
                </a>
            </div>
        </div>
        </footer>
    )
}

export default Footer
