import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect, useRef } from 'react'
import { doc, getDoc, getDocs, collection, where, query, documentId } from 'firebase/firestore'
import { db } from '../firebase.config'
import ItemCard from '../components/ItemCard'

function Favorites() {

    const navigate = useNavigate()

    const [privateDetails, setPrivateDetails] = useState(null)
    const [items, setItems] = useState([])

    const isMounted = useRef(true)
    const auth = getAuth()

    useEffect(() => {
        document.title = 'Layolay | Favorites'
    }, [])

    useEffect(() => {

        const fetchPrivate = async (userId) => {
            // Get reference
            const privateRef = collection(db, 'private')
            
            // Create a query
            const q = query(
                privateRef,
                where('userId', '==', userId)
            )

            // Execute query
            const querySnap = await getDocs(q)
            
            let privateDetails = []
            querySnap.forEach((doc) => {
                return privateDetails.push({
                    id: doc.id,
                    data: doc.data()
                })
            })
            setPrivateDetails(privateDetails[0].data)
        }

        if(isMounted){
            onAuthStateChanged(auth, (user) => {
                if(user) {
                    fetchPrivate(auth.currentUser.uid)
                } else {
                    navigate('/sign-in')
                }
            })
        }
        
        return () => {
            isMounted.current = false
        }

    }, [isMounted])

    useEffect(() => {
        const fetchItems = async () => {
            // Get reference
            const itemRef = collection(db, 'items')
                    
            // Create a query
            const q = query(
                itemRef,
                where(documentId(), 'in', privateDetails.favorites)
            )

            // Execute query
            const querySnap = await getDocs(q)

            let items = []
            querySnap.forEach((doc) => {
                return items.push({
                    id: doc.id,
                    data: doc.data()
                })
            })

            setItems(items)
        }

        if (privateDetails != null){
            fetchItems()
        }

    }, [privateDetails])

    if (privateDetails == null){
        return <>Loading</>
    }

    if (items == []){
        return <>Loading</>
    }

  return (
    <div className='flex flex-col mx-4 mt-4 2xl:w-[70%] xl:w-[70%] lg:w-[80%] md:w-[80%] s:w-[80%]
    2xl:mx-auto xl:mx-auto lg:mx-auto md:mx-auto sm:mx-auto 2xl:my-8 xl:my-8 lg:my-8 md:my-8 sm:my-8'>
        <div className='flex flex-col bg-base-200 rounded-lg mx-auto p-8'>
            <div className='font-bold text-2xl mx-auto'>
            Your Favorite Items
            </div>
            <div className='font-light text-lg mx-auto mt-4'>
            You will be notified when these items go on sale or have special deals.
            </div>
        </div>
        <div className='grid 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 s:grid-cols-1 gap-4 p-4 
        2xl:p-8 xl:p-8 lg:p-8 md:p-8 sm:p-8'>
            {items.map((item) => (
                <ItemCard key={item.id} id={item.id} item={item.data} className='bg-base-300'/>
            ))}
        </div>
    </div>
  )
}

export default Favorites

{/* <div className='flex flex-col bg-base-300 rounded-lg p-4'>
    <img className='rounded-lg hover:cursor-pointer' src={item.data.imgUrls[0]} onClick={() => {navigate('/item/'+item.id)}} />
    <div className='font-bold text-lg mt-2 mx-auto'>
        {item.data.name}
    </div>
    <div className='font-light text-sm mt-2 mx-auto hover:cursor-pointer' onClick={() => navigate('/profile/'+item.data.userId)}>
        Visit Creator
    </div>
</div> */}