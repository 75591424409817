import { useState, useEffect, useRef } from "react"
import { useParams, Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom"
import { updateDoc, doc, addDoc, setDoc, getDoc, collection, getDocs, query, orderBy, where, limit, startAfter, FieldValue } from 'firebase/firestore'
import { Timestamp } from 'firebase/firestore'
import { db } from '../firebase.config'
import { getAuth, updateProfile } from 'firebase/auth'
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage'
import Anime, { anime } from 'react-anime'
import { v4 as uuidv4} from 'uuid'
import Spinner from "../components/Spinner"
import ItemCard from "../components/ItemCard"
import ProfileReviewCard from '../components/ProfileReviewCard'
import { FaFacebook, FaTwitter, FaYoutube, FaCog, FaPlus } from 'react-icons/fa'
import { FiSend, FiUpload, FiEdit, FiPlus } from 'react-icons/fi'
import { BiBookmarkHeart, BiHeart, BiMessage } from 'react-icons/bi'
import { RiStarSLine, RiStarSFill, RiInstagramFill } from 'react-icons/ri'
import { BsFillCaretLeftFill, BsFillCaretRightFill, BsImage } from 'react-icons/bs'
import Resizer from "react-image-file-resizer"
import { TiMessages } from 'react-icons/ti'

const default1 = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/defaultProfilePics%2Fdefault-1.png?alt=media&token=22d99789-e34a-4fff-b396-ce9dfa413c57'
const default2 = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/defaultProfilePics%2Fdefault-2.png?alt=media&token=8dae40c6-eea4-4fd9-914f-5614280a1525'
const default3 = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/defaultProfilePics%2Fdefault-3.png?alt=media&token=6b86b83c-4db6-4999-8108-ac6ee417399e'
const default4 = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/defaultProfilePics%2Fdefault-4.png?alt=media&token=15fec291-ff71-44e2-9837-0dec55d5037c'
const default5 = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/defaultProfilePics%2Fdefault-5.png?alt=media&token=1f82522a-4acb-438a-ad4d-52ed6f8f19ef'
const default6 = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/defaultProfilePics%2Fdefault-6.png?alt=media&token=b5a70ab7-4700-47d1-8832-69c4d8b224c4'
const default7 = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/defaultProfilePics%2Fdefault-7.png?alt=media&token=4fece556-a389-4b6b-99cb-1ef1a9a92861'
const default8 = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/defaultProfilePics%2Fdefault-8.png?alt=media&token=6b200e0c-17a4-4e64-b0ba-a7a95d9424b6'
const default9 = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/defaultProfilePics%2Fdefault-9.png?alt=media&token=0968aef4-6d26-4683-9058-ddfc9abf8351'

function Profile() {
    const params = useParams()
    //console.log(FieldValue)
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)
    const [userCopy, setUserCopy] = useState({})

    const [profilePicPreviewURL, setProfilePicPreviewURL] = useState([])
    const [isDefaultProfilePicSelected, setIsDefaultProfilePicSelected] = useState(false)
    const [defaultProfilePic, setDefaultProfilePic] = useState(false)
    const [bannerPicPreviewURL, setBannerPicPreviewURL] = useState([])
    const [triggerDelete, setTriggerDelete] = useState(false)
    const [changeDetails, setChangeDetails] = useState(false)
    const [userQueryTrigger, setUserQueryTrigger] = useState(false)
    const [newProfilePicReference, setNewProfilePicReference] = useState(null)
    const [newBannerPicReference, setNewBannerPicReference] = useState(null)
    const [userMatchesLoggedInUser, setUserMatchesLoggedInUser] = useState(false)
    const [userAllowsInfo, setUserAllowsInfo] = useState(false)
    const [userAllowsSocials, setUserAllowsSocials] = useState(false)
    const [resizedProfilePic, setResizedProfilePic] = useState('')
    const [resizedBannerPic, setResizedBannerPic] = useState('')

    const [posts, setPosts] = useState([])
    const [currentPost, setCurrentPost] = useState(0)
    
    const [reviews, setReviews] = useState([])
    const [lastFetchedReview, setLastFetchedReview] = useState(null)
    const [moreReviewsToLoad, setMoreReviewsToLoad] = useState(true)

    const auth = getAuth()

    const navigate = useNavigate()
    const onLogout = () => {
        auth.signOut()
        navigate('/home')
    }

    const fetchReviews = async() => {
        try {
            // Get reference
            const reviewRef = collection(db, 'reviews')

            // Create a query
            const q = query(
                reviewRef,
                where('owningUserId', '==', params.userId),
                limit(5)
            )

            // Execute query
            const querySnap = await getDocs(q)
            const lastVisible = querySnap.docs[querySnap.docs.length-1]
            setLastFetchedReview(lastVisible)
            
            let reviews = []
            querySnap.forEach((doc) => {
                return reviews.push({
                    id: doc.id,
                    data: doc.data()
                })
            })

            if(reviews.length < 5){
                setMoreReviewsToLoad(false)
            }
            setReviews(reviews)

        } catch(error) {
            console.log(error)
        }
    }

    const fetchPosts = async() => {
        try {
            // Get reference
            const postsRef = collection(db, 'posts')

            // Create a query
            const q = query(
                postsRef,
                where('userId', '==', params.userId),
            )

            // Execute query
            const querySnap = await getDocs(q)
            
            let posts = []
            querySnap.forEach((doc) => {
                return posts.push(
                    doc.data()
                )
            })

            // SSort by timestamp (newest to oldest)
            posts.sort((post1, post2) => {return post2.timestamp - post1.timestamp})

            setPosts(posts)

        } catch(error) {
            console.log(error)
        }
    }

    useEffect(() => {

        setCurrentPost(0)
        setLoading(true)
        const fetchUser = async () => {
            const docRef = doc(db, 'users', params.userId)
            const docSnap = await getDoc(docRef)

            //console.log(docSnap.data())
            if(docSnap.exists()){
                setUser(docSnap.data())
            }

        }

        const getItems = async () => {
            try {
                // Get reference
                const itemRef = collection(db, 'items')
    
                // Create a query
                const q = query(
                    itemRef,
                    where('userId', '==', params.userId),
                    where('hidden', '==', false)
                )
                
                // Execute query
                const querySnap = await getDocs(q)

                let items = []
                querySnap.forEach((doc) => {
                    return items.push({
                        id: doc.id,
                        data: doc.data()
                    })
                })

                setItems(items)

            } catch(error) {
                console.log(error)
            }
        }

        getItems()
        fetchUser()
        setLoading(false)

    }, [params.userId, userQueryTrigger])

    useEffect(() => {
        if(user != null){
            document.title = 'Layolay | ' + user.name
            setUserAllowsInfo(user.settings.experience.permission || user.settings.softwareUsed.permission || user.settings.profession.permission)
            setUserAllowsSocials(user.settings.twitter.permission || user.settings.youtube.permission || user.settings.facebook.permission || user.settings.instagram.permission || user.settings.email.permission)
        }
        setUserCopy({
            ...user,
        })
        setUserMatchesLoggedInUser(auth.currentUser === null ? false : (auth.currentUser.uid === params.userId))
        fetchReviews()
        fetchPosts()
    }, [user])

    useEffect(() => {

        if(triggerDelete && (isDefaultProfilePicSelected == false)){
            const deleteObjectPic = async () => {
                const storage = getStorage()
                const deleteRef = ref(storage, 'profilePics/' + userCopy.profilePicReference)
                await deleteObject(deleteRef).then(() => {
                    console.log('Old profile pic deletion successful')
                }).catch((error) => {
                    console.log('Failed to delete old profile pic')
                })
            }
            const updateUser = async () => {
                const userRef = doc(db, 'users', auth.currentUser.uid)
                await updateDoc(userRef, {
                    profilePicReference: newProfilePicReference
                })
            }
            deleteObjectPic()
            updateUser()
            setTriggerDelete(false)
            setUserQueryTrigger(!userQueryTrigger)
        }

    }, [newProfilePicReference])

    useEffect(() => {

        if(triggerDelete){
            const deleteObjectPic = async () => {
                const storage = getStorage()
                const deleteRef = ref(storage, 'bannerPics/' + userCopy.bannerPicReference)
                await deleteObject(deleteRef).then(() => {
                    console.log('Old profile pic deletion successful')
                }).catch((error) => {
                    console.log('Failed to delete old profile pic')
                })
            }
            const updateUser = async () => {
                const userRef = doc(db, 'users', auth.currentUser.uid)
                await updateDoc(userRef, {
                    bannerPicReference: newBannerPicReference
                })
            }
            deleteObjectPic()
            updateUser()
            setTriggerDelete(false)
            setUserQueryTrigger(!userQueryTrigger)
        }
    
    }, [newBannerPicReference])


    const createMessage = async (recieverID, senderID) => {
        const id = senderID > recieverID ? `${senderID + recieverID}` : `${recieverID + senderID}`
        try {
              await setDoc(doc(db, "messages", id), {
                userArray: [senderID, recieverID],
                lastMessage: 'No Messages Yet',
                lastMessageSender: senderID,
                read: false,
                timestamp: Timestamp.fromDate(new Date())
              })
              //Use this code to start the chat later
            //   await addDoc(collection(db, "messages", id, "message"), {
            //     text: 'big'
            //   })
        } catch (error) {
            console.log(error)
        }
    }

    const getProfilePicPreview = (e) => {
        const files = e.target.files
        const file = e.target.files[0]
        if(!file){
            return
        }
        
        let images = []
        Array.from(files).forEach((file) => {
            return images.push({
                url: URL.createObjectURL(file)
            })
        })

        setProfilePicPreviewURL(images)
    }
    
    const resizeFile = (image, imageType, maxWidth, maxHeight, quality) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                image,
                maxWidth,
                maxHeight,
                imageType,
                quality,
                0,
                (uri) => {
                    resolve(uri)
                },
                "file"
            )
        })
    }

    const onProfilePicChange = (e) => {
        setIsDefaultProfilePicSelected(false)
        //Files
        if(e.target.files){
            getProfilePicPreview(e)
            console.log('setting new file', e.target.files)
            let resizedImages = []
            Array.from(e.target.files).forEach(async (file) => {
                try {
                    const image = resizeFile(file, file.type.toString().substring(6).toUpperCase(), 1000, 1000, 100).then(resizedImage => resizedImages.push(resizedImage))
                    setResizedProfilePic(resizedImages)
                } catch (error) {
                    console.log('Image Resize unsuccessful', error)
                }
            })
            setUserCopy((prevState) => ({
                ...prevState,
                profilePic: e.target.files
            }))
        }
    }

    const onBannerPicChange = (e) => {
        //Files
        if(e.target.files){
            getBannerPicPreview(e)
            let resizedImages = []
            Array.from(e.target.files).forEach(async (file) => {
                try {
                    const image = resizeFile(file, file.type.toString().substring(6).toUpperCase(), 1500, 1500, 100).then(resizedImage => resizedImages.push(resizedImage))
                    setResizedBannerPic(resizedImages)
                } catch (error) {
                    console.log('Image Resize unsuccessful', error)
                }
            })
            setUserCopy((prevState) => ({
                ...prevState,
                bannerPic: e.target.files
            }))
        }
    }

    const getBannerPicPreview = (e) => {
        const files = e.target.files
        const file = e.target.files[0]
        if(!file){
            return
        }
        
        let images = []
        Array.from(files).forEach((file) => {
            return images.push({
                url: URL.createObjectURL(file)
            })
        })

        setBannerPicPreviewURL(images)
    }

    const onChange = (e) => {
        setUserCopy((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
        }))
    }

    const onSubmit = async (e) => {

        //Store image in firebase
        const storeImage = async (image) => {
            return new Promise((resolve, reject) => {
                const storage = getStorage()
                const filename = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`
                const storageRef = ref(storage, 'profilePics/' + filename)
                setNewProfilePicReference(filename)
                setTriggerDelete(true)
                const uploadTask = uploadBytesResumable(storageRef, image)

                uploadTask.on('state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        console.log('Upload is ' + progress + '% done')
                        switch (snapshot.state){
                            case 'paused':
                                console.log('Upload is paused')
                                break
                            case 'running':
                                console.log('Upload is running')
                                break
                        }
                    },
                    (error) => {
                        reject(error)
                    },
                    () => {
                        // Handle successful uploads on complete
                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            resolve(downloadURL)
                        })
                    }
                )
            })
        }

        //Store image in firebase
        const storeBanner = async (image) => {
            return new Promise((resolve, reject) => {
                const storage = getStorage()
                const filename = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`
                const storageRef = ref(storage, 'bannerPics/' + filename)
                setNewBannerPicReference(filename)
                setTriggerDelete(true)
                const uploadTask = uploadBytesResumable(storageRef, image)

                uploadTask.on('state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        console.log('Upload is ' + progress + '% done')
                        switch (snapshot.state){
                            case 'paused':
                                console.log('Upload is paused')
                                break
                            case 'running':
                                console.log('Upload is running')
                                break
                        }
                    },
                    (error) => {
                        reject(error)
                    },
                    () => {
                        // Handle successful uploads on complete
                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            resolve(downloadURL)
                        })
                    }
                )
            })
        }

        let imgUrl = []
        if(isDefaultProfilePicSelected){
            imgUrl[0] = defaultProfilePic
        } else if (typeof userCopy.profilePic !== 'string'){
            imgUrl = await Promise.all(
                // [...userCopy.profilePic].map((image) => 
                //     storeImage(image)
                // )
                [...resizedProfilePic].map((image) => 
                    storeImage(image)
                )
            ).catch(() => {
                console.log('image upload failed')
                return
            })
        }

        let bannerUrl = []
        if (typeof userCopy.bannerPic !== 'string'){
            bannerUrl = await Promise.all(
                // [...userCopy.bannerPic].map((image) => 
                //     storeBanner(image)
                // )
                [...resizedBannerPic].map((image) => 
                storeBanner(image)
                )   
            ).catch(() => {
                console.log('banner upload failed')
                return
            })
        }

        const userRef = doc(db, 'users', auth.currentUser.uid)
        if (imgUrl.length == 0 && bannerUrl.length == 0){
            await updateDoc(userRef, {
                name: userCopy.name,
                bio: userCopy.bio,
            })
            await updateProfile(auth.currentUser, {
                displayName: userCopy.name,
            })
        } else if(imgUrl.length == 0) {
            await updateDoc(userRef, {
                name: userCopy.name,
                bio: userCopy.bio,
                bannerPic: bannerUrl[0]
            })
            await updateProfile(auth.currentUser, {
                displayName: userCopy.name,
                bio: userCopy.bio,
            })
        } else if(bannerUrl.length == 0) {
            setUser((prevState) => ({
                ...prevState,
                profilePic: imgUrl[0]
            }))
            await updateDoc(userRef, {
                name: userCopy.name,
                bio: userCopy.bio,
                profilePic: imgUrl[0],
            })
            //console.log('Inside Correct updater')
            await updateProfile(auth.currentUser, {
                displayName: userCopy.name,
                bio: userCopy.bio,
                photoURL: imgUrl[0]
            })
        } else {
            setUser((prevState) => ({
                ...prevState,
                profilePic: imgUrl[0]
            }))
            // Change profilePic from the literal file to the link that storeImage returns
            await updateDoc(userRef, {
                name: userCopy.name,
                bio: userCopy.bio,
                profilePic: imgUrl[0],
                bannerPic: bannerUrl[0]
            })

            await updateProfile(auth.currentUser, {
                displayName: userCopy.name,
                bio: userCopy.bio,
                photoURL: imgUrl[0]
            })
        }

    }

    if (loading) {
        return(
            <div className='mx-auto my-auto'>
                <Spinner />
            </div>
        )
    }

    //console.log(auth.currentUser.displayName != null ? auth.currentUser.displayName : '')

    return (
        <div className='flex flex-col justify-between m-4 mt-4 mx-4'>
            {changeDetails ? 
                <> 
                    <label for='bannerPic' className='z-0'>
                        <div className="bg-no-repeat z-0 border-r-2 border-t-2 border-l-2 border-primary hover:cursor-pointer bg-cover rounded-tl-lg rounded-tr-lg
                        h-32 -mb-16 2xl:h-64 xl:h-64 lg:h-64 md:h-64 sm:h-64 2xl:-mb-32 xl:-mb-32 lg:-mb-32 md:-mb-32 sm:-mb-32 gradient-mask-b-0" 
                        style={{backgroundImage: `url(${(bannerPicPreviewURL.length === 0) ? 
                            userCopy.bannerPic : bannerPicPreviewURL[0].url})`}} 
                        ></div>
                    </label>
                    <input 
                        style={{display: 'none'}}
                        placeholder="banner" 
                        id="bannerPic"
                        hidden={!changeDetails}
                        className=""
                        onChange={onBannerPicChange}
                        max='1'
                        accept=".jpg,.png,.gif,.jpeg"
                        type="file" 
                        multiple
                        required
                    />
                </>
                :
                <div className="bg-no-repeat bg-cover z-0 rounded-tl-lg rounded-tr-lg h-32 -mb-16 2xl:h-64 xl:h-64 lg:h-64 md:h-64 sm:h-64
                2xl:-mb-32 xl:-mb-32 lg:-mb-32 md:-mb-32 sm:-mb-32 gradient-mask-b-0"
                    style={{backgroundImage: `url(${(bannerPicPreviewURL.length === 0) ? 
                    userCopy.bannerPic : bannerPicPreviewURL[0].url})`}} 
                />
            }
            <div className='flex flex-col m-2 justift-between z-10 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col'>
                <div className='hidden flex-col justify-center items-center basis-1/3 lg:mt-16 z-10 2xl:flex xl:flex lg:flex md:flex sm:hidden'>
                    {(userAllowsInfo) ? 
                        <h6 className='font-bold text-xl mb-4 mt-6'>Information</h6> 
                        :
                        <>
                        {changeDetails ? 
                            <div className='flex flex-col'>
                            <span className=''>Go to settings to update your information!</span>
                            <button className='btn btn-primary mt-4 mx-auto' onClick={() => navigate('/settings')}><FaCog className='mr-3'/> Settings </button>
                            </div>
                            :
                            <></>
                        }
                        </>
                    }

                    {userCopy.settings != null ?
                        <>
                        <div className='flex flex-col -z-10'>
                            {userCopy.settings.experience.permission ?
                                    <div>
                                        <span className='mr-4 opacity-70'>Experience:</span>{userCopy.settings.experience.handle}
                                    </div>
                                :
                                <></>
                            }
                            {userCopy.settings.softwareUsed.permission ?
                                    <div>
                                         <span className='mr-4 opacity-70'>Software Used:</span>{userCopy.settings.softwareUsed.handle}
                                    </div>
                                :
                                <></>
                            }
                            {userCopy.settings.profession.permission ?
                                    <div>
                                        <span className='mr-4 opacity-70'>Profession:</span> {userCopy.settings.profession.handle}
                                    </div>
                                :
                                <></>
                            }
                        </div>       
                        </>
                        :
                        <></>
                    }
                </div>
                <div className='flex flex-col lg:order-none sm:order-first basis-1/3 z-10'>
                    {(changeDetails) ? 
                        <div className='flex justify-center'>

                            <label for="my-modal">
                                <img className={`object-cover w-28 h-28 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 2xl:h-40 xl:h-40 lg:h-40 md:h-40 sm:h-40
                                rounded-xl border-2 border-primary p-1 ${changeDetails ? 'hover:cursor-pointer hover:opacity-90' : ''}`} 
                                    src={
                                        (profilePicPreviewURL.length === 0) ? 
                                            userCopy.profilePic 
                                            : profilePicPreviewURL[0].url
                                    }
                                />
                            </label>
                            <input type="checkbox" id="my-modal" className="modal-toggle"/>
                            <div className="modal">
                                <div className="modal-box flex flex-col h-[20rem]">
                                    <h3 className="font-bold text-lg text-white mx-auto">Select Image</h3>
                                    <div className="modal-action grid grid-cols-2 gap-4">
                                        <div>
                                            <label for='profilePic'>
                                                <div className='bg-base-200 rounded-lg btn btn-ghost h-full w-full'>
                                                    <BsImage size={30} />
                                                </div>
                                            </label>
                                            <input 
                                                style={{display: 'none'}}
                                                placeholder="image" 
                                                id="profilePic"
                                                hidden={!changeDetails}
                                                className=""
                                                onChange={onProfilePicChange}
                                                //onClick={setIsDefaultProfilePicSelected(false)}
                                                max='1'
                                                accept=".jpg,.png,.gif,.jpeg"
                                                type="file" 
                                                multiple
                                                required
                                            />
                                        </div>
                                        <div>
                                            <div className='grid grid-cols-3 gap-2'>
                                                {[default4, default5, default6, default7, default8, default9, default1, default2, default3].map((image) => 
                                                    <img className={`object-cover h-10 w-10 rounded-lg p-1 hover:cursor-pointer hover:opacity-90'} 2xl:h-16 xl:h-16 lg:h-16 md:h-16 sm:h-16
                                                    2xl:w-16 xl:w-16 lg:w-16 md:w-16 sm:w-16`} 
                                                        key={image} src={image} onClick={() => {
                                                                setIsDefaultProfilePicSelected(true)
                                                                setDefaultProfilePic(image)
                                                                setUserCopy((prevState) => ({ //This is a weird way to display the defaults
                                                                    ...prevState,
                                                                    profilePic: image,
                                                                }))
                                                                setProfilePicPreviewURL([])
                                                            }
                                                        }
                                                    />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-2'>
                                        <h3 className='font-light text-md text-center my-2'>Upload Image</h3>
                                        <h3 className='font-light text-md text-center my-2'>Premade Images</h3>
                                    </div>
                                    <label for='my-modal' className='btn btn-neutral mt-2'>
                                        Exit
                                    </label>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='flex justify-center'>
                            <img className={`object-cover w-28 h-28 2xl:w-40 xl:w-40 lg:w-40 md:w-40 sm:w-40 2xl:h-40 xl:h-40 lg:h-40 md:h-40 sm:h-40
                                 z-5 rounded-xl p-1`} 
                                src={
                                    (profilePicPreviewURL.length === 0) ? 
                                        userCopy.profilePic 
                                        : profilePicPreviewURL[0].url
                                }
                            />
                        </div>
                    }
                    <div className='flex justify-center mt-4'>
                        {(!changeDetails) ?
                            userMatchesLoggedInUser ?
                            <button 
                                type='button' 
                                className="btn btn-active btn-sm hover:bg-slate-500 mb-2"
                                hidden={!userMatchesLoggedInUser}
                                onClick={() => {
                                    changeDetails && onSubmit()
                                    setChangeDetails((prevState) => !prevState)
                                }}
                            >
                                <FiEdit className='mr-2' /> Profile
                            </button>
                            :
                            <></>
                            
                            :
                            <div className='mb-2'>
                                <button className="btn btn-active btn-sm mx-1 hover:bg-slate-500" onClick={() => {
                                    changeDetails && onSubmit()
                                    setChangeDetails((prevState) => !prevState)
                                }}>Confirm</button>
                                <button className="btn btn-active btn-sm mx-1 hover:bg-slate-500" onClick={() => {
                                    setChangeDetails((prevState) => !prevState)
                                    setUserCopy(user)
                                    setIsDefaultProfilePicSelected(false)
                                    setBannerPicPreviewURL([])
                                    setProfilePicPreviewURL([])
                                }}>Cancel</button>
                            </div>
                        }        
                    </div>
                    <div className='mb-2'>
                        <input
                            type='textarea' 
                            id='name' 
                            disabled={!changeDetails}
                            value={userCopy.name}
                            onChange={onChange}
                            className={`text-center font-bold text-2xl bg-base-100 w-full ${changeDetails ? 'input input-primary text-xl' : ''}`}
                        />
                    </div>
                    {userCopy.numRatings == 0 ?
                        <div className='mx-auto'>No rating</div>
                        :
                        <>
                            <div className='flex mx-auto'>
                                {[...Array(5).keys()].map((n) => (
                                    <>
                                        {n < (userCopy.totalRating / userCopy.numRatings) ?
                                            <RiStarSFill key={n} size={25} />
                                            : <RiStarSLine key={n} size={25} />
                                        }
                                    </>
                                ))}
                            </div>
                            <div className='font-light text-sm mx-auto'>
                                Out of {userCopy.numRatings} review{userCopy.numRatings == 1 ? '' : 's'}
                            </div>
                        </>
                    }
                    <div className="flex flex-col justify-center font-light break-words text-normal my-4 form-control">
                        {changeDetails == true ? 
                            <textarea 
                                type='text' 
                                id='bio' 
                                disabled={!changeDetails}
                                value={userCopy.bio}
                                onChange={onChange}
                                className={`break-words bg-base-100 w-full ${changeDetails ? 'textarea textarea-primary text-sm' : ''}`}
                            /> 
                        :
                            <p className='text-center mx-auto'>{userCopy.bio}</p>
                        }
                    </div>
                </div>
                <div className='flex flex-col justify-center items-center basis-1/3 z-10 lg:mt-16 lg:mb-0'>
                    {(userAllowsSocials) ? 
                        <h6 className='font-bold text-xl mb-4 mt-6 hidden 2xl:block xl:block lg:block md:block sm:hidden'>Socials</h6>
                        :
                        <>
                            {changeDetails ? 
                                <div className='flex flex-col'>
                                <span className=''>Go to settings to update your socials!</span>
                                <button className='btn btn-primary mt-4 mx-auto' onClick={() => navigate('/settings')}><FaCog className='mr-3'/> Settings </button>
                                </div>
                                :
                                <></>
                            }
                        </>
                    }
                    {userCopy.settings != null ?
                        <>
                        <div className='flex'>
                            {userCopy.settings.twitter.permission ?
                                <a className='btn btn-ghost btn-circle' href={`https://www.twitter.com/${userCopy.settings.twitter.handle}`} target="_blank" rel="noopener noreferrer">
                                    <FaTwitter size={25} />
                                </a>
                                :
                                <></>
                            }
                            {userCopy.settings.facebook.permission ?
                                <a className='btn btn-ghost btn-circle' href={`https://www.facebook.com/${userCopy.settings.facebook.handle}`} target="_blank" rel="noopener noreferrer">
                                    <FaFacebook size={25} />
                                </a>
                                :
                                <></>
                            }
                            {userCopy.settings.instagram.permission ?
                                <a className='btn btn-ghost btn-circle' href={`https://www.instagram.com/${userCopy.settings.instagram.handle}`} target="_blank" rel="noopener noreferrer">
                                    <RiInstagramFill size={25} />
                                </a>
                                :
                                <></>
                            }
                            {userCopy.settings.youtube.permission ?
                                <a className='btn btn-ghost btn-circle' href={`https://www.youtube.com/${userCopy.settings.youtube.handle}`} target="_blank" rel="noopener noreferrer">
                                    <FaYoutube size={25} />
                                </a>
                                :
                                <></>
                            }
                        </div>
                        {userCopy.settings.email.permission ?
                            <div className='pt-2'>
                                <a className='flex btn btn-primary btn-sm pt' href={`mailto:${userCopy.settings.email.handle}?subject=Layolay: Message from ${auth.currentUser != null ? auth.currentUser.displayName : 'Client'}`}>
                                    Email <FiSend className='ml-2'/>
                                </a>
                            </div>
                            :
                            <></>
                        }
                        {!userMatchesLoggedInUser ? 
                            <div onClick={() => {
                                createMessage(params.userId, auth.currentUser.uid)
                                navigate('/messages')}
                            } className={`${auth.currentUser == null ? 'hidden' : ''} btn btn-primary btn-sm mt-4`}>
                                Send Message <TiMessages size={20} className='ml-2'/>
                            </div>
                            :
                            <></>
                        }
                             
                        </>
                        :
                        <></>
                    }
                </div>
                {/* <div className='basis-2/5 mt-4 mx-4 block 2xl:hidden xl:hidden lg:hidden md:hidden sm:block'>
                    <div className='flex mb-4'>
                        <div className='text-2xl'>
                            Posts
                        </div>
                        <div className='justify-start ml-4'>
                            {userMatchesLoggedInUser ?
                                <Link to={`/creator-tools`}>
                                    <button type="button" className='btn btn-active btn-sm mx-1 hover:bg-slate-500'><FiPlus className="mr-1"/>New Post</button>
                                </Link>
                                : 
                                <></>
                            }
                        </div>
                    </div>
                    <div className='flex bg-base-200 rounded-lg p-4 min-h-[16rem]'>
                        <div className='my-auto ml-4'>
                            <button className={`btn btn-ghost`} disabled={currentPost == 0} onClick={() => setCurrentPost(currentPost-1)}>
                                <BsFillCaretLeftFill size={20} />
                            </button>
                            <div className='font-light text-xs text-center mt-2'>
                                Newer
                            </div>
                        </div>
                        { posts.length > 0 ?
                            <div className='flex flex-col mx-auto my-auto px-4'>
                                <div className='font-bold text-lg text-center'>
                                    {posts[currentPost].title}
                                </div>
                                <div className='font-light text-md mx-auto my-2'>
                                    {posts[currentPost].body}
                                </div>
                                <div className='font-light text-xs mx-auto mt-4'>
                                    {new Date(posts[currentPost].timestamp.seconds * 1000).toLocaleTimeString("en-US")}
                                </div>
                                <div className='font-light text-xs mx-auto'>
                                    {new Date(posts[currentPost].timestamp.seconds * 1000).toLocaleDateString("en-US")}
                                </div>
                            </div>
                            : 
                            <div className='mx-auto font-light text-md my-auto'>
                                This user has no posts yet.
                            </div>
                        } */}
                        {/* ${currentPost < posts.length ? 'hidden' : 'block'} */}
                        {/* <div className='my-auto mr-4'>
                            <button className={`btn btn-ghost`} disabled={currentPost == posts.length-1 || posts.length == 0} onClick={() => setCurrentPost(currentPost+1)}>
                                <BsFillCaretRightFill size={20} />
                            </button>
                            <div className='font-light text-xs text-center mt-2'>
                                Older
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className='flex flex-col mt-4 justify-around 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col'>
                {/* basis-full 2xl:basis-3/5 xl:basis-3/5 lg:basis-3/5 md:basis-full sm:basis-full */}
                <div className='basis-full 2xl:basis-3/5 xl:basis-3/5 lg:basis-3/5 md:basis-full sm:basis-full'>
                    <div className='flex mb-4'>
                        <div className='text-2xl ml-0 2xl:ml-4 xl:ml-4 lg:ml-4 md:ml-4 sm:ml-0'>
                            Featured Creations
                        </div>
                        <div className='justify-start ml-4'>
                            {userMatchesLoggedInUser ?
                                <>
                                    <button type="button" className='btn btn-active btn-sm mr-2 hover:bg-slate-500' onClick={() => {navigate('/item-upload')}}>
                                        <FiUpload/>
                                        <span className="hidden ml-2 2xl:block xl:block lg:block md:block sm:block">Upload</span>
                                    </button>
                                    <button type="button" className='btn btn-active btn-sm hover:bg-slate-500' onClick={() => {navigate('/creator-tools')}}>
                                        <FiEdit/>
                                        <span className="hidden ml-2 2xl:block xl:block lg:block md:block sm:block">Edit</span>
                                    </button>
                                </>
                                : 
                                <></>
                            }
                        </div>
                    </div>
                    <div className='2xl:m-4 xl:m-4 lg:m-4 md:m-4 sm:m-0'>
                        <div className='grid gap-4 grid-cols-2 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2
                        2xl:gap-4 xl:gap-4 lg:gap-4 md:gap-4 sm:gap-4 mb-4'>
                            {items.length == 0 ?
                                <>
                                    <div className='flex flex-col bg-base-200 aspect-square rounded-xl m-4'>
                                        <div className='flex flex-col mx-auto my-auto'>
                                            <button className='btn btn-ghost mx-auto my-auto' onClick={() => {navigate('/item-upload')}}>
                                                <FaPlus size={25} />
                                            </button>
                                            <div className='font-light text-xs mx-auto mt-2'>
                                                Upload an Item!
                                            </div>
                                        </div>
                                        
                                    </div>
                                </>
                                :
                                items.map((item) => (
                                    <ItemCard className='' key={item.id} id={item.id} item={item.data}/>
                                ))
                            }
                        </div>
                    </div>
                </div>
                {/* basis-0 mx-4 hidden 2xl:block xl:block lg:block md:block sm:hidden 2xl:basis-2/5 xl:basis-2/5 lg:basis-2/5 md:basis-0 sm:basis-0 */}
                <div className='2xl:basis-2/5 xl:basis-2/5 lg:basis-2/5'>
                    <div className='flex mb-4'>
                        <div className='text-2xl'>
                            Posts
                        </div>
                        <div className='justify-start ml-4'>
                            {userMatchesLoggedInUser ?
                                <Link to={`/creator-tools`}>
                                    <button type="button" className='btn btn-active btn-sm mx-1 hover:bg-slate-500'><FiPlus className="mr-1"/>New Post</button>
                                </Link>
                                : 
                                <></>
                            }
                        </div>
                    </div>
                    <div className='flex bg-base-200 rounded-lg p-4 min-h-[16rem]'>
                        <div className='my-auto ml-4'>
                            <button className={`btn btn-ghost`} disabled={currentPost == 0} onClick={() => setCurrentPost(currentPost-1)}>
                                <BsFillCaretLeftFill size={20} />
                            </button>
                            <div className='font-light text-xs text-center mt-2'>
                                Newer
                            </div>
                        </div>
                        { posts.length > 0 ?
                            <div className='flex flex-col mx-auto my-auto px-4'>
                                <div className='font-bold text-lg text-center'>
                                    {posts[currentPost].title}
                                </div>
                                <div className='font-light text-md mx-auto my-2'>
                                    {posts[currentPost].body}
                                </div>
                                <div className='font-light text-xs mx-auto mt-4'>
                                    {new Date(posts[currentPost].timestamp.seconds * 1000).toLocaleTimeString("en-US")}
                                </div>
                                <div className='font-light text-xs mx-auto'>
                                    {new Date(posts[currentPost].timestamp.seconds * 1000).toLocaleDateString("en-US")}
                                </div>
                            </div>
                            : 
                            <div className='mx-auto font-light text-md my-auto'>
                                This user has no posts yet.
                            </div>
                        }
                        {/* ${currentPost < posts.length ? 'hidden' : 'block'} */}
                        <div className='my-auto mr-4'>
                            <button className={`btn btn-ghost`} disabled={currentPost == posts.length-1 || posts.length == 0} onClick={() => setCurrentPost(currentPost+1)}>
                                <BsFillCaretRightFill size={20} />
                            </button>
                            <div className='font-light text-xs text-center mt-2'>
                                Older
                            </div>
                        </div>
                    </div>
                    
                    {reviews.length > 0 ?
                        <div className="my-4">
                            <div className='text-2xl'>
                                Reviews
                            </div>
                            {reviews.map((review) => (
                                <ProfileReviewCard key={review.id} id={review.id} review={review.data}/>
                            ))}
                        </div>
                        : 
                        <>
                            <div className='text-2xl my-4'>
                                No Reviews
                            </div>
                            <div className='font-light text-lg ml-4'>
                                Have you bought something from this creator? Leave a review on the item you bought!
                            </div>
                        </>
                    }
                </div>
            </div>
            {/* <div className='block 2xl:hidden xl:hidden lg:hidden md:hidden sm:block'>
                {reviews.length > 0 ?
                    <>
                        <div className='text-2xl my-4'>
                            Reviews
                        </div>
                        {reviews.map((review) => (
                            <ProfileReviewCard key={review.id} id={review.id} review={review.data}/>
                        ))}
                    </>
                    : 
                    <>
                        <div className='text-2xl my-4'>
                            No Reviews
                        </div>
                        <div className='font-light text-lg ml-4'>
                            Have you bought something from this creator? Leave a review on the item you bought!
                        </div>
                    </>
                }
            </div> */}
        </div>
    )
}

export default Profile