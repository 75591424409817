import React from 'react'
import { useSpring, animated, to, config } from 'react-spring'

function AnimateNumber({ number }) {

    const { val } = useSpring({ 
        from: { val: 0 }, 
        to: {val: Number(number)},
        config: config.molasses
    })

    return (
        <animated.div>{val.to(n => n.toFixed(2))}</animated.div>
    )

}

export default AnimateNumber