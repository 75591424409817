import { useState, useEffect } from 'react'
import { FaBug, FaSkull } from 'react-icons/fa'
import { SiOpenbugbounty } from 'react-icons/si'
import { db } from '../firebase.config'
import { addDoc, collection, serverTimestamp, doc, getDoc } from "firebase/firestore"

function Bugs() {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [titleError, setTitleError] = useState(false)
    const [descriptionError, setDescriptionError] = useState(false)

    const [reported, setReported] = useState(false)

    useEffect(() => {
        document.title = 'Layolay | Bug Report'
    }, [])

    const onSubmit = async () => {
        if (title == ''){
            setTitleError(true)
        }
        if (description == ''){
            setDescriptionError(true)
        }
        if (title != '' && description != '' && reported == false){
            const bug = {
                title: title,
                description: description,
            }

            const docRef = await addDoc(collection(db, 'bugs'), bug)
            setReported(true)
        }
    }

    // if (reported) {
    //     return (
    //         <div className='flex flex-col h-full mx-auto justify-center items-center bg-base-200'>
    //             <h1 className="font-bold text-4xl text-center m-4">Bug Report Form</h1>
    //             <div className='flex flex-col my-auto'>
    //                 <h1 className='font-medium text-2xl m-4'>Thanks for the assist! Your feedback has been reported.</h1>
    //             </div>
    //             <div className='flex flex-col my-auto'>
                    
    //             </div>
    //         </div>
    //     )
    // }

    return (
        <div className='flex flex-col bg-base-200 rounded-2xl p-8 m-4 2xl:w-[40%] xl:w-[50%] lg:w-[60%] md:w-[70%]
        2xl:mx-auto xl:mx-auto lg:mx-auto md:mx-auto 2xl:my-8 xl:my-8 lg:my-8 md:my-8'>
            <h1 className="font-bold text-2xl pb-6">Bug Report Form</h1>
            <div className='flex mb-4'>
                <h1 className="font-medium text-lg my-auto mr-4">Title</h1>
                <textarea
                        required
                        minLength={1}
                        maxLength={200}
                        value={title}
                        onChange={(e) => 
                            {
                                setTitle(e.target.value)
                                setTitleError(false)
                            }
                        }
                        placeholder="title"
                        id="description"
                        className={`input textarea w-full h-12 textarea-bordered ${titleError ? 'textarea-error' : 'textarea-primary'}`}
                        type='text'
                ></textarea>
            </div>
            <h1 className="font-light max-w-md text-md mb-4">Good example: 'Cart item count won't update with 10+ items' <br/>Bad example: 'mY cARt dOeSn'T wOrk'</h1>
            <div className='flex mb-4'>
                <h1 className="font-medium text-lg text-center my-auto mr-4">Description</h1>
                <textarea
                        required
                        minLength={1}
                        maxLength={500}
                        value={description}
                        onChange={(e) => 
                            {
                                setDescription(e.target.value)
                                setDescriptionError(false)
                            }
                        }
                        placeholder="description"
                        id="description"
                        className={`input textarea w-full h-12 textarea-bordered ${descriptionError ? 'textarea-error' : 'textarea-primary'}`}
                        type='text'
                ></textarea>
            </div>
            <h1 className="font-light max-w-md text-md">Be sure to include your device type, any relevant pages, and whether the issue has occured multiple times.</h1>

            <h1 className="font-medium text-2xl mx-auto m-6">Squash that bug!</h1>
            { reported == false ?
                <button className={`btn btn-primary w-32 h-32 mb-8 mx-auto`} disabled = {title == '' || description == ''} onClick={onSubmit}>
                    <FaBug size={35} />
                </button>
                : 
                <>
                    <div className='w-32 h-32 bg-error rounded-lg mx-auto flex items-center'>
                        <FaSkull size={35} className='mx-auto' />
                    </div>
                    <h1 className='font-medium text-lg mt-4'>Thanks for the assist! Your feedback has been reported.</h1>
                </>
            }
        </div>
    )
}

export default Bugs
