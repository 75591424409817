import DynamicItemCard from "./DynamicItemCard"
import { useTrail, animated, to, config } from 'react-spring'
import { useState } from "react"

function DynamicItemCardList({ data }) {
    
    const [reanimate, setReanimate] = useState(false)
    //const config = { mass: 5, tension: 2000, friction: 200 }
    const trail = useTrail(data.length, {
        config: config.gentle,
        // reset: reanimate,
        opacity: 1,
        x:  0,
        height: 80,
        from: { opacity: 0, x: 20, height: 0 },
        // onStart: () =>  setReanimate(false), 
        // onProps: () => setReanimate(true),
    })
  
  return (
    <>
      <div className='grid grid-cols-2 gap-2 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2
      2xl:gap-4 xl:gap-4 lg:gap-4 md:gap-4 sm:gap-4 mb-4'>
          {trail.map(({ x, height, ...rest }, index) => (
              <animated.div
                  key={data[index].id.raw}
                  className="trails-text"
                  style={{
                  ...rest,
                  transform: x.to(x => `translate3d(0,${x}px,0)`)
                  }}
              >
                  <DynamicItemCard key={data[index].id.raw} name={data[index].name.raw} imgUrls={data[index].imgurls.raw} prices={data[index].prices.raw} id={data[index].id.raw}/>
              </animated.div>
          ))}
      </div>
        
      {/* {data.map((r) => (
        <DynamicItemCard key={r.id.raw} searchdata={r} name={r.name.raw} imgUrls={r.imgurls.raw} prices={r.prices.raw} id={r.id.raw}/>
       ))} */}
    </>
  )
}

export default DynamicItemCardList