import SignUpCard from "../components/SignUpCard"
import { useState, useEffect, useContext, useRef } from "react"
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth'
import { updateDoc, doc, getDocs, where, collection, query } from 'firebase/firestore'
import { db } from '../firebase.config'
import { Link, useNavigate, useNavigationType } from "react-router-dom"
import { RiEyeCloseFill, RiEyeFill } from 'react-icons/ri'
import { MdOutlineEmail } from 'react-icons/md'
import { BiLockAlt } from "react-icons/bi"
import { ImWarning } from 'react-icons/im'
import { IoAlertCircleOutline } from 'react-icons/io5'
import CustomerContext from '../context/user/CustomerContext'


function SignIn() {

    const { setCart } = useContext(CustomerContext)

    const [showPassword, setShowPassword] = useState(false)
    const [displayLogInError, setDisplayLogInError] = useState(false)
    const [displayCapsLockWarning, setDisplayCapsLockWarning] = useState(false)
    const [formData, setFormData] = useState({
        email:'',
        password:'',
    })
    const {email, password} = formData

    const navigate = useNavigate()

    const onChange = (e) => {
        //console.log(e)
        if(e.target.id == 'password'){
            console.log('Inside Password')
            // if (e.getModifierState("CapsLock")) {
            //     setDisplayCapsLockWarning(true)
            //   } else {
            //     setDisplayCapsLockWarning(false)
            //   }
        }
        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }

    const isMounted = useRef(true)
    const auth = getAuth()

    useEffect(() => {
        document.title = 'Layolay | Sign In'
    }, [])

    useEffect(() => {

        if(isMounted){
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    const loadCart = async () => {
                        // Find private details for the current user
                        // Get reference
                        const privateCollectionRef = collection(db, 'private')
                        
                        // Create a query
                        const q = query(
                            privateCollectionRef,
                            where('userId', '==', auth.currentUser.uid)
                        )

                        // Execute query
                        const querySnap = await getDocs(q)
                        
                        let privateDetails = []
                        querySnap.forEach((doc) => {
                            return privateDetails.push({
                                id: doc.id,
                                data: doc.data()
                            })
                        })

                        console.log('privateDetails', privateDetails)

                        const loadedCart = privateDetails[0].data.cart

                        setCart(loadedCart)
                    }

                    loadCart()
                    
                    navigate('/home')
                } 
            })
        }

        return () => {
            isMounted.current = false
        }

    }, [isMounted])

    
    const onSubmit = async (e) => {
        e.preventDefault()
        try {
            const auth = getAuth()

            const userCredential = await signInWithEmailAndPassword(auth, email, password)
            const user = userCredential.user

            if(userCredential.user) {
                // Set cookies 
                //   grab cart from user on firebase and set cookie for the session
                navigate('/home')
            }
        } catch (error) {
            console.log('BAD LOGIN')
            setDisplayLogInError(true)
        }
    }

    const checkForCaps = (e) => { 
        if (e.getModifierState("CapsLock")) {
            setDisplayCapsLockWarning(true)
        } else {
            setDisplayCapsLockWarning(false)
        }
    }

    const eyeDisplay = (showPassword ? <RiEyeFill size={25} /> : <RiEyeCloseFill size={25} />)

    const emailInput = [
        <input 
            placeholder="Email"
            key='email'
            id="email" 
            value={email} 
            onChange={(onChange)} 
            className="input w-full input-primary input-bordered" 
            type="email" 
        />
    ]

    // var input = document.getElementById("password");
    // var text = document.getElementById("CapsLockText");
    // input.addEventListener("keyup", function(event) {
    // if (event.getModifierState("CapsLock")) {
    //     setDisplayCapsLockWarning(true)
    //   } else {
    //     setDisplayCapsLockWarning(false)
    //   }
    // })

    const passwordInput = [
        <input 
            placeholder="Password" 
            key='password'
            id="password" 
            value={password} 
            onChange={(onChange)} 
            className="input w-full input-primary input-bordered col-span" 
            type={showPassword ? 'text' : 'password'} 
            onKeyDown={(checkForCaps)}
        />
    ]

    return (
            <div className="flex flex-row p-4 2xl:m-auto xl:m-auto lg:m-auto md:m-auto sm:m-auto">
                <div className='p-8 card bg-base-200 basis-full 2xl:basis-3/4 xl:basis-3/4 lg:basis-3/4 md:basis-full sm:basis-full'>
                    <div className='flex justify-between'>
                        <h6 className='pb-6 font-bold text-2xl'>Sign In</h6>   
                        <button className='btn btn-primary block 2xl:hidden xl:hidden lg:hidden md:block sm:block' onClick={() => {navigate('/sign-up')}}>Sign Up <br/> Instead</button>
                    </div>
                    
                    <form onSubmit={onSubmit}>
                        <div className="form-control pb-4">
                            <label className="label">
                                <span className="label-text">Email</span>
                            </label>
                            <div className="input-group">
                                <span><MdOutlineEmail size={18}/></span>
                                {emailInput}
                            </div>
                        </div>
                        
                        <div className="form-control  pb-4 w-full">
                            <label className="label">
                                <span className="label-text">Password</span>
                            </label>
                                <div className="flex space-x-2">
                                    <div className="input-group">
                                        <span className=""><BiLockAlt size={18}/></span>
                                        {passwordInput}
                                    </div>
                                    
                                    <button type="button" onClick={() => setShowPassword((prevState => !prevState))} className="btn btn-primary">{eyeDisplay}</button>
                                </div>
                            <label className="label">
                                <Link to={'/forgot-password'} className="label-text-alt">Forgot password?</Link>
                            </label>
                            <p className={`flex ${displayCapsLockWarning ? 'visible' : 'invisible'} text-warning`}><ImWarning size={18} className="self-center mr-2"/> Caps Lock is on!</p>
                        </div>
                        <div className="flex mt-6 justify-between">
                            {displayLogInError ? 
                                <div className='flex rounded-lg text-error mr-2'><IoAlertCircleOutline size={25} color="'#ff5724'" className='self-center mx-2 my-1' /> <span className='self-center mr-2'>Incorrect email or password</span></div>
                                :
                                <div></div>
                            }
                            <button type="submit" className='btn btn-primary basis-1/4'>Log In</button>
                        </div>
                    </form>
                </div>
                <div className='ml-2 hidden basis-0 2xl:basis-1/4 xl:basis-1/4 lg:basis-1/4 md:basis-0 sm:basis-0 2xl:block xl:block lg:block md:hidden sm:hidden'>
                    <SignUpCard />
                </div>
            </div>   
    )
}

export default SignIn
