import { useState, useEffect } from 'react'
import { collection, getDocs, query, orderBy, where, limit, startAfter } from 'firebase/firestore'
import { db } from '../firebase.config'
import UserCard from './UserCard'
import Aos from 'aos'
import 'aos/dist/aos.css'

function UserList() {

    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [lastFetchedUser, setLastFetchedUser] = useState(null)
    const [moreUsersToLoad, setMoreUsersToLoad] = useState(true)

    const getUsers = async () => {
        setLoading(true)

        try {
            // Get reference
            const userRef = collection(db, 'users')

            // Create a query
            const q = query(
                userRef,
                orderBy('numItems', 'desc'),
                where('numItems', '>=', 1),
                limit(10),
            )

            // Execute query
            const querySnap = await getDocs(q)
            const lastVisible = querySnap.docs[querySnap.docs.length-1]
            setLastFetchedUser(lastVisible)
            
            let users = []
            querySnap.forEach((doc) => {
                return users.push({
                    id: doc.id,
                    data: doc.data()
                })
            })

            if(users.length < 10){
                setMoreUsersToLoad(false)
            }
            setUsers(users)

        } catch(error) {
            console.log(error)
        }
    }

    const getMoreUsers = async () => {
        setLoading(true)

        try {
            // Get reference
            const userRef = collection(db, 'users')

            // Create a query
            const q = query(
                userRef,
                orderBy('name'),
                startAfter(lastFetchedUser),
                limit(10)
            )

            // Execute query
            const querySnap = await getDocs(q)
            const lastVisible = querySnap.docs[querySnap.docs.length-1]
            setLastFetchedUser(lastVisible)
            
            let users = []
            querySnap.forEach((doc) => {
                return users.push({
                    id: doc.id,
                    data: doc.data()
                })
            })

            if(users.length < 10){
                setMoreUsersToLoad(false)
            }
            setUsers((prevState) => [...prevState, ...users])

        } catch(error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getUsers()
        Aos.init({
            duration: 1000,
            easing: 'ease-in-sine',
            once: true,
        })
    }, [])


    return (
        <>
            {users.length > 0 ?
                <>
                    <div className='flex flex-col'>
                        {users.map((user, index) => (
                            <div data-aos={(index % 2 == 0 ? 'fade-up' : 'fade-up')} data-aos-delay={(150).toString()} className='my-2'>
                                <UserCard key={user.id} id={user.id} user={user.data}/>
                            </div>
                        ))}
                    </div>
                    {moreUsersToLoad ?
                        <div className='flex container'> 
                            <button className='btn btn-primary mx-auto my-4' onClick={getMoreUsers}>Load More Creators</button>
                        </div>
                        : <></>
                    }
                </>
                : <p>No users found</p>
            }
        </>

    )
}

export default UserList