import { useState, useEffect, useContext, useRef, Suspense, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { doc, getDoc, setDoc, addDoc, updateDoc, collection, getDocs, query, orderBy, where, limit, serverTimestamp } from 'firebase/firestore'
import { db } from '../firebase.config'
import { getAuth } from 'firebase/auth'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import { RiStarSLine, RiStarSFill } from 'react-icons/ri'
import { ImPlus } from 'react-icons/im'
import { FaTimes, FaHandPaper, FaFlag, FaHeart } from 'react-icons/fa'
import { MdOutlineZoomOutMap } from 'react-icons/md'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { FiHeart } from 'react-icons/fi'
import { VscQuestion } from 'react-icons/vsc'
import { Link } from 'react-router-dom'
import { v4 as uuidv4} from 'uuid'
import Review from '../components/Review'
import ItemCard from '../components/ItemCard'
import Spinner from '../components/Spinner'
import CustomerContext from '../context/user/CustomerContext'
import { Canvas, useFrame, useLoader, useThree } from "@react-three/fiber"
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'
import {  PerspectiveCamera, useProgress, Html, Center, OrbitControls, useAspect } from '@react-three/drei'
import * as THREE from "three"

function Item() {

    THREE.Object3D.DefaultUp.set(0, 0, 1)
    const params = useParams()
    const storage = getStorage()
    const navigate = useNavigate()
    //const { viewport } = useThree()

    const [privateDetails, setPrivateDetails] = useState(null)

    const [loading, setLoading] = useState(true)
    const [item, setItem] = useState(null)
    const [objectHasBeenRenderedFlag, setObjectHasBeenRenderedFlag] = useState(false)
    const [user, setUser] = useState({})
    const [currentImage, setCurrentImage] = useState(null)
    const [display3D, setDisplay3D] = useState(false)

    const [relatedItems, setRelatedItems] = useState([])

    const [size, setSize] = useState('Small')
    const [color, setColor] = useState({name: 'Vanilla White', hex: 'bg-[#dad5d1]', text: 'text-black'})
    const [note, setNote] = useState('')
    const [quantity, setQuantity] = useState(1)

    const [favorite, setFavorite] = useState(false)

    const [report, setReport] = useState('')
    const [reported, setReported] = useState(false)

    // https://www.prusa3d.com/product/prusament-pla-prusa-galaxy-black-1kg/
    // https://www.hatchbox3d.com/products/3d-pla-1kg1-75-silk-lblu
    // https://www.prusa3d.com/product/prusament-pla-royal-blue-blend-970g/
    // https://www.filacube.com/products/1-75mm-1kg-orange-pms-159c-filacube-3d-printer-pla-2-filament-burnt-orange-longhorn-long-horn-ut-university-of-texas-austin-pms-159
    // https://www.filacube.com/products/1-75mm-1kg-black-filacube-3d-printer-pla-2-filament
    // https://www.prusa3d.com/product/prusament-pla-galaxy-silver-1kg/
    // https://www.prusa3d.com/product/prusament-pla-opal-green-1kg/

    const availableColors = [
        {name: 'Galaxy Black', hex: 'bg-[#2c2e3a]', text: 'text-white'},
        {name: 'Galaxy Purple', hex: 'bg-[#483470]', text: 'text-white'},
        {name: 'Hatchbox Black', hex: 'bg-[#383934]', text: 'text-white'},
        {name: 'Vanilla White', hex: 'bg-[#dad5d1]', text: 'text-black'},
        {name: 'Royal Blue', hex: 'bg-[#2b4ea1]', text: 'text-white'},
        {name: 'Silk Light Blue', hex: 'bg-[#a1b4e5]', text: 'text-black'},
        {name: 'Silver', hex: 'bg-[#7d787c]', text: 'text-white'},
        {name: 'Gold', hex: 'bg-[#ceb61e]', text: 'text-black'},
        {name: 'Hatchbox Red', hex: 'bg-[#bd131b]', text: 'text-white'},
        {name: 'Burnt Orange', hex: 'bg-[#e97418]', text: 'text-white'},
        {name: 'Opal Green', hex: 'bg-[#05654a]', text: 'text-white'},
    ]

    const [reviews, setReviews] = useState([])
    const [allowedToReview, setAllowedToReview] = useState(false)
    const [creatingReview, setCreatingReview] = useState(false)
    const [userRating, setUserRating] = useState(0)
    const [userReview, setUserReview] = useState('')
    const [lastFetchedReview, setLastFetchedReview] = useState(null)
    const [moreReviewsToLoad, setMoreReviewsToLoad] = useState(true)

    const [grab, setGrab] = useState(false)
    const [stopModelRotation, setStopModelRotation] = useState(false)

    const { addItem } = useContext(CustomerContext)

    const auth = getAuth()

    function Scene({fileName, item}) {
        const mesh = useRef(null)
        if (item.objectType == 'obj'){
            const obj = useLoader(OBJLoader, fileName)
            const box = useMemo(() => new THREE.Box3().setFromObject(obj), [])
            return (
                    <mesh scale={.05} ref={mesh} position={[0,-2,0]}>
                        <primitive object={obj}  dispose={null} />
                    </mesh>
            )
        } else if (item.objectType == 'stl') {
                const stl = useLoader(STLLoader, fileName)
                const { viewport } = useThree()
            if(objectHasBeenRenderedFlag == false){
                stl.computeBoundingBox()
                stl.center() 
                stl.computeVertexNormals()
                stl.normalizeNormals()
                setObjectHasBeenRenderedFlag(true)
            }
            const largestDimension = computeLargestDimension(stl.boundingBox)
            const smallestCanvasDimension = Math.min(viewport.width, viewport.height)
            if(stopModelRotation != true){
                useFrame(() => (mesh.current.rotation.z -= 0.005))
            } else {
                useFrame(() => (mesh.current.rotation.z = mesh.current.rotation.z))
            }
            return (
                    <mesh scale={0.65 * smallestCanvasDimension / largestDimension} ref={mesh} position={[0,0,0]}>
                        <primitive object={stl} attach="geometry"/>
                        <meshStandardMaterial color={color.hex.substring(4, 11)}/>
                    </mesh>
            )
        }
    }

    const computeLargestDimension = (dimensions) => {
        const x = dimensions.max.x - dimensions.min.x
        const y = dimensions.max.y - dimensions.min.y
        const z = dimensions.max.z - dimensions.min.z
        return Math.max(x, y, z)
    }

    const mod = (x, y) => {
        return ((x % y) + y) % y
    }
    
    useEffect(() => {
        const fetchItem = async () => {
            setLoading(true)
            const docRef = doc(db, 'items', params.itemId)
            const docSnap = await getDoc(docRef)

            //console.log(docSnap.data())
            if(docSnap.exists()){
                setItem(docSnap.data())
            }
            setLoading(false)
        }

        fetchItem()
        
    }, [params.itemId])

    useEffect(() => {
        const fetchUser = async() => {
            const docRef = doc(db, 'users', item.userId)
            const docSnap = await getDoc(docRef)

            if(docSnap.exists()){
                setUser(docSnap.data())
            }
            setLoading(false)
            setCurrentImage(item.imgUrls[0])
            // getDownloadURL(ref(storage, `images/${item.imageReferenceArray[0]}`)).then((url) => setCurrentImage(url))
            // // item.imageReferenceArray.map((ref) => {
            // //     getDownloadURL(storage, )
            // // })
            
        }

        const fetchRelatedItems = async() => {
            const itemRef = collection(db, 'items')

            // Create a query
            const q = query(
                itemRef,
                where('hidden', '==', false),
                where('userId', '==', item.userId),
                where('name', '!=', item.name),
                limit(5)
            )

            // Execute query
            const querySnap = await getDocs(q)
            
            let relatedItems = []
            querySnap.forEach((doc) => {
                return relatedItems.push({
                    id: doc.id,
                    data: doc.data()
                })
            })

            setRelatedItems(relatedItems)
        }

        if (item != null){
            fetchUser()
            fetchReviews()
            fetchRelatedItems()
            document.title = 'Layolay | ' + item.name
        }

    }, [item])

    useEffect(() => {
        const fetchPrivate = async (userId) => {
            // Get reference
            const docRef = doc(db, 'private', userId)
            const docSnap = await getDoc(docRef)
            setPrivateDetails(docSnap.data())
            // console.log('favorites', docSnap.data().favorites)
            if (docSnap.data().favorites.includes(params.itemId)){
                setFavorite(true)
            }
        }
        if (auth.currentUser != null){
            fetchPrivate(auth.currentUser.uid)
        }
    }, [auth.currentUser])

    const fetchReviews = async() => {
        // Get reference
        const reviewRef = collection(db, 'reviews')

        // Create a query
        const q = query(
            reviewRef,
            where('itemId', '==', params.itemId),
            limit(5)
        )

        // Execute query
        const querySnap = await getDocs(q)
        const lastVisible = querySnap.docs[querySnap.docs.length-1]
        setLastFetchedReview(lastVisible)
        
        let reviews = []
        querySnap.forEach((doc) => {
            return reviews.push({
                id: doc.id,
                data: doc.data()
            })
        })

        // Shift logged in user's review to the front
        setAllowedToReview(false)
        if (auth.currentUser != null){
            if (auth.currentUser.uid != item.userId){
                //console.log(reviews)
                let currentUserReview = null
                for(let i = 0; i < reviews.length; i++){
                    //console.log('posting user', reviews[i].data.postingUserId, 'current user', auth.currentUser.uid)
                    if (reviews[i].data.postingUserId == auth.currentUser.uid){
                        currentUserReview = {index: i, user: reviews[i]}
                    }
                }
                //console.log('currentUserReview', currentUserReview)
                if (currentUserReview != null){
                    reviews.splice(currentUserReview.index, 1);
                    reviews.unshift(currentUserReview.user)
                } else {
                    setAllowedToReview(true)
                }
                //console.log(allowedToReview)
            }
        }

        if(reviews.length < 5){
            setMoreReviewsToLoad(false)
        }
        
        //console.log('setting reviews', reviews)
        setReviews(reviews)
    }

    const addItemToCart = (moveTo) => {

        const newItem = {
            cartItemId: uuidv4(),
            itemId: params.itemId,
            userId: item.userId,
            prices: item.prices,
            quantity: quantity,
            options: {
                size: size,
                color: color,
                note: note,
                gift: false,
            },
        }

        // Update context
        addItem(newItem)

        // Navigate to cart
        navigate('/' + moveTo)
    }

    const submitReview = async() => {
        if (auth.currentUser != null){
            // Upload review
            setAllowedToReview(false)
            setUserRating(0)
            setUserReview('')
            await addDoc(collection(db, 'reviews'), {
                body: userReview,
                itemId: params.itemId,
                rating: userRating,
                timestamp: serverTimestamp(),
                postingUserId: auth.currentUser.uid,
                owningUserId: item.userId,
            })
            await setDoc(doc(db, 'users', item.userId), {
                ...user,
                numRatings: user.numRatings + 1,
                totalRating: user.totalRating + userRating
            })
        }
    }

    const updateFavorites = async() => {
        if (auth.currentUser != null){
            if (favorite == false && privateDetails != null){
                const privateRef = doc(db, 'private', auth.currentUser.uid)
                await updateDoc(privateRef, {
                    favorites: [...privateDetails.favorites, params.itemId]
                })
            } else {
                const privateRef = doc(db, 'private', auth.currentUser.uid)
                const newFavorites = privateDetails.favorites.filter(itemId => itemId !== params.itemId)
                await updateDoc(privateRef, {
                    favorites: newFavorites
                })
                setPrivateDetails({
                    ...privateDetails,
                    favorites: newFavorites
                })
            }
        }
        
        setFavorite(!favorite)        
    }

    function CustomLoader() {
        const { progress } = useProgress()
        // console.log(progress)
        return (
            <Html center>
                <span className='font-light text-md'>
                    <Spinner />
                    Loading!
                </span>
            </Html>
        )
    }
      
    if (loading) {
        return (
            <div className='mx-auto mt-14 mb-auto h-1/6 w-1/6'>
                <Spinner />
            </div>
        )
    }

    if (item == null){
        return (
            <div className='flex container mt-8 mx-auto'>
                <div className='flex flex-col bg-base-200 rounded-lg mx-auto p-8'>
                    <div className='font-bold text-3xl mx-auto'>
                        This item does not exist.
                    </div>
                    <div className='font-light text-2xl text-center mx-auto mt-8'>
                        Maybe you could be the one to fill this spot!
                    </div>
                    <button className='btn btn-primary center mx-auto mt-8 w-64' onClick = {() => navigate('/item-upload')}>
                        Upload Your Creation
                    </button>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className='flex container mt-4 mx-auto flex-col 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col
            2xl:mt-8 xl:mt-8 lg:mt-8 md:mt-8 sm:mt-8'>
                {item.hidden == false || (auth.currentUser != null ? auth.currentUser.uid == item.userId : false) ?
                    <>
                        <div className='flex mx-auto 2xl:basis-2/3 xl:basis-2/3 lg:basis-2/3 md:basis-2/3 sm:basis-2/3'>
                            <div className='flex-col hidden 2xl:flex xl:flex lg:flex md:hidden sm:hidden
                            2xl:basis-1/5 xl:basis-1/5 lg:basis-1/5'>
                                {item.imgUrls.map((url) => (
                                    <>
                                        {url == currentImage ?
                                            <div key={url} className='bg-neutral p-2 mx-auto rounded-md mr-0 mb-4'>
                                                <img src={url} alt='thumbnail' className='rounded-md object-cover w-20 h-20 hover:cursor-pointer' onClick={() => {
                                                    setCurrentImage(url)
                                                    setObjectHasBeenRenderedFlag(false)
                                                    setDisplay3D(false)
                                                }}/>
                                            </div>
                                            :
                                            <div key={url} className='bg-base-200 opacity-50 p-2 mx-auto rounded-md mr-0 mb-4'>
                                                <img src={url} alt='thumbnail' className='rounded-md object-cover w-20 h-20 hover:cursor-pointer' onClick={() => {
                                                    setCurrentImage(url)
                                                    setDisplay3D(false)
                                                }}/>
                                            </div> 
                                        }
                                    </>
                                ))}
                                <div className={`mx-auto hover:cursor-pointer rounded-md p-2 mr-0 mb-4 ${display3D ? 'bg-neutral' : 'bg-base-300 opacity-50'}`}>
                                    <div className={`flex rounded-md w-20 h-20 hover:cursor-pointer ${display3D ? 'bg-base-100 ' : 'bg-base-200'}`} onClick={() => {
                                        setDisplay3D(true)
                                        setCurrentImage('')
                                    }}>
                                        <div className='font-light text-lg mx-auto my-auto text-center'>
                                            3D <br/> View
                                        </div>
                                    </div>
                                </div>
                                {auth.currentUser != null ?
                                    <div className='bg-base-200 mx-auto hover:cursor-pointer rounded-md p-2 mr-0 mb-4'>
                                        <div className='flex flex-col rounded-md w-20 h-20 hover:cursor-pointer'>
                                            <button className='btn btn-ghost mx-auto my-auto' onClick = {updateFavorites}>
                                                {favorite == false ?
                                                    <FiHeart size={25} />
                                                    :
                                                    <FaHeart size={25} />
                                                }
                                            </button>
                                            <div className='font-light text-xs mx-auto my-auto'>Favorite</div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            <div className='flex flex-col mx-4 2xl:basis-4/5 xl:basis-4/5 lg:basis-4/5'>
                                {display3D ?
                                    <div className={`${grab ? 'cursor-grabbing' : 'cursor-grab'} aspect-auto `} 
                                        onMouseDown={() => {
                                            setGrab(true)
                                            setStopModelRotation(true)
                                        }}
                                        onMouseUp={() => setGrab(false)}
                                        onMouseLeave={() => setGrab(false)} 
                                    >
                                        {item.objectUrls != null ? 
                                            <div className='flex flex-col'>
                                                {/* style={{ height: 576, width: 700 }} */}
                                                <div className='self-center w-full h-[300px] 2xl:h-[500px] 2xl:w-full xl:h-[500px] xl:w-full lg:h-[500px] lg:w-full
                                                md:h-[400px] md:w-full sm:h-[400px] sm:w-full' onClick={() => {setStopModelRotation(true)}}>
                                                    <Canvas className='rounded-lg' camera={{ fov: 75, near: .01, far: 1000, position: [ 0, -30, 15] }} shadowMap>
                                                        <Suspense fallback={<CustomLoader />}>
                                                            <ambientLight intensity={0.4} />
                                                            <pointLight intensity={0.4} position={[ 0, -60, 15]} />
                                                            <pointLight intensity={0.4} position={[ 0, 60, 15]} />
                                                            <pointLight intensity={0.4} position={[ 0, 0, 70]} />
                                                            <OrbitControls enablePan={false} enableZoom={true} enableRotate={true} />
                                                            {/* Enable the axes helper below for easier debugging */}
                                                            {/* <primitive object={new THREE.AxesHelper(3000)} /> */}
                                                            <Center>
                                                                <Scene fileName={item.objectUrls[0]} item={item} />
                                                            </Center>
                                                        </Suspense>
                                                    </Canvas>
                                                </div>
                                                <div className='ml-auto mt-2 hidden 2xl:flex xl:flex lg:flex md:flex sm:flex'>
                                                    <FaHandPaper className='my-auto mr-2' size={15} />
                                                    <div className='font-light text-sm my-auto mr-4'>
                                                        Click and drag to move the model
                                                    </div>
                                                    <MdOutlineZoomOutMap className='my-auto mr-2' size={15} />
                                                    <div className='font-light text-sm my-auto mr-4'>
                                                        Scroll to zoom in and out
                                                    </div>
                                                </div>
                                                <div className='ml-auto mt-2 flex 2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden'>
                                                    <FaHandPaper className='my-auto mr-2' size={15} />
                                                    <div className='font-light text-xs my-auto mr-4'>
                                                        Swipe to move the model
                                                    </div>
                                                    <MdOutlineZoomOutMap className='my-auto mr-2' size={15} />
                                                    <div className='font-light text-xs my-auto mr-4'>
                                                        Pinch to zoom in and out
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className='flex my-auto'>
                                                <div className='font-light text-lg mx-auto my-52'>
                                                    Sorry, we couldn't find this model
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    : 
                                    <img className='h-[300px] bg-base-200 p-2 rounded-md mx-auto 2xl:h-[500px] xl:h-[500px] lg:h-[500px] md:h-[400px] sm:h-[400px] object-cover' src={currentImage} alt={item.name} />

                                }
                                
                                {/* THUMBNAILS */}
                                <div className='mt-4 mx-auto flex flex-row 2xl:hidden xl:hidden lg:hidden md:flex sm:flex'>
                                    {item.imgUrls.map((url) => (
                                        <div key={url} className={`p-1 mx-2 rounded-md mr-0 mb-4 ${url == currentImage ? 'bg-neutral' : 'bg-base-200 opacity-50'}`}>
                                            <img src={url} alt='thumbnail' className='object-cover rounded-md w-10 h-10 hover:cursor-pointer' onClick={() => {
                                                    setCurrentImage(url)
                                                    setDisplay3D(false)
                                                    if (url == currentImage){
                                                        setObjectHasBeenRenderedFlag(false)
                                                }
                                            }}/>
                                        </div>
                                    ))}
                                    <div className={`bg-neutral mx-2 hover:cursor-pointer rounded-md p-1 mr-0 mb-4 ${display3D ? 'bg-neutral' : 'bg-base-300 opacity-50'}`}>
                                        <div className={`flex rounded-md w-10 h-10 hover:cursor-pointer ${display3D ? 'bg-base-100 ' : 'bg-base-200'}`} onClick={() => {
                                            setDisplay3D(true)
                                            setCurrentImage('')
                                        }}>
                                            <div className='font-light text-lg mx-auto my-auto text-center'>
                                                3D
                                            </div>
                                        </div>
                                    </div>
                                    {auth.currentUser != null ?
                                        <button className='btn btn-ghost mx-2 bg-base-200 hover:cursor-pointer' onClick = {updateFavorites}>
                                            {favorite == false ?
                                                <FiHeart size={15} />
                                                :
                                                <FaHeart size={15} />
                                            }
                                        </button>
                                        :
                                        <></>
                                    }
                                </div>

                                {/* REVIEWS */}
                                <div className='hidden 2xl:block xl:block lg:block md:hidden sm:hidden'>
                                    <div className='font-large text-2xl mt-10 mb-4 ml-4'>
                                        {reviews.length > 0 ?
                                            <>
                                            {reviews.length} Item Review{reviews.length > 1 ? 's' : ''}
                                            </>
                                            : <>No Reviews</>
                                        }
                                    </div>
                                    <div className='ml-8 flex-col'>
                                        {/* BOX TO ADD A REVIEW */}
                                        {auth.currentUser != null && allowedToReview ?
                                            auth.currentUser.uid != item.userId ?
                                                <div className='bg-base-200 rounded-lg p-4 mb-2 flex flex-col'>
                                                    {creatingReview == false ?
                                                        <div className='flex'>
                                                            <button className="btn btn-square btn-ghost" onClick={() => {
                                                                setCreatingReview(true)
                                                            }}>
                                                                <ImPlus size={20} />
                                                            </button>
                                                            <div className='font-light text-lg ml-4 my-auto'>
                                                                Have this item? Let people know what you thought!
                                                            </div>
                                                        </div>
                                                        : 
                                                        <>
                                                            <div className='flex'>
                                                                <button className="btn btn-square btn-ghost" onClick={() => {
                                                                    setCreatingReview(false)
                                                                }}>
                                                                    <FaTimes size={25} />
                                                                </button>
                                                                <div className='font-light text-lg ml-4 my-auto'>
                                                                    What would you rate this item?
                                                                </div>
                                                                <div className='flex ml-4 my-auto'>
                                                                    {[...Array(5).keys()].map((n) => (
                                                                        <>
                                                                            {n < userRating ?
                                                                                <RiStarSFill key={n} size={25} className='cursor-pointer' onClick = {() => setUserRating(n+1)}/>
                                                                                : <RiStarSLine key={n} size={25} className='cursor-pointer' onClick = {() => setUserRating(n+1)}/>
                                                                            }
                                                                        </>
                                                                    ))}
                                                                </div>
                                                                <button className='btn btn-primary ml-auto' onClick = {() => {
                                                                    submitReview()
                                                                    setCreatingReview(false)
                                                                    fetchReviews()
                                                                }}>Submit</button>
                                                            </div>
                                                            <textarea
                                                                required
                                                                minLength={1}
                                                                maxLength={500}
                                                                value={userReview}
                                                                onChange={(e) => setUserReview(e.target.value)}
                                                                placeholder="leave a review" 
                                                                id="description"
                                                                className="input textarea h-24 mt-4 textarea-primary textarea-bordered" 
                                                                type='text' 
                                                            ></textarea>
                                                        </>
                                                        
                                                    }
                                                </div>
                                                : <></>
                                            : <></>
                                        }
                                        <div className='mb-8'>
                                            {reviews.length > 0 ?
                                                reviews.map((review) => (
                                                    <Review key={review.id} id={review.id} review = {review.data}/>
                                                ))
                                            : <></>
                                            }    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='basis-1/3 flex flex-col mx-auto px-4'>
                            <div className="avatar">
                                <div className="rounded-md w-10 h-10 m-1">
                                    <Link to={`/profile/${item.userId}`}>
                                        <img src={user.profilePic} />
                                    </Link>
                                </div>
                                <Link to={`/profile/${item.userId}`}><p className='font-medium text-md mx-2 my-3'>{user.name}</p></Link>
                            </div>
                            <div className='font-large text-4xl my-4'>
                                {item.name}
                            </div>
                            <div className='font-medium text-2xl'>
                                {'$' + Number(item.prices[size]).toFixed(2).replace(/[.,]0$/, '')}
                            </div>
                            <div className='font-light text-lg my-4'>
                                {item.description}
                            </div>
                            <div className='flex flex-col rounded-lg p-4'>
                                <div className='flex my-auto'>
                                    <div className='font-medium text-xl'>Options</div>
                                    <div className='tooltip cursor-pointer p-1 ml-2 my-auto' data-tip={`Click to learn more`} onClick={() => {navigate('/order-options')}}>
                                        <VscQuestion size={25} />
                                    </div>
                                </div>
                                <div className='flex flex-wrap mx-auto 2xl:mx-0 xl:mx-0 lg:mx-0 md:mx-0 sm:mx-0'>
                                    <div className='font-light text-lg my-auto mx-2'>
                                        Size
                                    </div>
                                    <div className="dropdown dropdown-hover">
                                        <label tabIndex="0" className="m-2 btn font-medium pr-2 text-xs">
                                            {size}
                                            <RiArrowDropDownLine className='ml-2' size={20} />
                                        </label>
                                        <ul tabIndex="0" className="p-2 shadow menu dropdown-content bg-base-200 rounded-box w-52">
                                            <li key={'Small'}>
                                                <a className='flex' onClick = {() => setSize('Small')}>
                                                    <div className='font-medium text-md'>
                                                        Small
                                                    </div>
                                                    <div className='font-light text-xs ml-auto'>
                                                        8cm
                                                    </div>
                                                </a>
                                            </li>
                                            <li key={'Medium'}>
                                                <a className='flex' onClick = {() => setSize('Medium')}>
                                                    <div className='font-medium text-md'>
                                                        Medium
                                                    </div>
                                                    <div className='font-light text-xs ml-auto'>
                                                        12cm
                                                    </div>
                                                </a>
                                            </li>
                                            <li key={'Large'}>
                                                <a className='flex' onClick = {() => setSize('Large')}>
                                                    <div className='font-medium text-md'>
                                                        Large
                                                    </div>
                                                    <div className='font-light text-xs ml-auto'>
                                                        16cm
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='flex'>
                                        <div className='font-light text-lg my-auto mx-2'>
                                            Color
                                        </div>
                                        <div className="dropdown dropdown-hover dropdown-end 2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden">
                                            <label tabIndex="0" className={`m-2 btn font-medium ${color.hex}`}>
                                                <RiArrowDropDownLine size={20} />
                                            </label>
                                            <div tabIndex="0" className="dropdown-content grid grid-cols-2 gap-2 p-2 shadow menu bg-base-200 rounded-box w-64">
                                                {availableColors.map((color) => (
                                                    <li key={color.name} 
                                                        className={`rounded-md font-light text-xs p-2 text-center cursor-pointer ${color.hex} ${color.text} hover:brightness-125`} 
                                                        onClick={() => setColor(color)}
                                                    >
                                                        {color.name}
                                                    </li>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="dropdown dropdown-hover hidden 2xl:block xl:block lg:block md:block sm:block">
                                            <label tabIndex="0" className={`m-2 btn font-medium ${color.hex}`}>
                                                <RiArrowDropDownLine size={20} />
                                            </label>
                                            <div tabIndex="0" className="dropdown-content grid grid-cols-2 gap-2 p-2 shadow menu bg-base-200 rounded-box w-64">
                                                {availableColors.map((color) => (
                                                    <li key={color.name} 
                                                        className={`rounded-md font-light text-xs p-2 text-center cursor-pointer ${color.hex} ${color.text} hover:brightness-125`} 
                                                        onClick={() => setColor(color)}
                                                    >
                                                        {color.name}
                                                    </li>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='hidden 2xl:flex xl:flex lg:flex md:flex sm:flex'>
                                        <div className='font-light text-lg my-auto mx-2'>
                                            Quantity
                                        </div>
                                        <div className="dropdown dropdown-hover">
                                            <button tabIndex="0" className="m-2 btn font-medium pr-2">
                                                {quantity}
                                                <RiArrowDropDownLine className='ml-2' size={20} />
                                            </button>
                                            <ul tabIndex="0" className="p-2 shadow menu dropdown-content bg-base-200 rounded-box w-52">
                                                {[...Array(5).keys()].map((n) => (
                                                    <li key={n}><a onClick = {() => setQuantity(n+1)}>{n+1}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='font-medium text-xl mt-4'>Add a Note</div>
                                <div className='font-light text-sm my-2'>
                                    Include any custom details or messages which may come with the item
                                </div>
                                <textarea
                                    required
                                    minLength={1}
                                    maxLength={500}
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                    placeholder="leave a note" 
                                    id="description"
                                    className="input textarea h-24 mx-2 mt-4 textarea-primary textarea-bordered col-span" 
                                    type='text' 
                                ></textarea>
                            </div>
                            <div className='flex flex-col mx-auto mb-4 2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden'>
                                <div className='font-light text-lg mx-auto mb-4'>
                                    Quantity
                                </div>
                                <div className='flex flex-wrap mx-auto mb-4'>
                                    <button className='btn btn-neutral'
                                        onClick = {() => {
                                            if (quantity > 1){
                                                setQuantity(quantity-1)
                                            }
                                        }
                                    }>-</button>
                                    <div className="btn btn-neutral font-bold text-xl mx-2 no-animation">
                                        {quantity}
                                    </div>
                                    <button className='btn btn-neutral'
                                        onClick = {() => {
                                            setQuantity(quantity+1)
                                        }
                                    }>+</button>
                                </div>
                            </div>
                            <div className='flex flex-col mx-auto'>
                                <div className='my-2'>
                                    <button className='btn btn-neutral w-72' onClick={() => addItemToCart('cart')}>Add to Cart</button>
                                </div>
                                <div className='my-2 mb-8'>
                                    <button className='btn btn-primary w-72' onClick={() => addItemToCart('checkout')}>Buy Now</button>
                                </div>
                            </div>
                        </div>
                        <div className='mx-4 flex flex-col 2xl:hidden xl:hidden lg:hidden md:flex sm:flex'>
                            <div className='font-large text-2xl mt-10 mb-4 mx-auto 2xl:ml-4 xl:ml-4 lg:ml-4 md:ml-4 sm:mx-auto'>
                                {reviews.length > 0 ?
                                    <>
                                    {reviews.length} Item Review{reviews.length > 1 ? 's' : ''}
                                    </>
                                    : <>No Reviews</>
                                }
                            </div>
                            <div className='flex-col'>
                                {/* BOX TO ADD A REVIEW */}
                                {auth.currentUser != null && allowedToReview ?
                                    auth.currentUser.uid != item.userId ?
                                        <div className='bg-base-200 rounded-lg p-4 mb-2 flex flex-col'>
                                            {creatingReview == false ?
                                                <div className='flex'>
                                                    <button className="btn btn-square btn-ghost" onClick={() => {
                                                        setCreatingReview(true)
                                                    }}>
                                                        <ImPlus size={20} />
                                                    </button>
                                                    <div className='font-light text-lg ml-4 my-auto'>
                                                        Have this item? Let people know what you thought!
                                                    </div>
                                                </div>
                                                : 
                                                <>
                                                    <div className='flex'>
                                                        <button className="btn btn-square btn-ghost" onClick={() => {
                                                            setCreatingReview(false)
                                                        }}>
                                                            <FaTimes size={25} />
                                                        </button>
                                                        <div className='font-light text-lg ml-4 my-auto'>
                                                            What would you rate this item?
                                                        </div>
                                                        <div className='flex ml-4 my-auto'>
                                                            {[...Array(5).keys()].map((n) => (
                                                                <>
                                                                    {n < userRating ?
                                                                        <RiStarSFill key={n} size={25} className='cursor-pointer' onClick = {() => setUserRating(n+1)}/>
                                                                        : <RiStarSLine key={n} size={25} className='cursor-pointer' onClick = {() => setUserRating(n+1)}/>
                                                                    }
                                                                </>
                                                            ))}
                                                        </div>
                                                        <button className='btn btn-primary ml-auto' onClick = {() => {
                                                            submitReview()
                                                            setCreatingReview(false)
                                                            fetchReviews()
                                                        }}>Submit</button>
                                                    </div>
                                                    <textarea
                                                        required
                                                        minLength={1}
                                                        maxLength={500}
                                                        value={userReview}
                                                        onChange={(e) => setUserReview(e.target.value)}
                                                        placeholder="leave a review" 
                                                        id="description"
                                                        className="input textarea h-24 mt-4 textarea-primary textarea-bordered" 
                                                        type='text' 
                                                    ></textarea>
                                                </>
                                                
                                            }
                                        </div>
                                        : <></>
                                    : <></>
                                }
                                <div className='mb-8'>
                                    {reviews.length > 0 ?
                                        reviews.map((review) => (
                                            <Review key={review.id} id={review.id} review = {review.data}/>
                                        ))
                                    : <></>
                                    }    
                                </div>
                            </div>
                        </div>
                    </>
                :
                <div className='flex flex-col bg-base-200 rounded-lg m-4 p-8 2xl:mx-auto xl:mx-auto lg:mx-auto md:mx-auto
                2xl:my-auto xl:my-auto lg:my-auto md:my-auto'>
                    <div className='font-bold text-3xl mx-auto'>
                        This item is hidden.
                    </div>
                    <div className='font-light text-xl text-center mx-auto mt-8'>
                        The creator of this item has decided to temporarily remove the <br/> item from the store in order to make some changes.
                        Please check back later!
                    </div>
                    <button className='btn btn-primary center mx-auto mt-8 w-64' onClick = {() => navigate('/')}>
                        Keep Shopping
                    </button>
                </div>
                }
            </div>
            {item.hidden == false || (auth.currentUser != null ? auth.currentUser.uid == item.userId : false) ?
                <div className='flex flex-col container mt-4 mb-8 mx-auto'>
                    <div className='font-large text-2xl mb-4 mx-auto 2xl:ml-4 xl:ml-4 lg:ml-4 md:ml-4 sm:mx-auto'>
                        More from this creator
                    </div>
                    {/* <div className='hidden 2xl:flex xl:flex'>
                        {relatedItems.slice(0,3).map((item) => (
                                <div className="m-2 hidden 2xl:hidden xl:hidden lg:block md:block">
                                    <ItemCard key={item.id} id={item.id} item={item.data} />
                                </div>
                            ))}
                    </div> */}
                    
                    {relatedItems.length > 0 ? 
                        <>
                            <div className='grid grid-cols-2 gap-2 mx-4 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2
                            2xl:gap-4 xl:gap-4 lg:gap-4 md:gap-4 sm:gap-4 2xl:ml-8 xl:ml-8 lg:ml-8 md:mx-4 sm:mx-4'>
                                {relatedItems.map((item) => (
                                    <ItemCard key={item.id} id={item.id} item={item.data}/>
                                ))}
                            </div>
                            
                        </> : <p>No items found</p>
                    }
                    <label for="my-modal" class="btn btn-ghost font-light text-sm mt-8 mx-auto w-52 modal-button">
                        Report this item
                    </label>
                    <input type="checkbox" id="my-modal" className="modal-toggle"/>
                    <div className="modal">
                        <div className="modal-box flex flex-col">
                            <div className="font-bold text-lg text-white">
                                Please describe why you are reporting this item:
                            </div>
                            <textarea
                                required
                                minLength={1}
                                maxLength={500}
                                value={report}
                                onChange={(e) => setReport(e.target.value)}
                                placeholder="report note" 
                                id="report"
                                className="input textarea w-full h-24 mt-4 textarea-primary textarea-bordered" 
                                type='text' 
                            ></textarea>
                            <div className='font-light text-sm mx-2 mt-2'>
                                If this item is your intellectual property, we encourage you to message this creator directly. These disputes can often be resolved
                                without involving Layolay.
                            </div>
                            <div className='flex mt-4'>
                                <VscQuestion size={25} className='ml-2 my-auto hover:cursor-pointer' onClick={() => navigate('/prohibited-items')} />
                                <div className='font-light text-sm my-auto mx-4 underline hover:cursor-pointer' onClick={() => navigate('/prohibited-items')} >
                                    Learn more about out Layolay's prohibited items policy
                                </div>
                                <div className="modal-action my-auto">
                                    <label for="my-modal" className="btn btn-error text-white" onClick={() => {
                                        if (!reported){
                                            addDoc(collection(db, 'reports'), {
                                                itemId: params.itemId,
                                                reportBody: report
                                            })
                                            //console.log()
                                        }
                                        setReported(true)
                                    }}>Report</label>
                                    <label for="my-modal" className="btn btn-info text-white">Cancel</label>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                : <></>
                }
        </>
    )
}

export default Item