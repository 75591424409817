import { FiUser } from "react-icons/fi"
import { MdOutlineEmail } from 'react-icons/md'
import { BiLockAlt } from "react-icons/bi"
import { v4 as uuidv4} from 'uuid'
import {RiEyeCloseFill, RiEyeFill} from 'react-icons/ri'
import { useState, useRef, useEffect } from "react"
import { getAuth, createUserWithEmailAndPassword, updateProfile, sendEmailVerification, onAuthStateChanged } from 'firebase/auth'
import { db } from '../firebase.config'
import { setDoc, doc, serverTimestamp } from "firebase/firestore"
import { useNavigate } from "react-router-dom"
import SignInCard from "../components/SignInCard"
import { FaCheck, FaTimes } from "react-icons/fa"
import { ImWarning } from 'react-icons/im'
import { IoAlertCircleOutline } from 'react-icons/io5'

const default1 = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/defaultProfilePics%2Fdefault-1.png?alt=media&token=22d99789-e34a-4fff-b396-ce9dfa413c57'
const default2 = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/defaultProfilePics%2Fdefault-2.png?alt=media&token=8dae40c6-eea4-4fd9-914f-5614280a1525'
const default3 = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/defaultProfilePics%2Fdefault-3.png?alt=media&token=6b86b83c-4db6-4999-8108-ac6ee417399e'
const default4 = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/defaultProfilePics%2Fdefault-4.png?alt=media&token=15fec291-ff71-44e2-9837-0dec55d5037c'
const default5 = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/defaultProfilePics%2Fdefault-5.png?alt=media&token=1f82522a-4acb-438a-ad4d-52ed6f8f19ef'
const default6 = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/defaultProfilePics%2Fdefault-6.png?alt=media&token=b5a70ab7-4700-47d1-8832-69c4d8b224c4'
const default7 = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/defaultProfilePics%2Fdefault-7.png?alt=media&token=4fece556-a389-4b6b-99cb-1ef1a9a92861'
const default8 = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/defaultProfilePics%2Fdefault-8.png?alt=media&token=6b200e0c-17a4-4e64-b0ba-a7a95d9424b6'
const default9 = 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/defaultProfilePics%2Fdefault-9.png?alt=media&token=0968aef4-6d26-4683-9058-ddfc9abf8351'

const defaultPics = [default1, default2, default3, default4, default5, default6, default7, default8, default9]

function SignUp() {

    const [showPassword, setShowPassword] = useState(false)
    const [displayLogInError, setDisplayLogInError] = useState(false)
    const [userDefaults, setUserDefaults] = useState({
        creator: false,
        bio: '',
        profilePic: defaultPics[Math.floor(Math.random()*defaultPics.length)],
        profilePicReference: '',
        bannerPic: 'https://firebasestorage.googleapis.com/v0/b/d-ecommerce-website-23a39.appspot.com/o/defaultProfilePics%2Fspace-banner.jpg?alt=media&token=6dc6662e-a177-4fff-b57c-797941fb4759',
        bannerPicReference: '',
        numSales: 0,
        numItems: 0,
        numRatings: 0,
        totalRating: 0,
        itemIds: [],
        // uuid: uuidv4(),
        settings: {
            email: {
                handle: '',
                permission: false,
            },
            twitter: {
                handle: '',
                permission: false,
            },
            instagram: {
                handle: '',
                permission: false,
            },
            facebook: {
                handle: '',
                permission: false,
            },
            youtube: {
                handle: '',
                permission: false,
            },
            experience: {
                handle: '',
                permission: false,
            },
            softwareUsed: {
                handle: '',
                permission: false,
            },
            profession: {
                handle: '',
                permission: false,
            },
        }
    })

    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState(false)
    const [rePassword, setRePassword] = useState('')
    const [rePasswordError, setRePasswordError] = useState(false)
    const [displayCapsLockWarning, setDisplayCapsLockWarning] = useState(false)

    const navigate = useNavigate()

    const isMounted = useRef(true)
    const auth = getAuth()

    useEffect(() => {
        document.title = 'Layolay | Sign Up'
    }, [])

    useEffect(() => {

        if(isMounted){
            onAuthStateChanged(auth, (user) => {
                console.log(user)
                if(user) {
                    navigate('/home')
                } 
            })
        }

        return () => {
            isMounted.current = false
        }

    }, [isMounted])

    const onSubmit = async (e) => {
        e.preventDefault()
        if (name == ''){
            setNameError(true)
        }
        if (email == ''){
            setEmailError(true)
        }
        if (password == ''){
            setPasswordError(true)
        }
        if (rePassword == ''){
            setRePasswordError(true)
        }

        if (name != '' && email != '' && password != '' && rePassword != '' && (password == rePassword)){
            const newUser = {
                name: name,
                ...userDefaults
            }
            
            try {
                const auth = getAuth()
    
                const createUserInDatabase = async () => {
                    const userCredential = await createUserWithEmailAndPassword(auth, email, password)
                    const user = userCredential.user
                    
                    updateProfile(auth.currentUser, {
                        displayName: newUser.name,
                        photoURL: newUser.profilePic,
                    })
    
                    //sendEmailVerification(auth.currentUser)
                    newUser.timestamp = serverTimestamp()
        
                    await setDoc(doc(db, 'users', user.uid), newUser)
    
                    const newUserPrivate = {
                        userId: user.uid,
                        alerts: [],
                        favorites: [],
                        cart: [],
                        orders: [],
                        earnings: 0,
                        allTimeRevenue: 0,
                        settings: {
                            email: {
                                handle: email,
                                permission: false,
                            },
                            twitter: {
                                handle: '',
                                permission: false,
                            },
                            instagram: {
                                handle: '',
                                permission: false,
                            },
                            facebook: {
                                handle: '',
                                permission: false,
                            },
                            youtube: {
                                handle: '',
                                permission: false,
                            },
                            experience: {
                                handle: '',
                                permission: false,
                            },
                            softwareUsed: {
                                handle: '',
                                permission: false,
                            },
                            profession: {
                                handle: '',
                                permission: false,
                            },
                        }
                    }
    
                    await setDoc(doc(db, 'private', user.uid), newUserPrivate)
                    navigate('/home')
                }

                createUserInDatabase()

            } catch (error) {
                setDisplayLogInError(true)
            }
        }
    }
    
    const checkForSignUpButton = (e) => {
        if (name != '' && email != '' && password != '' && rePassword != '' && (password === rePassword)){
                return false
            } else {
                return true
            }
    }

    const signUpButtonDisabled = (name != '' && email != '' && password != '' && rePassword != '' && (password === rePassword)) ? false : true

    const eyeDisplay = (showPassword ? <RiEyeFill size={25} /> : <RiEyeCloseFill size={25} />)

    const passwordSameCheck = (
        (password != '' && rePassword != '') ?
            (password == rePassword ? 
                <div className='flex rounded-lg mx-auto text-info mt-4'>
                    <FaCheck color='#2094f3' className='self-center mx-3'/>
                    <span className='self-center'>Passwords match</span>
                </div> 
            :  
                <div className='flex rounded-lg mx-auto text-error mt-4'>
                    <FaTimes color="'#ff5724'" className='self-center mx-3 pr-' />
                        <span>Passwords don't match</span>
                </div>)
        :
        <></>
    )

    const checkForCaps = (e) => { 
        if (e.getModifierState("CapsLock")) {
            setDisplayCapsLockWarning(true)
        } else {
            setDisplayCapsLockWarning(false)
        }
    }

    const emailInput = [
        <input 
            placeholder="Email" 
            id="email" 
            value={email} 
            onChange={(e) => {
                setEmail(e.target.value)
                setEmailError(false)
            }} 
            className={`input w-full input-bordered col-span ${emailError ? 'input-error' : 'input-primary'}`}
            type="email" 
        />
    ]

    const usernameInput = [
        <input 
            placeholder="Username" 
            id="name" 
            value={name} 
            onChange={(e) => {
                setName(e.target.value)
                setNameError(false)
            }} 
            className={`input w-full input-bordered col-span ${nameError ? 'input-error' : 'input-primary'}`}
            type="username" 
        />
    ]

    const passwordInput = [
        <input 
            placeholder="Password" 
            id="password" 
            value={password} 
            onChange={(e) => {
                setPassword(e.target.value)
                setPasswordError(false)
            }}  
            className={`input w-full input-bordered col-span ${passwordError ? 'input-error' : 'input-primary'}`} 
            type={showPassword ? 'text' : 'password'} 
            onKeyDown={(checkForCaps)}
        />
    ]

    const rePasswordInput = [
        <input 
            placeholder="Re-Type Password" 
            id="rePassword" 
            value={rePassword}
            onChange={(e) => {
                setRePassword(e.target.value)
                setRePasswordError(false)
            }}
            className={`input w-full input-bordered col-span ${rePasswordError ? 'input-error' : 'input-primary'}`}
            type={showPassword ? 'text' : 'password'}
            onKeyDown={(checkForCaps)}
        />
    ]


    return (
        
            <div className="flex flex-row p-4 2xl:m-auto xl:m-auto lg:m-auto md:m-auto sm:m-auto">
                <div className='p-8 card bg-base-200 basis-full 2xl:basis-3/4 xl:basis-3/4 lg:basis-3/4 md:basis-full sm:basis-full'>
                    <div className='flex justify-between'>
                        <h6 className='pb-6 font-bold text-2xl'>Sign Up</h6>   
                        <button className='btn btn-primary block 2xl:hidden xl:hidden lg:hidden md:block sm:block' onClick={() => {navigate('/sign-in')}}>Sign In <br/> Instead</button>
                    </div>
                    <form onSubmit={onSubmit}>
                        <div className="form-control pb-4">
                            <label className="label">
                                <span className="label-text">Email</span>
                            </label>
                            <div className="input-group">
                                <span><MdOutlineEmail size={18}/></span>
                                {emailInput}
                            </div>
                        </div>
                        
                        <div className="form-control pb-4">
                            <label className="label">
                                <span className="label-text">Username</span>
                            </label>
                            <div className="input-group">
                                <span><FiUser size={18}/></span>
                                {usernameInput}
                            </div>
                        </div>

                        <div className="form-control pb-4 w-full">
                            <label className="label">
                                <span className="label-text">Password</span>
                            </label>
                                <div className="flex space-x-2">
                                    <div className="input-group">
                                        <span className=""><BiLockAlt size={18}/></span>
                                        {passwordInput}
                                    </div>
                                    <button type='button' onClick={() => setShowPassword((prevState => !prevState))} className="btn btn-primary">{eyeDisplay}</button>
                                </div>
                        </div>

                        <div className="form-control pb-4 w-full">
                            <label className="label">
                                <span className="label-text">Re-Type Password</span>
                            </label>
                                <div className="flex space-x-2">
                                    <div className="input-group">
                                        <span className=""><BiLockAlt size={18}/></span>
                                        {rePasswordInput}
                                    </div>
                                    <button type="button" onClick={() => setShowPassword((prevState => !prevState))} className="btn btn-primary">{eyeDisplay}</button>
                                </div> 
                        </div>
                        <p className={`flex ${displayCapsLockWarning ? 'visible' : 'invisible'} text-warning`}><ImWarning size={18} className="self-center mr-2"/> Caps Lock is on!</p>
                        {passwordSameCheck}
                        <div className="flex mt-6 justify-between">
                            {displayLogInError ? 
                                <div className='flex rounded-lg text-error mr-8'><IoAlertCircleOutline size={25} color="'#ff5724'" className='self-center mx-2 my-1' /> <span className='self-center mr-2'>An error occured</span></div>
                                :
                                <div></div>
                            }
                            <button type="submit" className='btn btn-primary basis-1/4' disabled={signUpButtonDisabled}>Sign Up</button>
                        </div>
                    </form>
                </div>
                <div className='ml-2 hidden basis-0 2xl:basis-1/4 xl:basis-1/4 lg:basis-1/4 md:basis-0 sm:basis-0 2xl:block xl:block lg:block md:hidden sm:hidden'>
                    <SignInCard />
                </div>
            </div>


    )
}

export default SignUp
