import { VscQuestion } from 'react-icons/vsc'
import { FaGift } from 'react-icons/fa'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { Link, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../firebase.config'
import { useContext } from 'react'
import CustomerContext from '../context/user/CustomerContext'
import { Cookies } from 'react-cookie'
import Spinner from '../components/Spinner'
import { BiDuplicate } from 'react-icons/bi'
import { v4 as uuidv4} from 'uuid'

function CartItem({cartItem: {cartItemId, itemId, userId, prices, quantity, options}}) {

    const navigate = useNavigate()

    const cookies = new Cookies()
    const { cartTotal, addItem, removeItem, updateItem, getTotal, getSize } = useContext(CustomerContext)

    const [loadingItem, setLoadingItem] = useState(true)
    const [loadingUser, setLoadingUser] = useState(true)

    const [item, setItem] = useState(null)
    const [user, setUser] = useState(null)

    const [size, setSize] = useState(options.size)
    const [color, setColor] = useState(options.color)
    const [note, setNote] = useState(options.note)
    const [quant, setQuant] = useState(quantity)

    const [isGift, setIsGift] = useState(options.gift)

    // https://www.prusa3d.com/product/prusament-pla-vanilla-white-1kg/
    // https://www.prusa3d.com/product/prusament-pla-prusa-galaxy-black-1kg/
    // https://www.hatchbox3d.com/products/3d-pla-1kg1-75-silk-lblu
    // https://www.prusa3d.com/product/prusament-pla-royal-blue-blend-970g/
    // https://www.filacube.com/products/1-75mm-1kg-orange-pms-159c-filacube-3d-printer-pla-2-filament-burnt-orange-longhorn-long-horn-ut-university-of-texas-austin-pms-159
    // https://www.filacube.com/products/1-75mm-1kg-black-filacube-3d-printer-pla-2-filament
    // https://www.prusa3d.com/product/prusament-pla-galaxy-silver-1kg/
    // https://www.prusa3d.com/product/prusament-pla-opal-green-1kg/

    const availableColors = [
        {name: 'Galaxy Black', hex: 'bg-[#2c2e3a]', text: 'text-white'},
        {name: 'Galaxy Purple', hex: 'bg-[#483470]', text: 'text-white'},
        {name: 'Hatchbox Black', hex: 'bg-[#383934]', text: 'text-white'},
        {name: 'Vanilla White', hex: 'bg-[#dad5d1]', text: 'text-black'},
        {name: 'Royal Blue', hex: 'bg-[#2b4ea1]', text: 'text-white'},
        {name: 'Silk Light Blue', hex: 'bg-[#a1b4e5]', text: 'text-black'},
        {name: 'Silver', hex: 'bg-[#7d787c]', text: 'text-white'},
        {name: 'Gold', hex: 'bg-[#ceb61e]', text: 'text-black'},
        {name: 'Hatchbox Red', hex: 'bg-[#bd131b]', text: 'text-white'},
        {name: 'Burnt Orange', hex: 'bg-[#e97418]', text: 'text-white'},
        {name: 'Opal Green', hex: 'bg-[#05654a]', text: 'text-white'},
    ]

    const fetchItem = async () => {
        const docRef = doc(db, 'items', itemId)
        const docSnap = await getDoc(docRef)
        
        // Update the cartTotal in the context
        if(docSnap.exists()){
            setItem(docSnap.data())
        }
        setLoadingItem(false)
        
    }

    const fetchUser = async () => {
        const docRef = doc(db, 'users', userId)
        const docSnap = await getDoc(docRef)
        
        if(docSnap.exists()){
            setUser(docSnap.data())
        }
        setLoadingUser(false)
    }

    useEffect(() => {

        fetchItem()
        fetchUser()
        
    }, [itemId, userId])

    useEffect(() => {
        updateItem({
            cartItemId: cartItemId,
            itemId: itemId,
            userId: userId,
            quantity: quant,
            prices: prices,
            options: {
                size: size,
                color: color,
                note: note,
                gift: isGift,
            }
        })
    }, [size, color, note, quant, isGift])


    const removeItemFromCart = () => {
        removeItem(cartItemId)
        getTotal()
        getSize()
    }

    if (loadingItem || loadingUser){
        return (
            <div className='flex bg-base-200 p-4 rounded-2xl my-2'>
                <div className='mx-auto my-8 h-1/6 w-1/6'>
                    <Spinner />
                </div>
            </div>
        )
    }

    if (item == null && !loadingItem){
        removeItem(itemId)
        return (<></>)
    }

    return (
        <div className='mt-4'>
            <div className='flex bg-base-200 p-4 rounded-2xl flex-col 2xl:flex-row xl:flex-row lg:flex-col md:flex-col sm:flex-col'>
                <div className='basis-1/4 flex flex-row 2xl:flex-col xl:flex-col lg:flex-row md:flex-row sm:flex-row'>
                    <div className='flex bg-base-300 p-2 ml-0 rounded-md mb-2 basis-1/2 2xl:basis-1 xl:basis-1 lg:basis-1/3 md:basis-1/2 sm:basis-1/2'>
                        <img src={item.imgUrls[0]} alt='thumbnail' className='rounded-md object-cover hover:cursor-pointer' onClick={() => {navigate('/item/'+itemId)}}/>
                    </div>
                    <div className='flex flex-col ml-4 basis-1/2 2xl:basis-1 xl:basis-1 lg:basis-2/3 md:basis-1/2 sm:basis-1/2'>
                        <div className='font-medium text-xl mb-2'>
                            {item.name}
                        </div>
                        <div className="avatar">
                            <div className="rounded-md w-10 h-10 m-1">
                                <Link to={`/profile/${item.userId}`}>
                                    <img src={user.profilePic} />
                                </Link>
                            </div>
                            <Link to={`/profile/${item.userId}`}><p className='font-medium text-md mx-2 my-3'>{user.name}</p></Link>
                        </div>
                        <div className='font-medium text-lg mt-4 hidden 2xl:hidden xl:hidden lg:block md:block sm:block'>
                            Description
                        </div>
                        <div className='font-light text-md m-2 hidden 2xl:hidden xl:hidden lg:block md:block sm:block'>
                            {item.description}
                        </div>
                    </div>
                </div>
                <div className='basis-1/2 flex flex-col rounded-lg p-2 2xl:ml-4 xl:ml-4 lg:ml-4 md:ml-4 sm:ml-4
                2xl:p-4 xl:p-4 lg:px-4 md:p-4 sm:p-4'>
                    <div className='font-medium text-lg hidden 2xl:block xl:block lg:hidden md:hidden sm:hidden'>
                        Description
                    </div>
                    <div className='font-light text-md m-2 hidden 2xl:block xl:block lg:hidden md:hidden sm:hidden'>
                        {item.description}
                    </div>
                    <div className='flex my-auto'>
                        <div className='font-medium text-lg'>Options</div>
                        <div className='tooltip cursor-pointer p-1 ml-2 my-auto' data-tip={`Click to learn more`} onClick={() => {navigate('/order-options')}}>
                            <VscQuestion size={25} />
                        </div>
                    </div>
                    <div className='flex flex-wrap mx-auto 2xl:ml-2 xl:ml-2 lg:ml-2 md:ml-2 sm:ml-2'>
                        <div className='flex'>
                            <div className='font-light text-sm my-auto mx-2 hidden 2xl:block xl:block lg:block md:block sm:block'>
                                Size
                            </div>
                            <div className="dropdown dropdown-hover">
                                <label tabIndex="0" className="m-2 btn font-medium pr-2">
                                    <div className='text-xs'>
                                        {size}
                                    </div>
                                    <RiArrowDropDownLine className='ml-2' size={20} />
                                </label>
                                <ul tabIndex="0" className="p-2 shadow menu dropdown-content bg-base-200 rounded-box w-52">
                                    <li key={'Small'}>
                                        <a className='flex' onClick = {() => {
                                                setSize('Small')
                                            }}>
                                            <div className='font-medium text-md'>
                                                Small
                                            </div>
                                            <div className='font-light text-xs ml-auto'>
                                                8cm
                                            </div>
                                        </a>
                                    </li>
                                    <li key={'Medium'}>
                                        <a className='flex' onClick = {() => {
                                                setSize('Medium')
                                            }}>
                                            <div className='font-medium text-md'>
                                                Medium
                                            </div>
                                            <div className='font-light text-xs ml-auto'>
                                                12cm
                                            </div>
                                        </a>
                                    </li>
                                    <li key={'Large'}>
                                        <a className='flex' onClick = {() => {
                                                setSize('Large')
                                            }}>
                                            <div className='font-medium text-md'>
                                                Large
                                            </div>
                                            <div className='font-light text-xs ml-auto'>
                                                16cm
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* had to duplicate this to change the dropdown */}
                        <div className='flex 2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden'>
                            <div className='font-light text-sm my-auto mx-2 hidden 2xl:block xl:block lg:block md:block sm:block'>
                                Color
                            </div>
                            <div className="dropdown dropdown-hover dropdown-end">
                                <label tabIndex="0" className={`m-2 btn font-medium ${color.hex}`}>
                                    <RiArrowDropDownLine size={20} />
                                </label>
                                <div tabIndex="0" className="dropdown-content grid grid-cols-2 gap-2 p-2 shadow menu bg-base-200 rounded-box w-60
                                    2xl:w-64 xl:w-64 lg:w-64 md:w-64 sm:w-64">
                                    {availableColors.map((color) => (
                                        <li key={color.name} 
                                            className={`rounded-md font-light text-xs p-2 text-center cursor-pointer ${color.hex} ${color.text} hover:brightness-125`} 
                                            onClick={() => setColor(color)}
                                        >
                                            {color.name}
                                        </li>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='hidden 2xl:flex xl:flex lg:flex md:flex sm:flex'>
                            <div className='font-light text-sm my-auto mx-2 hidden 2xl:block xl:block lg:block md:block sm:block'>
                                Color
                            </div>
                            <div className="dropdown dropdown-hover dropdown-end">
                                <label tabIndex="0" className={`m-2 btn font-medium ${color.hex}`}>
                                    <RiArrowDropDownLine size={20} />
                                </label>
                                <div tabIndex="0" className="dropdown-content grid grid-cols-2 gap-2 p-2 shadow menu bg-base-200 rounded-box w-60
                                    2xl:w-64 xl:w-64 lg:w-64 md:w-64 sm:w-64">
                                    {availableColors.map((color) => (
                                        <li key={color.name} 
                                            className={`rounded-md font-light text-xs p-2 text-center cursor-pointer ${color.hex} ${color.text} hover:brightness-125`} 
                                            onClick={() => setColor(color)}
                                        >
                                            {color.name}
                                        </li>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='font-light text-md my-auto mx-2 hidden 2xl:block xl:block lg:block md:block sm:block'>
                            {/* This is a gift */}
                            Gift
                        </div>
                        <div className='tooltip' data-tip={"Price hidden when packaging"}>
                            <button className={`btn w-12 h-12 m-2 ${isGift ? 'bg-pink-500 hover:bg-pink-500' : ''}`} onClick = {() => setIsGift(!isGift)}><FaGift size={20}/></button>
                        </div>
                    </div>
                    <div className='font-medium text-xl mt-4'>Add a Note</div>
                    <div className='font-light text-sm my-2'>
                        Include any custom details or messages which may come with the item
                    </div>
                    <textarea
                        required
                        minLength={1}
                        maxLength={500}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        placeholder="leave a note"
                        id="description"
                        className="input textarea w-[95%] h-12 m-2 textarea-primary textarea-bordered col-span" 
                        type='text'
                    ></textarea>
                </div>
                <div className='basis-1/4 rounded-lg 2xl:ml-4 xl:ml-4 lg:ml-4 md:ml-4 sm:p-4 2xl:p-4 xl:p-4 lg:p-4 md:p-4 sm:p-4'>
                    <div className='flex flex-col 2xl:flex-col xl:flex-col lg:flex-row md:flex-row sm:flex-col'>
                        <div className='flex flex-col order-2 2xl:order-1 xl:order-1 lg:order-1 md:order-1 sm:order-1
                        my-auto mx-auto'>
                            <div className='font-medium text-lg mx-auto mt-2'>
                                Price for {quantity} item{quantity > 1 ? 's' : ''}
                            </div>
                            <div className='mx-auto mb-4'>
                                <div className='font-bold text-2xl mx-auto'>
                                    $ {Number(item.prices[size] * quantity).toFixed(2).replace(/[.,]0$/, '')}
                                </div>
                                <div className={`font-light text-md mx-auto px-auto ${quant > 1 ? '' : 'invisible'}`}>
                                    {
                                        quant > 1 ?
                                        `($${Number(item.prices[size]).toFixed(2).replace(/[.,]0$/, '')} each)`
                                        : <>&nbsp;</>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col order-1 my-4 mx-auto 2xl:order-1 xl:order-1 lg:order-1 md:order-1 sm:order-1
                        2xl:my-0 xl:my-0 lg:my-auto md:my-0 sm:my-0'>
                            <div className='font-medium text-lg mx-auto my-4 hidden 2xl:block xl:block lg:block md:block sm:block'>
                                Quantity
                            </div>
                            <div className='flex flex-wrap mx-auto mb-0'>
                                <button className='btn btn-neutral'
                                    onClick = {() => {
                                        if (quant > 1){
                                            setQuant(quant-1)
                                        }
                                    }
                                }>-</button>
                                <div className="dropdown dropdown-hover">
                                    <label tabIndex="0" className="btn font-bold text-xl pr-2 mx-2 mb-2">
                                        {quantity}
                                        <RiArrowDropDownLine className='ml-2' size={20} />
                                    </label>
                                    <ul tabIndex="0" className="p-2 shadow menu dropdown-content bg-base-200 rounded-box w-52">
                                        {[...Array(5).keys()].map((n) => (
                                            <li key={n}>
                                                <a onClick = {() => 
                                                    {
                                                        setQuant(n+1)
                                                    }
                                                }>{n+1}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <button className='btn btn-neutral'
                                    onClick = {() => {
                                        setQuant(quant+1)
                                    }
                                }>+</button>
                            </div>
                        </div>
                        <div className='flex flex-col order-last mx-auto my-4'>
                            {/* <button className='btn btn-neutral mx-auto my-2'>Save for Later</button> */}
                            <button className='btn btn-neutral mx-auto my-auto' onClick = {removeItemFromCart}>Remove</button>
                            <div className='flex mt-6'>
                                <button className='btn btn-neutral mx-auto my-auto' onClick = {() => {
                                    const newItem = {
                                        cartItemId: uuidv4(),
                                        itemId: itemId,
                                        userId: userId,
                                        prices: prices,
                                        quantity: quantity,
                                        options: {
                                            size: size,
                                            color: color,
                                            note: '',
                                            gift: false,
                                        },
                                    }
                            
                                    // Update context
                                    addItem(newItem)
                                }}>
                                    <BiDuplicate size={25} />
                                </button>
                                <div className='font-light text-xs my-auto ml-2'>
                                    Duplicate with <br/> different options
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartItem