import Spin from '../media/LayolaySpinner.gif'

function Spinner() {
    return (
        <div >
            <img src={Spin} alt="" />
        </div>
    )
}

export default Spinner
