import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

function Alerts() {

    const navigate = useNavigate()

    useEffect(() => {
        document.title = 'Layolay | Alerts'
    }, [])

    return (
        <div className='flex flex-col m-4 bg-base-200 rounded-lg 2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[90%]
        2xl:mx-auto xl:mx-auto lg:mx-auto md:mx-auto 2xl:my-8 xl:my-8 lg:my-8 md:my-8'>
            <div className='card p-8'>
                <div className='font-bold text-2xl'>
                    Notifications
                </div>
                <div className='flex flex-col mt-4 mx-auto'>
                    <div className='font-light text-md mx-auto'>
                        You have no new notifications.
                    </div>
                    <div className='font-light text-md mx-auto'>
                        Favorite items to get notified when they go on sale or have other special deals.
                    </div>
                    <button className='btn btn-primary center mx-auto mt-4 w-64' onClick = {() => navigate('/favorites')}>
                        Visit Favorites
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Alerts
